import type { SerializedError } from "@reduxjs/toolkit"
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query"

import { isObject } from "~/helpers/merge"

export interface ReduxQueryAPIError {
	data: APIError
}

/**
 * An error returned by the API.
 * @property {string} SystemErrorMessage The error message.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export interface APIError {
	SystemErrorMessage: string | null // Why is this nullable?!
}

/**
 * The error messages returned by the API.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export enum ErrorMessages {
	Success = "OK",

	MobileNumberNotFound = "mobile not found, please register",
	UserAlreadyExists = "A patient exists on the system with these details, password reminder sent",
	InvalidVerificationCode = "Password does not match",

	ExpiredToken = "Token expired",
	MissingToken = "Token not supplied",
	MalformedToken = "Corrupt token Signature verification failed",
	UnknownToken = "Token not found",
	MismatchingRefreshToken = "Refresh token does not match",

	AppointmentTaken = "Sorry this appointment was taken by another patient in the last few seconds",

	NoAppointments = "No appointments found"
}

export type AnyError = FetchBaseQueryError | SerializedError | ReduxQueryAPIError | Error

/**
 * Ensures an RTK query result's data is an error returned by the API.
 * @param {unknown} data The RTK query result's data.
 * @returns Whether the result's data is an error returned by the API.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const isReduxDataAPIError = (data: object): data is APIError =>
	"SystemErrorMessage" in data && typeof data.SystemErrorMessage === "string"

/**
 * Ensures an RTK query result is an error returned by the API.
 * @param {AnyError} error The RTK query result.
 * @returns Whether the result is an error returned by the API.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const isAPIError = (error: AnyError): error is ReduxQueryAPIError =>
	"data" in error && isObject(error.data) && isReduxDataAPIError(error.data)

/**
 * Ensures an RTK query result is a HTTP error.
 * @param {AnyError} error The RTK query result.
 * @returns Whether the result is a HTTP error.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const isHTTPError = (error: AnyError): error is FetchBaseQueryError => "status" in error

/**
 * Ensures an RTK query result is a standard error.
 * @param {AnyError} error The RTK query result.
 * @returns Whether the result is a standard error.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const isStandardError = (error: AnyError): error is Error => error instanceof Error
