import { useCallback } from "react"
import { useNavigate } from "react-router-dom"

import { isAPIError, isHTTPError, isReduxDataAPIError } from "~/api/osteo-physio/types/error"
import type { InflatedAppointment, InflatedAppointmentTime } from "~/classes/appointment"
import type { OnTimeClickCallback } from "~/components/appointments/available"
import AvailableAppointmentCard from "~/components/appointments/available"
import ErrorMessage from "~/components/errorMessage"
import LoadingSpinner, { loadingSpinnerIconSize } from "~/components/loadingSpinner"
import Paragraph from "~/components/standard/text/paragraph"
import { useDailyAvailableAppointments } from "~/hooks/appointments/useDailyAvailable"
import type { ConfirmAppointmentNavigationState } from "~/pages/appointment/confirm"
import { Routes } from "~/router"
import type { ComponentProps } from "~/types/components/props"

type OnAppointmentTimeClickCallback = (appointment: InflatedAppointment, time: InflatedAppointmentTime) => void

/**
 * Renders a list of available appointments.
 * This uses the filters set by the form.
 * @example <AvailableAppointmentsList />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.3.0
 */
const AvailableAppointmentsList = ({
	rescheduleAppointmentId,

	chosenTherapyTypeIdentifier,
	chosenAppointmentTypeIdentifier,

	...props
}: ComponentProps<
	HTMLDivElement,
	{
		rescheduleAppointmentId?: number

		chosenTherapyTypeIdentifier: number
		chosenAppointmentTypeIdentifier: number
	}
>): JSX.Element => {
	// Router
	const navigate = useNavigate()

	// Fetch the available appointments
	const { appointments, error, isLoading } = useDailyAvailableAppointments(
		chosenTherapyTypeIdentifier,
		chosenAppointmentTypeIdentifier
	)

	// Runs when an appointment time is clicked...
	const onAppointmentClick = useCallback<OnAppointmentTimeClickCallback>(
		(appointment, time) => {
			navigate(Routes.ConfirmAppointment, {
				state: {
					inflatedAppointmentJSON: appointment.toJSON(),
					inflatedAppointmentTimeJSON: time.toJSON(),

					rescheduleAppointmentId: rescheduleAppointmentId
				} as ConfirmAppointmentNavigationState
			})
		},
		[navigate, rescheduleAppointmentId]
	)

	// Show fetch errors
	if (error !== null) {
		if (isReduxDataAPIError(error))
			return <ErrorMessage title="API Error" content={error.SystemErrorMessage?.toString()} />

		if (isAPIError(error))
			return <ErrorMessage title="API Error" content={error.data.SystemErrorMessage?.toString()} />

		if (isHTTPError(error)) return <ErrorMessage title="HTTP Error" content={error.status.toString()} />

		return <ErrorMessage title="Unknown Error" content="Failed to fetch!" />
	}

	// Wait before rendering the list
	if (appointments === null || isLoading)
		return (
			<div {...props} className={`flex flex-col gap-y-4 ${props.className ?? ""}`.trimEnd()}>
				<LoadingSpinner size={loadingSpinnerIconSize} className="mt-4" />
			</div>
		)

	return (
		<div {...props} className={`flex flex-grow flex-col gap-y-4 ${props.className ?? ""}`.trimEnd()}>
			{appointments.length <= 0 ? (
				<Paragraph className="px-4 text-center font-bold text-text">
					No matching appointments available.
				</Paragraph>
			) : (
				appointments.map(appointment => (
					<AvailableAppointmentCardWrapper
						key={appointment.getUniqueKey()}
						appointment={appointment}
						onClick={onAppointmentClick}
					/>
				))
			)}
		</div>
	)
}

const AvailableAppointmentCardWrapper = ({
	appointment,
	onClick,

	...props
}: ComponentProps<
	HTMLDivElement,
	{
		appointment: InflatedAppointment
		onClick?: OnAppointmentTimeClickCallback
	}
>): JSX.Element => {
	const onTimeClick = useCallback<OnTimeClickCallback>(
		(time: InflatedAppointmentTime) => {
			onClick?.(appointment, time)
		},
		[appointment, onClick]
	)

	return <AvailableAppointmentCard appointment={appointment} onTimeClick={onTimeClick} {...props} />
}

export default AvailableAppointmentsList
