import { ClockIcon, CreditCardIcon, MapPinIcon, XMarkIcon } from "@heroicons/react/24/outline"
import { CalendarDaysIcon, ChatBubbleLeftEllipsisIcon, PhoneIcon } from "@heroicons/react/24/solid"
import type { ReturnObject } from "ics"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useLazyInvoiceAppointmentQuery } from "~/api/osteo-physio/client"

import { InflatedAppointment } from "~/classes/appointment"
import AppointmentDetails from "~/components/appointments/details"
import Button from "~/components/controls/button"
import ErrorMessage from "~/components/errorMessage"
import LoadingSpinner from "~/components/loadingSpinner"
import Page from "~/components/standard/layout/page"
import Section from "~/components/standard/layout/section"
import Paragraph from "~/components/standard/text/paragraph"
import { useAuthSessionSelector } from "~/hooks/useAuthSession"
import { useMediaQueries } from "~/hooks/useMediaQueries"
import { useModalDispatch } from "~/hooks/useModal"
import { useUrlMethods } from "~/hooks/useUrlCallbacks"
import { Routes } from "~/router"
import { isPartialUser } from "~/state/slices/authSession"
import type { OnClickCallback } from "~/types/components/controls/button"
import type { ComponentProps } from "~/types/components/props"
import type { NavigationState } from "~/types/router/navigation"

// https://vitejs.dev/guide/env-and-mode#env-variables-and-modes
const contactEmailAddress = import.meta.env.VITE_CONTACT_EMAIL_ADDRESS
if (!contactEmailAddress) throw new Error("The Osteo & Physio contact email address is missing!")
const contactPhoneNumber = import.meta.env.VITE_CONTACT_PHONE_NUMBER
if (!contactPhoneNumber) throw new Error("The Osteo & Physio contact phone number is missing!")

// The name of the calendar download file
const calendarDownloadFileName = "appointment.ics"

/**
 * The appointment details page.
 * This page handles viewing all details of a booked future appointment.
 * @example <AppointmentDetailsPage />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.1.0
 */
const AppointmentDetailsPage = ({ ...props }: ComponentProps<HTMLDivElement>): JSX.Element => {
	// Utility
	const { isMediumWidth } = useMediaQueries()
	const { openUrl, downloadFile } = useUrlMethods()

	// Popup Modals
	const { showNoticeModal } = useModalDispatch()

	// Auth
	const { user } = useAuthSessionSelector()

	// Router
	const navigate = useNavigate()
	const location = useLocation()
	const state = location.state as NavigationState | null
	const appointment = state !== null ? InflatedAppointment.fromJSON(state.inflatedAppointmentJSON) : null

	// Loading state
	const [isSendingInvoice, setIsInvoicing] = useState<boolean>(false)

	// API
	const [invoiceAppointment] = useLazyInvoiceAppointmentQuery()

	// Generate a calendar event for the appointment...
	// NOTE: This feels like it does alot, so it's memoized!
	const calendarEvent: ReturnObject | null = useMemo(() => {
		if (appointment?.isBooked() !== true) return null // Do not continue if there's no state, or appointment is not the right type
		if (!user || isPartialUser(user)) return null // Do not continue if the user is not fully signed in

		return appointment.generateCalendarEvent(user)
	}, [appointment, user])

	// Generate a download URL for the calendar event
	// NOTE: This exists in browser memory, so it is memoized! Ensure it's cleaned up when done too :)
	const downloadUrl = useMemo(() => {
		if (calendarEvent?.value === undefined) return null // Do not continue if we have no calendar event

		return URL.createObjectURL(new File([calendarEvent.value], calendarDownloadFileName, { type: "text/calendar" }))
	}, [calendarEvent])

	// Always ensure the download URL is cleaned up when unmounting!
	useEffect(() => {
		if (downloadUrl === null) return // Do not continue if we have no download URL for the calendar event

		return (): void => {
			URL.revokeObjectURL(downloadUrl)
		}
	}, [downloadUrl])

	// Redirects to the reschedule page...
	const onRescheduleClick = useCallback<OnClickCallback>((): void => {
		if (!appointment) return // Do not continue if we have no state
		if (!appointment.isBooked()) return // This feature isn't for non-booked appointments!

		navigate(Routes.RescheduleAppointment, {
			state: {
				inflatedAppointmentJSON: appointment.toJSON()
			} as NavigationState
		})
	}, [navigate, appointment])

	// Redirects to the cancellation page
	const onCancelClick = useCallback<OnClickCallback>(() => {
		if (!appointment) return // Do not continue if we have no state
		if (!appointment.isBooked()) return // This feature isn't for non-booked appointments!

		navigate(Routes.CancelAppointment, {
			state: {
				inflatedAppointmentJSON: appointment.toJSON()
			} as NavigationState
		})
	}, [navigate, appointment])

	// Opens a popup modal to add the appointment to a calendar...
	const onAddToCalendarClick = useCallback<OnClickCallback>((): void => {
		if (appointment?.isBooked() !== true) return // Do not continue if there's no state, or appointment is not the right type
		if (!user || isPartialUser(user)) return // Do not continue if the user is not fully signed in

		showNoticeModal("Calendar", () => (
			<div className="flex flex-col gap-y-4">
				<Paragraph>
					Select your preferred calendar app below to add this appointment to your schedule. If you&apos;re
					unsure, download the ICS file for offline use.
				</Paragraph>
				<div className={`flex ${isMediumWidth ? "flex-row gap-x-2" : "flex-col gap-y-2"}`}>
					<Button
						label="Add to Google Calendar"
						wide={true}
						standardHeight={false}
						onClick={() => {
							openUrl(appointment.generateGoogleCalendarUrl(user))
						}}
					/>
					<Button
						label="Add to Microsoft Outlook (Live)"
						wide={true}
						standardHeight={false}
						onClick={() => {
							openUrl(appointment.generateMicrosoftOutlookUrl(user))
						}}
					/>
					<Button
						label="Add to Microsoft 365 (Office)"
						wide={true}
						standardHeight={false}
						onClick={() => {
							openUrl(appointment.generateMicrosoft365Url(user))
						}}
					/>
					{downloadUrl !== null && (
						<Button
							label="Download ICS"
							wide={true}
							standardHeight={false}
							onClick={() => {
								downloadFile(calendarDownloadFileName, downloadUrl)
								URL.revokeObjectURL(downloadUrl)
							}}
						/>
					)}
				</div>
			</div>
		))
	}, [showNoticeModal, openUrl, downloadFile, appointment, downloadUrl, isMediumWidth, user])

	// Opens Google Maps with the location of the appointment...
	const onNavigateClick = useCallback<OnClickCallback>((): void => {
		if (!appointment) return // Do not continue if we have no state

		// Split up the coordinates
		const [latitude, longitude] = appointment.location.latlong
		if (latitude === undefined || longitude === undefined) {
			console.warn("Appointment has no coordinates!")
			return
		}

		const link = document.createElement("a")
		link.href = `https://www.google.com/maps/search/?api=1&query=${latitude.toString()},${longitude.toString()}`
		link.target = "_blank"
		link.rel = "noopener noreferrer"
		link.click()
		link.remove()
	}, [appointment])

	// Shows messages not implemented yet notice...
	const onMessageClick = useCallback<OnClickCallback>((): void => {
		showNoticeModal(
			"Coming Soon",
			`Messaging functionality is in the works! Contact us at ${contactEmailAddress} or ${contactPhoneNumber} for more information.`
		)
	}, [showNoticeModal])

	// Open the dialer with the phone number...
	const onPhoneClick = useCallback<OnClickCallback>((): void => {
		const link = document.createElement("a")
		link.href = `tel:${contactPhoneNumber.replace(/\s/g, "")}`
		link.click()
		link.remove()
	}, [])

	// Invoices the appointment...
	const onInvoiceClick = useCallback<OnClickCallback>((): void => {
		if (!appointment) return // Do not continue if we have no state
		if (!appointment.isPast()) return // This feature isn't for future appointments!

		/* eslint-disable promise/prefer-await-to-then */
		setIsInvoicing(true)
		invoiceAppointment({
			id: appointment.id
		})
			.then(value => {
				showNoticeModal("Invoice Sent", "Your invoice has been sent, please check your email.")

				return value
			})
			.catch((error: unknown) => {
				console.warn(
					`Failed to send invoice for appointment '${appointment.id.toString()}'! (${error?.toString() ?? "Unknown error"})`
				)
			})
			.finally(() => {
				setIsInvoicing(false)
			})
	}, [invoiceAppointment, setIsInvoicing, showNoticeModal, appointment])

	// Do not continue if the user is not fully signed in
	if (!user || isPartialUser(user)) return <ErrorMessage title="User" content="User is not signed in!" />

	// Do not continue if we have no state
	if (!appointment) return <ErrorMessage title="Router" content="No state passed to confirmation page!" />

	// Do not continue if the appointment is invalid
	if (!appointment.isValid())
		return <ErrorMessage title="Appointment" content="Appointment has bad properties/malformed data!" />

	// Do not continue if generating the calendar event failed
	if (calendarEvent?.value === undefined || calendarEvent.error)
		return (
			<ErrorMessage
				title="Calendar Event"
				content={
					calendarEvent?.error
						? `${calendarEvent.error.name}: ${calendarEvent.error.message}`
						: "Failed to generate calendar event!"
				}
			/>
		)

	return (
		<Page {...props} className={`justify-between ${props.className ?? ""}`.trimEnd()}>
			<Section title="Appointment Details">
				<AppointmentDetails appointment={appointment} />
			</Section>

			<div className="sticky flex flex-col gap-y-4 px-4 pb-4">
				{appointment.isFuture() && (
					<>
						<div className="flex flex-row gap-x-4">
							<Button
								label="Add to calendar"
								tooltip="Add this appointment to your calendar."
								wide={true}
								className="shadow"
								onClick={onAddToCalendarClick}
								startIcon={<CalendarDaysIcon width={28} height={28} />}
							/>
							<Button
								label="Navigate here"
								tooltip="Open Google Maps to the location of this clinic."
								wide={true}
								className="shadow"
								onClick={onNavigateClick}
								startIcon={<MapPinIcon width={28} height={28} />}
							/>
						</div>

						<div className="flex flex-row gap-x-4">
							<Button
								label="Reschedule"
								tooltip="Reschedule this appointment."
								wide={true}
								className="shadow"
								onClick={onRescheduleClick}
								startIcon={<ClockIcon width={28} height={28} />}
							/>
							<Button
								label="Cancel"
								tooltip="Cancel this appointment."
								wide={true}
								className="shadow"
								onClick={onCancelClick}
								startIcon={<XMarkIcon width={28} height={28} />}
							/>
						</div>

						<div className="flex flex-row gap-x-4">
							<Button
								label="Message us"
								tooltip="Messaging functionality is in the works! Contact us for more information."
								wide={true}
								isDisabled={true} // Not implemented yet!
								onClick={onMessageClick}
								className="shadow"
								startIcon={<ChatBubbleLeftEllipsisIcon width={28} height={28} />}
							/>
							<Button
								label="Phone us"
								tooltip="Call reception."
								wide={true}
								className="shadow"
								onClick={onPhoneClick}
								startIcon={<PhoneIcon width={28} height={28} />}
							/>
						</div>
					</>
				)}

				{appointment.isPast() && appointment.hasCost() && appointment.canBeInvoiced && (
					<div className="flex flex-row gap-x-4">
						<Button
							label={isSendingInvoice ? "Emailing invoice..." : "Email invoice"}
							tooltip="Issue an invoice for this appointment."
							isLoading={isSendingInvoice}
							wide={true}
							className="shadow"
							onClick={onInvoiceClick}
							startIcon={<CreditCardIcon width={28} height={28} />}
							endIcon={isLoading =>
								isLoading ? (
									<LoadingSpinner className="ms-1" inContainer={true} size={20} />
								) : (
									// Fallback for equal spacing
									<CreditCardIcon className="invisible" width={28} height={28} />
								)
							}
						/>
					</div>
				)}
			</div>
		</Page>
	)
}

export default AppointmentDetailsPage
