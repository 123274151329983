import { HomeIcon } from "@heroicons/react/24/solid"
import { useCallback, useEffect } from "react"

import { useLazyGetAddressQuery } from "~/api/get-address/client"
import Form from "~/components/controls/forms/form"
import DropDownInput from "~/components/controls/inputs/dropDown"
import BackOrContinueButtons from "~/components/onboarding/backOrContinueButtons"
import Paragraphs from "~/components/onboarding/paragraphs"
import StageWrapper from "~/components/onboarding/wrapper"
import Paragraph from "~/components/standard/text/paragraph"
import { dropDownIconStyles } from "~/constants/components/dropDown"
import { inputDisabledColorStyles, inputIconSize } from "~/constants/components/input"
import { useFlowContext } from "~/contexts/flow"
import { orNull } from "~/helpers/null"
import { useFormDispatch } from "~/hooks/useForm"
import { useOnboardingDispatch, useOnboardingSelector } from "~/hooks/useOnboarding"
import type { OnClickCallback } from "~/types/components/controls/button"
import type { OnFormSubmitCallback } from "~/types/components/controls/form"
import { OnboardingStage } from "~/types/components/pages/onboarding"
import type { ComponentProps } from "~/types/components/props"

enum HTMLElementIdentifiers {
	SuggestionSelection = "suggestionSelection",
	SelectedAddress = "selectedAddress"
}

/**
 * A sub-stage of the address stage for selecting a suggested address.
 * This should be wrapped in a <StageFlow /> component!
 * @example <SuggestedAddressStage />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.1.0
 */
const SuggestedAddressStage = ({ ...props }: ComponentProps<HTMLDivElement>): JSX.Element => {
	// Form
	const { showWarning, updateLoading } = useFormDispatch(HTMLElementIdentifiers.SuggestionSelection)

	// Onboarding
	const { transfer } = useFlowContext()
	const { setManualAddress, clearManualAddress } = useOnboardingDispatch()
	const { suggestedAddresses } = useOnboardingSelector()

	// API
	const [getAddress, addressDetails] = useLazyGetAddressQuery()

	// Map the addresses by their IDs & tidy up their value (some of them don't have street/city names so they have duplicated commas)
	const addresses: Record<string, string> = Object.fromEntries(
		suggestedAddresses.map(suggestedAddress => [
			suggestedAddress.id,
			suggestedAddress.address
				.split(",")
				.map(part => part.trim())
				.filter(part => part !== "")
				.join(", ")
		])
	)

	// Runs when the form is submitted...
	const onSubmitted = useCallback<OnFormSubmitCallback>(
		(values): void => {
			const addressId = values.get(HTMLElementIdentifiers.SelectedAddress) as string | null

			// Shouldn't happen, but check just in case to please TypeScript
			if (addressId === null) {
				console.warn("No address was selected, even though the input is required?!")
				showWarning(HTMLElementIdentifiers.SelectedAddress, "Select an address!")
				return
			}

			/* eslint-disable promise/prefer-await-to-then */
			console.info(`Fetching address '${addressId}'...`)
			updateLoading(true)
			getAddress({
				id: addressId
			}).catch((error: unknown) => {
				console.warn(`Failed to fetch address '${addressId}'! (${error?.toString() ?? "Unknown error"})`)
				showWarning(
					HTMLElementIdentifiers.SelectedAddress,
					"Sorry, an unknown problem occurred. Please try again later."
				)
				clearManualAddress()
			})
		},
		[showWarning, updateLoading, getAddress, clearManualAddress]
	)

	// Transfer to the manual address entry stage when the my address isn't listed link is clicked...
	const onLinkClick = useCallback<OnClickCallback>(() => {
		clearManualAddress() // Best to be safe!
		transfer(OnboardingStage.ManualAddress)
	}, [transfer, clearManualAddress])

	// Return to the previous (partial address) stage when the back button is clicked...
	const onBackClick = useCallback<OnClickCallback>(() => {
		transfer(OnboardingStage.PartialAddress)
	}, [transfer])

	useEffect(() => {
		if (addressDetails.isUninitialized || addressDetails.isFetching || addressDetails.isLoading) return

		updateLoading(false)

		if (addressDetails.isError) {
			console.warn("API returned unhandled error for fetching address!")
			showWarning(
				HTMLElementIdentifiers.SelectedAddress,
				"Sorry, we cannot find that address. Try enter it manually."
			)
			clearManualAddress()
			return
		}

		const houseNumber = orNull(addressDetails.data.building_number)
		const firstLine = orNull(addressDetails.data.line_1)
		const secondLine = orNull(addressDetails.data.line_2)
		const city = orNull(addressDetails.data.town_or_city)
		const county = orNull(addressDetails.data.county)
		const postCode = orNull(addressDetails.data.postcode)

		if (firstLine === null || firstLine === "") {
			console.warn("No first line returned for address?!")
			showWarning(
				HTMLElementIdentifiers.SelectedAddress,
				"Sorry, we cannot find that address. Try enter it manually."
			)
			clearManualAddress()
			return
		}

		if (city === null || city === "") {
			console.warn("No city returned for address?!")
			showWarning(
				HTMLElementIdentifiers.SelectedAddress,
				"Sorry, we cannot find that address. Try enter it manually."
			)
			clearManualAddress()
			return
		}

		if (county === null || county === "") {
			console.warn("No county returned for address?!")
			showWarning(
				HTMLElementIdentifiers.SelectedAddress,
				"Sorry, we cannot find that address. Try enter it manually."
			)
			clearManualAddress()
			return
		}

		if (postCode === null || postCode === "") {
			console.warn("No post code returned for address?!")
			showWarning(
				HTMLElementIdentifiers.SelectedAddress,
				"Sorry, we cannot find that address. Try enter it manually."
			)
			clearManualAddress()
			return
		}

		setManualAddress({
			houseNumber: houseNumber,
			firstLine: firstLine,
			secondLine: secondLine,
			city: city,
			county: county,
			postCode: postCode
		})

		transfer(OnboardingStage.Association)
	}, [addressDetails, showWarning, updateLoading, clearManualAddress, setManualAddress, transfer])

	return (
		<StageWrapper {...props}>
			<Paragraphs>
				<Paragraph>Please select your address below to continue creating your account.</Paragraph>
			</Paragraphs>
			<Form id={HTMLElementIdentifiers.SuggestionSelection} onSubmit={onSubmitted}>
				<DropDownInput
					id={HTMLElementIdentifiers.SelectedAddress}
					label="Address"
					linkText="My address isn't listed"
					choices={addresses}
					initialValue={Object.keys(addresses)[0]}
					tooltip="Please select an address."
					onLinkClick={onLinkClick}
					startIcon={isLoading => (
						<HomeIcon
							className={`${dropDownIconStyles} ${isLoading ? inputDisabledColorStyles : "fill-primary"}`}
							width={inputIconSize}
							height={inputIconSize}
						/>
					)}
				/>
				<BackOrContinueButtons
					previousStage={OnboardingStage.EmailAddress}
					submitLoadingLabel="Fetching..."
					onBackClick={onBackClick}
				/>
			</Form>
		</StageWrapper>
	)
}

export default SuggestedAddressStage
