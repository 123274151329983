import { ArrowTopRightOnSquareIcon } from "@heroicons/react/16/solid"
import { EnvelopeIcon, HomeIcon } from "@heroicons/react/24/solid"
import { useCallback, useEffect } from "react"

import {
	useLazyAutoCompleteQuery,
	useLazyGetAddressQuery,
	useLazyGetNearestAddressQuery
} from "~/api/get-address/client"
import Form from "~/components/controls/forms/form"
import TextInput from "~/components/controls/inputs/text"
import BackOrContinueButtons from "~/components/onboarding/backOrContinueButtons"
import OfflineWarning from "~/components/onboarding/offlineWarning"
import Paragraphs from "~/components/onboarding/paragraphs"
import StageWrapper from "~/components/onboarding/wrapper"
import Paragraph from "~/components/standard/text/paragraph"
import FadeIn from "~/components/wrappers/fadeIn"
import { longerColorTransitionStyles } from "~/config/transitions"
import { inputDisabledColorStyles, inputIconSize, inputLinkIconSize } from "~/constants/components/input"
import { useFlowContext } from "~/contexts/flow"
import { orNull } from "~/helpers/null"
import { useFormDispatch } from "~/hooks/useForm"
import { useInputSelector } from "~/hooks/useInput"
import { useIsOnline } from "~/hooks/useIsOnline"
import { useOnboardingDispatch, useOnboardingSelector } from "~/hooks/useOnboarding"
import type { OnClickCallback } from "~/types/components/controls/button"
import type { OnFormSubmitCallback } from "~/types/components/controls/form"
import { OnboardingStage } from "~/types/components/pages/onboarding"
import type { ComponentProps } from "~/types/components/props"

enum HTMLElementIdentifiers {
	PartialAddress = "partialAddress",
	HouseNumber = "houseNumber",
	PostCode = "postCode"
}

/**
 * The fourth stage of the onboarding process.
 * This stage collects the user's address for registering a new account.
 * This should be wrapped in a <StageFlow /> component!
 * @example <PartialAddressStage />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.1.0
 */
const PartialAddressStage = ({ ...props }: ComponentProps<HTMLDivElement>): JSX.Element => {
	// Form
	const { showWarning, clearWarnings, updateLoading } = useFormDispatch(HTMLElementIdentifiers.PartialAddress)
	const { warningMessage } = useInputSelector(HTMLElementIdentifiers.PostCode, HTMLElementIdentifiers.PartialAddress)

	// Onboarding
	const { transfer } = useFlowContext()
	const { setPartialAddress, clearPartialAddress, setManualAddress, clearManualAddress, setSuggestedAddresses } =
		useOnboardingDispatch()
	const { partialAddress } = useOnboardingSelector()

	// API
	const [autoCompleteAddress, autoCompletedAddress] = useLazyAutoCompleteQuery()
	const [getAddress, addressDetails] = useLazyGetAddressQuery()
	const [getNearestAddress, nearestAddresses] = useLazyGetNearestAddressQuery()

	// Internet connectivity check
	const [isOnline] = useIsOnline()

	useEffect(() => {
		if (!partialAddress) return
		if (autoCompletedAddress.isUninitialized || autoCompletedAddress.isFetching || autoCompletedAddress.isLoading)
			return

		updateLoading(false)

		const houseNumber = partialAddress.houseNumber
		const postCode = partialAddress.postCode

		if (autoCompletedAddress.isError) {
			console.warn(`API returned unhandled error for auto-completing post code '${postCode}'!`)
			showWarning(HTMLElementIdentifiers.PostCode, "Sorry, an unknown problem occurred. Please try again later.")
			clearPartialAddress()
			return
		}

		const suggestions = autoCompletedAddress.data
		console.info(`Found ${suggestions.length.toString()} address(es) for post code '${postCode}'`)

		if (houseNumber === null) {
			console.warn("No house number entered! Showing drop-down selector...")
			setSuggestedAddresses(suggestions)
			transfer(OnboardingStage.SuggestedAddresses)
			return
		}

		const matchingSuggestion = suggestions.find(suggestion =>
			suggestion.address.toLowerCase().startsWith(houseNumber.toLowerCase())
		)
		if (!matchingSuggestion) {
			console.warn(`No matching address found for house number '${houseNumber}'! Showing drop-down selector...`)
			setSuggestedAddresses(suggestions)
			transfer(OnboardingStage.SuggestedAddresses)
			return
		}

		/* eslint-disable promise/prefer-await-to-then */
		updateLoading(true)
		getAddress({
			id: matchingSuggestion.id
		}).catch((error: unknown) => {
			console.warn(
				`Failed to fetch address '${matchingSuggestion.address}'! (${error?.toString() ?? "Unknown error"})`
			)
			showWarning(HTMLElementIdentifiers.PostCode, "Sorry, an unknown problem occurred. Please try again later.")
			clearPartialAddress()
			clearManualAddress()
		})
	}, [
		transfer,
		showWarning,
		updateLoading,
		clearPartialAddress,
		clearManualAddress,
		getAddress,
		setSuggestedAddresses,
		autoCompletedAddress,
		partialAddress
	])

	useEffect(() => {
		if (!partialAddress) return
		if (addressDetails.isUninitialized || addressDetails.isFetching || addressDetails.isLoading) return

		updateLoading(false)

		if (addressDetails.isError) {
			console.warn("API returned unhandled error for fetching address!")
			showWarning(HTMLElementIdentifiers.PostCode, "Sorry, we cannot find your address. Try enter it manually.")
			clearPartialAddress()
			clearManualAddress()
			return
		}

		console.info(
			`Fetched address for house number '${partialAddress.houseNumber?.toString() ?? "Unknown house number"}' and post code '${partialAddress.postCode}'`
		)
		const houseNumber = orNull(addressDetails.data.building_number)
		const firstLine = orNull(addressDetails.data.line_1)
		const secondLine = orNull(addressDetails.data.line_2)
		const city = orNull(addressDetails.data.town_or_city)
		const county = orNull(addressDetails.data.county)
		const postCode = orNull(addressDetails.data.postcode)

		if (firstLine === null) {
			console.warn("No first line returned for address?!")
			showWarning(HTMLElementIdentifiers.PostCode, "Sorry, we cannot find your address. Try enter it manually.")
			clearPartialAddress()
			clearManualAddress()
			return
		}

		if (city === null) {
			console.warn("No city returned for address?!")
			showWarning(HTMLElementIdentifiers.PostCode, "Sorry, we cannot find your address. Try enter it manually.")
			clearPartialAddress()
			clearManualAddress()
			return
		}

		if (county === null) {
			console.warn("No county returned for address?!")
			showWarning(HTMLElementIdentifiers.PostCode, "Sorry, we cannot find your address. Try enter it manually.")
			clearPartialAddress()
			clearManualAddress()
			return
		}

		// Fallback to the values they entered for this lookup
		setManualAddress({
			houseNumber: houseNumber ?? partialAddress.houseNumber,
			firstLine: firstLine,
			secondLine: secondLine,
			city: city,
			county: county,
			postCode: postCode ?? partialAddress.postCode
		})

		transfer(OnboardingStage.Association)
	}, [
		transfer,
		clearPartialAddress,
		updateLoading,
		showWarning,
		clearManualAddress,
		setManualAddress,
		addressDetails,
		partialAddress
	])

	useEffect(() => {
		if (nearestAddresses.isUninitialized || nearestAddresses.isFetching || nearestAddresses.isLoading) return

		updateLoading(false)

		if (nearestAddresses.isError) {
			console.warn("API returned unhandled error for fetching nearest address!")
			showWarning(HTMLElementIdentifiers.PostCode, "Sorry, an unknown problem occurred. Please try again later.")
			return
		}

		const addresses = nearestAddresses.data
		console.info(`Fetched ${addresses.length.toString()} nearest address(es)`)
		setSuggestedAddresses(addresses)
		transfer(OnboardingStage.SuggestedAddresses)
	}, [transfer, updateLoading, showWarning, setSuggestedAddresses, nearestAddresses])

	const onSubmitted = useCallback<OnFormSubmitCallback>(
		(values): void => {
			const houseNumber = values.get(HTMLElementIdentifiers.HouseNumber) as string | null
			const postCode = values.get(HTMLElementIdentifiers.PostCode) as string | null

			// Shouldn't happen, but check just in case to please TypeScript
			if (postCode === null) {
				console.warn("No post code was entered, even though the input is required?!")
				showWarning(HTMLElementIdentifiers.PostCode, "Enter your post code name!")
				clearPartialAddress()
				return
			}

			console.info(
				`Auto-completing for house number '${houseNumber?.toString() ?? "Unknown house number"}' and post code '${postCode}'...`
			)
			setPartialAddress({
				houseNumber,
				postCode
			})

			/* eslint-disable promise/prefer-await-to-then */
			updateLoading(true)
			autoCompleteAddress({
				postCode
			}).catch((error: unknown) => {
				console.warn(
					`Failed to auto-complete post code '${postCode}'! (${error?.toString() ?? "Unknown error"})`
				)
				showWarning(
					HTMLElementIdentifiers.PostCode,
					"Sorry, an unknown problem occurred. Please try again later."
				)
				clearPartialAddress()
			})
		},
		[autoCompleteAddress, showWarning, clearPartialAddress, updateLoading, setPartialAddress]
	)

	// Runs when the locate me link is clicked...
	const onFindAddressLinkClick = useCallback<OnClickCallback>(() => {
		if (!("geolocation" in navigator)) {
			console.warn("Geolocation API is not available!")
			return
		}

		clearWarnings()

		/* eslint-disable promise/prefer-await-to-then */
		// https://developer.mozilla.org/en-US/docs/Web/API/Geolocation/getCurrentPosition
		try {
			updateLoading(true)
			navigator.geolocation.getCurrentPosition(
				position => {
					console.info(
						`Location is ${position.coords.latitude.toString()}, ${position.coords.longitude.toString()}.`
					)
					getNearestAddress({
						latitude: position.coords.latitude,
						longitude: position.coords.longitude
					}).catch((error: unknown) => {
						console.warn(`Failed to fetch nearest address! (${error?.toString() ?? "Unknown error"})`)
						showWarning(
							HTMLElementIdentifiers.PostCode,
							"Sorry, an unknown problem occurred. Please try again later."
						)
					})
				},
				error => {
					updateLoading(false)

					if (error.code === error.PERMISSION_DENIED) {
						console.warn("User denied the request for Geolocation.")
						showWarning(HTMLElementIdentifiers.PostCode, "Permissions request for location was denied.")
						return
					}

					if (error.code === error.POSITION_UNAVAILABLE) {
						console.warn("Location information is unavailable.")
						showWarning(
							HTMLElementIdentifiers.PostCode,
							"Sorry, your location is unavailable. Try again later."
						)
						return
					}

					if (error.code === error.TIMEOUT) {
						console.warn("The request to get user location timed out.")
						showWarning(
							HTMLElementIdentifiers.PostCode,
							"Sorry, we couldn't find your location. Try again later."
						)
						return
					}

					console.error(`Failed to get user location! (${error.code.toString()}: ${error.message})`)
					showWarning(HTMLElementIdentifiers.PostCode, "Sorry, an unknown problem occurred. Try again later.")
				},
				{
					maximumAge: 60000, // 1 minute
					timeout: 5000, // 5 seconds
					enableHighAccuracy: true
				}
			)
		} catch (error: unknown) {
			console.warn(`Failed to fetch location! (${error?.toString() ?? "Unknown error"})`)
			showWarning(HTMLElementIdentifiers.PostCode, "Sorry, an unknown problem occurred. Please try again later.")
		}
	}, [clearWarnings, getNearestAddress, showWarning, updateLoading])

	// Transfer to the manual address entry stage when the my address isn't listed link is clicked...
	const onEnterManualAddressLinkClick = useCallback<OnClickCallback>(() => {
		clearManualAddress() // Best to be safe!
		transfer(OnboardingStage.ManualAddress)
	}, [transfer, clearManualAddress])

	return (
		<StageWrapper {...props}>
			<Paragraphs>
				<Paragraph>Please enter your address below to continue creating your account.</Paragraph>
			</Paragraphs>
			<OfflineWarning visible={!isOnline} />
			<Form id={HTMLElementIdentifiers.PartialAddress} onSubmit={onSubmitted}>
				{(warningMessage !== null || !isOnline) && (
					<FadeIn className="self-center">
						<Paragraph
							onClick={onEnterManualAddressLinkClick}
							className="flex flex-row gap-x-1 text-right text-xs text-primary hover:cursor-pointer hover:underline">
							Having problems? Enter address manually
							<ArrowTopRightOnSquareIcon width={inputLinkIconSize} height={inputLinkIconSize} />
						</Paragraph>
					</FadeIn>
				)}
				<TextInput
					id={HTMLElementIdentifiers.HouseNumber}
					label="House Number"
					linkText={"geolocation" in navigator ? "Find my address" : undefined}
					placeholder="5..."
					initialValue={partialAddress?.houseNumber ?? undefined}
					tooltip="Please enter your house number."
					missingValueWarningMessage="Enter a house number!"
					isRequired={false}
					isDisabled={!isOnline}
					onLinkClick={onFindAddressLinkClick}
					startIcon={isLoading => (
						<HomeIcon
							className={`${longerColorTransitionStyles} ${isLoading ? inputDisabledColorStyles : "fill-primary"}`}
							width={inputIconSize}
							height={inputIconSize}
						/>
					)}
				/>
				<TextInput
					id={HTMLElementIdentifiers.PostCode}
					label="Post Code"
					placeholder="EX15 1JW..."
					initialValue={partialAddress?.postCode}
					tooltip="Please enter your post code."
					missingValueWarningMessage="Enter your post code!"
					isFocused={true}
					isDisabled={!isOnline}
					startIcon={isLoading => (
						<EnvelopeIcon
							className={`${longerColorTransitionStyles} ${isLoading ? inputDisabledColorStyles : "fill-primary"}`}
							width={inputIconSize}
							height={inputIconSize}
						/>
					)}
				/>
				<BackOrContinueButtons
					previousStage={OnboardingStage.EmailAddress}
					submitLoadingLabel="Fetching..."
					isDisabled={!isOnline}
				/>
			</Form>
		</StageWrapper>
	)
}

export default PartialAddressStage
