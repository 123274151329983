import { useCallback } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { useLazyCancelAppointmentQuery } from "~/api/osteo-physio/client"
import { InflatedAppointment } from "~/classes/appointment"
import Form from "~/components/controls/forms/form"
import SubmitButton from "~/components/controls/forms/submitButton"
import MultiLineInput from "~/components/controls/inputs/multiLine"
import ErrorMessage from "~/components/errorMessage"
import Page from "~/components/standard/layout/page"
import Section from "~/components/standard/layout/section"
import { useFormDispatch } from "~/hooks/useForm"
import { Routes } from "~/router"
import type { OnFormSubmitCallback } from "~/types/components/controls/form"
import type { ComponentProps } from "~/types/components/props"
import type { NavigationState } from "~/types/router/navigation"

enum HTMLElementIdentifiers {
	CancelAppointment = "cancelAppointment",
	CancellationReason = "cancellationReason"
}

/**
 * The appointment cancellation page.
 * This page handles supplying a reason for cancellation of a booked appointment.
 * @example <CancelAppointmentPage />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.1.0
 */
const CancelAppointmentPage = ({ ...props }: ComponentProps<HTMLDivElement>): JSX.Element => {
	const navigate = useNavigate()

	// Form
	const { showWarning, updateLoading } = useFormDispatch(HTMLElementIdentifiers.CancelAppointment)

	// API queries
	const [cancelAppointment] = useLazyCancelAppointmentQuery()

	// Get state from router
	const location = useLocation()
	const state = location.state as NavigationState | null
	const appointment = state !== null ? InflatedAppointment.fromJSON(state.inflatedAppointmentJSON) : null

	// Runs when the cancel button is clicked...
	const onSubmit = useCallback<OnFormSubmitCallback>(
		values => {
			if (!appointment) return // Do not continue if we have no state
			if (!appointment.isBooked()) return // This feature isn't for non-booked appointments!

			// Get the input value
			const cancellationReason = values.get(HTMLElementIdentifiers.CancellationReason) as string | null
			if (cancellationReason === null) {
				console.warn("No cancellation reason was entered, even though the input is required?!")
				showWarning(HTMLElementIdentifiers.CancellationReason, "Enter a reason for cancellation!")
				return
			}

			/* eslint-disable promise/prefer-await-to-then */
			updateLoading(true)
			cancelAppointment({
				id: appointment.id,
				reason: cancellationReason
			})
				.then(value => {
					navigate(Routes.Home)
					return value
				})
				.catch((error: unknown) => {
					console.warn(
						`Failed to cancel appointment '${appointment.id.toString()}'! (${error?.toString() ?? "Unknown error"})`
					)
					showWarning(
						HTMLElementIdentifiers.CancellationReason,
						"Sorry, we couldn't cancel your appointment.Try again later."
					)
				})
				.finally(() => {
					updateLoading(false)
				})
		},
		[cancelAppointment, navigate, showWarning, updateLoading, appointment]
	)

	// Do not continue if we have no state
	if (!appointment) return <ErrorMessage title="Router" content="No state passed to confirmation page!" />

	// Do not continue if the appointment is invalid
	if (!appointment.isValid())
		return <ErrorMessage title="Appointment" content="Appointment has bad properties/malformed data!" />

	return (
		<Page {...props}>
			<Section title="Cancel Appointment">
				<Form id={HTMLElementIdentifiers.CancelAppointment} onSubmit={onSubmit}>
					<MultiLineInput
						id={HTMLElementIdentifiers.CancellationReason}
						label="Reason"
						placeholder="I'm unable to attend due to..."
						tooltip="The reason for cancelling this appointment."
						missingValueWarningMessage="Enter a cancellation reason!"
						isRequired={true}
						isFocused={true}
					/>
					<SubmitButton
						label="Cancel this appointment"
						loadingLabel="Cancelling this appointment..."
						className="!text-md shadow"
						wide={true}
					/>
				</Form>
			</Section>
		</Page>
	)
}

export default CancelAppointmentPage
