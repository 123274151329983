import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import type { AutoCompletion } from "~/api/get-address/types/endpoints/autoComplete"
import type { NearestAddresses } from "~/api/get-address/types/endpoints/nearest"
import type { Address } from "~/api/get-address/types/models/address"
import type { AutoCompletionSuggestion } from "~/api/get-address/types/models/autoComplete"
import type { NearestAddress } from "~/api/get-address/types/models/nearest"

// https://vitejs.dev/guide/env-and-mode#env-variables-and-modes
const key = import.meta.env.VITE_GET_ADDRESS_API_KEY
if (!key) throw new Error("The Get Address API key is missing!")

/**
 * The Redux Toolkit API client for the Get Address API.
 * @see https://getaddress.io
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const api = createApi({
	reducerPath: "get-address",
	baseQuery: fetchBaseQuery({
		baseUrl: "https://api.getaddress.io",
		timeout: 5000 // 5 seconds
	}),
	endpoints: builder => ({
		/**
		 * Retrieves a list of address suggestions for a given postcode.
		 * @param {string} postCode The postcode to search for.
		 * @returns {AutoCompletionSuggestion[]} The list of suggestions.
		 * @see https://api.getaddress.io/autocomplete
		 * @see https://documentation.getaddress.io/
		 * @author Jay Hunter <jh@yello.studio>
		 * @since 2.0.0
		 */
		autoComplete: builder.query<
			AutoCompletionSuggestion[],
			{
				postCode: string
			}
		>({
			query: ({ postCode }) => ({
				method: "GET",
				url: `autocomplete/${encodeURIComponent(postCode)}?api-key=${key}&top=20&all=true&template={line_1},%20{line_2},%20{town_or_city},%20{county}`
			}),

			transformResponse: (response: AutoCompletion) => response.suggestions
		}),

		/**
		 * Gets the full address for a previously found suggestion.
		 * @param {string} id The ID of the suggestion to retrieve.
		 * @returns {Address} The full address.
		 * @see https://api.getaddress.io/autocomplete
		 * @see https://documentation.getaddress.io
		 * @author Jay Hunter <jh@yello.studio>
		 * @since 2.0.0
		 */
		getAddress: builder.query<
			Address,
			{
				id: string
			}
		>({
			query: ({ id }) => ({
				method: "GET",
				url: `get/${id}?api-key=${key}`
			})
		}),

		/**
		 * Retrieves a list of address suggestions for a given latitude and longitude.
		 * @param {number} latitude The latitude to search for.
		 * @param {number} longitude The longitude to search for.
		 * @returns {NearestAddress[]} The list of suggestions.
		 * @see https://api.getaddress.io/autocomplete
		 * @see https://documentation.getaddress.io/nearest
		 * @author Jay Hunter <jh@yello.studio>
		 * @since 2.0.0
		 */
		getNearestAddress: builder.query<
			NearestAddress[],
			{
				latitude: number
				longitude: number
			}
		>({
			query: ({ latitude, longitude }) => ({
				method: "GET",
				url: `nearest/${latitude.toString()}/${longitude.toString()}?api-key=${key}&top=20&all=true&radius=0.25`
			}),

			transformResponse: (response: NearestAddresses) => response.suggestions
		})
	})
})

export const useLazyAutoCompleteQuery: typeof api.endpoints.autoComplete.useLazyQuery =
	api.endpoints.autoComplete.useLazyQuery
export const useAutoCompleteQuery: typeof api.endpoints.autoComplete.useQuery = api.endpoints.autoComplete.useQuery

export const useLazyGetAddressQuery: typeof api.endpoints.getAddress.useLazyQuery =
	api.endpoints.getAddress.useLazyQuery
export const useGetAddressQuery: typeof api.endpoints.getAddress.useQuery = api.endpoints.getAddress.useQuery

export const useLazyGetNearestAddressQuery: typeof api.endpoints.getNearestAddress.useLazyQuery =
	api.endpoints.getNearestAddress.useLazyQuery
export const useGetNearestAddressQuery: typeof api.endpoints.getNearestAddress.useQuery =
	api.endpoints.getNearestAddress.useQuery
