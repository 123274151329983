/**
 * Merges all the properties of the source object into the target object.
 * Properties of the source object will overwrite properties of the target object, unless the property value is undefined, in which case the target object's property will be preserved.
 * This function will not mutate the target object, but instead return a new object with the merged properties.
 * @param target The object to merge properties into.
 * @param source The object to merge properties from.
 * @return The cloned target object.
 * @see https://stackoverflow.com/a/34749873
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const deepObjectMerge = <Type extends Record<string, unknown>>(
	target: Record<string, unknown>,
	source: Record<string, unknown>
): Type => {
	const clone = { ...target } // Shallow clone

	for (const key in source)
		clone[key] = isObject(source[key]) ? deepObjectMerge(clone[key] as Type, source[key] as Type) : source[key]

	return clone as Type
}

/**
 * Checks if the given value is an object.
 * @param value The value to check.
 * @returns True if the value is an object, false otherwise.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const isObject = (value: unknown): value is object =>
	value !== null && value !== undefined && typeof value === "object" && !Array.isArray(value)
