import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"
import type { TypedUseSelectorHook } from "react-redux"
import { useDispatch, useSelector } from "react-redux"

import { api as cloudRunAPI } from "~/api/cloud-run/client"
import { api as getAddressAPI } from "~/api/get-address/client"
import { api as osteoPhysioAPI } from "~/api/osteo-physio/client"
import appointmentFiltersSlice from "~/state/slices/appointmentFilters"
import authSessionSlice from "~/state/slices/authSession"
import onboardingSlice from "~/state/slices/onboarding"
import userInterfaceSlice from "~/state/slices/userInterface"

/**
 * The Redux store.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const store = configureStore({
	reducer: {
		userInterface: userInterfaceSlice,
		onboarding: onboardingSlice,
		authSession: authSessionSlice,
		appointmentFilters: appointmentFiltersSlice,

		// https://redux-toolkit.js.org/rtk-query/overview#configure-the-store
		[osteoPhysioAPI.reducerPath]: osteoPhysioAPI.reducer,
		[getAddressAPI.reducerPath]: getAddressAPI.reducer,
		[cloudRunAPI.reducerPath]: cloudRunAPI.reducer
	},

	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			// We are naughty as we pass JSX components & callback functions into the store!
			// https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
			serializableCheck: {
				ignoredActions: ["userInterface/updateModal"],
				ignoredPaths: [
					"userInterface.content",
					"userInterface.callback",
					"userInterface.modal.content",
					"userInterface.modal.callback",
					"payload.content"
				]
			}
		})
			.concat(osteoPhysioAPI.middleware)
			.concat(getAddressAPI.middleware)
			.concat(cloudRunAPI.middleware)
})

setupListeners(store.dispatch)

/**
 * Simple way to access the Redux store's complex type.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export type Store = ReturnType<typeof store.getState>

/**
 * Hook to query the Redux store's current data.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const useReduxSelector: TypedUseSelectorHook<Store> = useSelector

/**
 * Hook to update the Redux store.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const useReduxDispatch: () => typeof store.dispatch = useDispatch
