export const standardTransitionEaseStyles = "ease-in-out"
export const standardTransitionDurationStyles = "duration-150"

// e.g., pages fading in/out
export const standardTransitionStyles = `${standardTransitionEaseStyles} ${standardTransitionDurationStyles} transition-all`
export const standardOpacityTransitionStyles = `${standardTransitionEaseStyles} ${standardTransitionDurationStyles} transition-opacity`
export const standardColorTransitionStyles = `${standardTransitionEaseStyles} ${standardTransitionDurationStyles} transition-colors`

export const buttonTransitionStyles = standardColorTransitionStyles

// e.g., inputs, buttons, etc.
export const longerTransitionDurationStyles = "duration-300"
export const longerTransitionStyles = `${standardTransitionEaseStyles} ${longerTransitionDurationStyles} transition-all`
export const longerOpacityTransitionStyles = `${standardTransitionEaseStyles} ${longerTransitionDurationStyles} transition-opacity`
export const longerColorTransitionStyles = `${standardTransitionEaseStyles} ${longerTransitionDurationStyles} transition-colors`

// e.g., slide in/out transitions between onboarding stages
export const longestTransitionDurationStyles = "duration-700"
export const longestTransitionStyles = `${standardTransitionEaseStyles} ${longestTransitionDurationStyles} transition-all`
