import { useEffect } from "react"
import { Navigate, useLocation, useOutlet } from "react-router-dom"

import { useFetchCoreQuery } from "~/api/osteo-physio/client"
import { isAPIError, isHTTPError } from "~/api/osteo-physio/types/error"
import ErrorMessage from "~/components/errorMessage"
import LoadingSpinner, { loadingSpinnerIconSize } from "~/components/loadingSpinner"
import PopupModal from "~/components/popupModal"
import Header from "~/components/standard/layout/header"
import FadeIn from "~/components/wrappers/fadeIn"
import FullScreen from "~/components/wrappers/fullScreen"
import { tidyUpUser } from "~/helpers/tidy-ups/user/user"
import { useAuthSessionDispatch, useAuthSessionSelector } from "~/hooks/useAuthSession"
import { useIsOnline } from "~/hooks/useIsOnline"
import { Routes } from "~/router"
import { isPartialUser } from "~/state/slices/authSession"
import type { ComponentWithChildrenProps } from "~/types/components/props"

/**
 * The root component for the app.
 * This is ONLY for rendering non-fullscreen/signed-in pages (e.g., home, book appointment, etc.)!
 * @example <App />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const App = (): JSX.Element => {
	const outlet = useOutlet()
	const { pathname } = useLocation()

	const { isSignedIn } = useAuthSessionSelector()

	const [isOnline] = useIsOnline()

	// Redirect to the home page at the root
	if (pathname === "/") return <Navigate to={Routes.Home} />

	// Redirect to the onboarding process if not signed in
	if (!isSignedIn) return <Navigate to={Routes.Onboarding} />

	return (
		<FullScreen useMain={false}>
			{isOnline ? (
				<FadeIn className="flex flex-grow flex-col">
					<FetchCore>
						<Header />
						{outlet ?? <ErrorMessage title="Router" content="No outlet for route!" />}
						{/* {(import.meta.env.DEV || import.meta.env.VITE_BASE_URL.includes("dev")) && <Footer />} */}
					</FetchCore>
				</FadeIn>
			) : (
				<ErrorMessage
					title="Internet connectivity"
					content="You are offline. Please check your Wi-Fi or data connection to continue."
					showContact={false}
					showRoutes={false}
				/>
			)}

			<PopupModal />
		</FullScreen>
	)
}

/**
 * Wrapper to ensure core data is fetched before rendering the rest of the app's pages.
 * @example <FetchCore>...</FetchCore>
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const FetchCore = ({ children }: ComponentWithChildrenProps<HTMLDivElement>): JSX.Element => {
	const { data: core, error } = useFetchCoreQuery({})

	const { updateSessionUser } = useAuthSessionDispatch()
	const { user } = useAuthSessionSelector()

	// Update the session partial user with the full user once we have core data
	useEffect(() => {
		if (!core || (error && isAPIError(error))) return

		const user = tidyUpUser(core.patient_details)
		updateSessionUser(user)
	}, [updateSessionUser, error, core])

	// Show errors
	if (error) {
		if (isAPIError(error))
			return <ErrorMessage title="API Error" content={error.data.SystemErrorMessage?.toString()} />

		if (isHTTPError(error)) return <ErrorMessage title="HTTP Error" content={error.status.toString()} />

		return <ErrorMessage title="Unknown Error" content="Failed to fetch core data!" />
	}

	// Wait for core data & the full user before rendering the app
	if (!core || !user || isPartialUser(user)) return <LoadingSpinner size={loadingSpinnerIconSize} />

	return <>{children}</>
}

export default App
