import { useCallback } from "react"
import { useUsageQuery } from "~/api/cloud-run/client"

import Form from "~/components/controls/forms/form"
import EmailAddressInput from "~/components/controls/inputs/emailAddress"
import BackOrContinueButtons from "~/components/onboarding/backOrContinueButtons"
import Paragraphs from "~/components/onboarding/paragraphs"
import StageWrapper from "~/components/onboarding/wrapper"
import Paragraph from "~/components/standard/text/paragraph"
import { useFlowContext } from "~/contexts/flow"
import { useFormDispatch } from "~/hooks/useForm"
import { useOnboardingDispatch, useOnboardingSelector } from "~/hooks/useOnboarding"
import type { OnFormSubmitCallback } from "~/types/components/controls/form"
import { OnboardingStage } from "~/types/components/pages/onboarding"
import type { ComponentProps } from "~/types/components/props"

enum HTMLElementIdentifiers {
	EmailAddressForm = "registerEmailAddress",
	EmailAddress = "emailAddress"
}

/**
 * The third stage of the onboarding process.
 * This stage collects the user's email address for registering a new account.
 * This should be wrapped in a <StageFlow /> component!
 * @example <EmailAddressStage />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const EmailAddressStage = ({ ...props }: ComponentProps<HTMLDivElement>): JSX.Element => {
	// Form
	const { showWarning } = useFormDispatch(HTMLElementIdentifiers.EmailAddressForm)

	// Onboarding
	const { transfer } = useFlowContext()
	const { setEmailAddress, clearEmailAddress } = useOnboardingDispatch()
	const { emailAddress } = useOnboardingSelector()

	// API
	const { currentData: usage } = useUsageQuery({})

	const onSubmitted = useCallback<OnFormSubmitCallback>(
		(values): void => {
			const emailAddress = values.get(HTMLElementIdentifiers.EmailAddress) as string | null

			// Shouldn't happen, but check just in case to please TypeScript
			if (emailAddress === null) {
				console.warn("No email address was entered, even though the input is required?!")
				showWarning(HTMLElementIdentifiers.EmailAddress, "Enter your email address!")
				clearEmailAddress()
				return
			}

			setEmailAddress(emailAddress)

			if (!usage) {
				console.warn("API usage data is unavailable! Assuming partial lookup is available...")
				transfer(OnboardingStage.PartialAddress)
				return
			}

			// Skip partial address lookup/suggestion if we have hit the API usage limits
			console.info(
				`Today's usage is ${usage.usage_today.toString()}/${usage.daily_limit.toString()} API request(s). There are ${(usage.monthly_buffer - usage.monthly_buffer_used).toString()}/${usage.monthly_buffer.toString()} API request(s) remaining in this month's buffer.`
			)
			if (usage.usage_today < usage.daily_limit) transfer(OnboardingStage.PartialAddress)
			else transfer(OnboardingStage.ManualAddress)
		},
		[showWarning, clearEmailAddress, setEmailAddress, transfer, usage]
	)

	return (
		<StageWrapper {...props}>
			<Paragraphs>
				<Paragraph>Please enter your email below to continue creating your account.</Paragraph>
			</Paragraphs>
			<Form id={HTMLElementIdentifiers.EmailAddressForm} onSubmit={onSubmitted}>
				<EmailAddressInput
					id={HTMLElementIdentifiers.EmailAddress}
					initialValue={emailAddress ?? undefined}
					isFocused={true}
				/>

				<BackOrContinueButtons previousStage={OnboardingStage.DateOfBirth} />
			</Form>
		</StageWrapper>
	)
}

export default EmailAddressStage
