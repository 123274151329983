import { ArrowUpOnSquareIcon } from "@heroicons/react/24/outline"
import { ArrowDownTrayIcon, XMarkIcon } from "@heroicons/react/24/solid"
import { useCallback, useEffect, useState } from "react"

import Button from "~/components/controls/button"
import Image from "~/components/controls/image"
import Paragraph from "~/components/standard/text/paragraph"
import { longerOpacityTransitionStyles } from "~/config/transitions"
import { isApple } from "~/helpers/device"
import type { OnClickCallback } from "~/types/components/controls/button"
import type { ComponentProps } from "~/types/components/props"

enum HTMLElementIdentifiers {
	InstallPrompt = "installPrompt"
}

/**
 * A popup modal for instructions on installing the app as a PWA.
 * @example <InstallPrompt />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.1.0
 */
const InstallPrompt = ({ ...props }: ComponentProps<HTMLDivElement>): JSX.Element => {
	const [showInstallPrompt, setShowInstallPrompt] = useState<boolean>(false)

	// Hides the install prompt...
	const hideInstallPrompt = useCallback(() => {
		setShowInstallPrompt(false)
		localStorage.setItem("dismissInstallPrompt", "true")

		const installPromptElement = document.getElementById(HTMLElementIdentifiers.InstallPrompt)
		if (!installPromptElement) return

		installPromptElement.classList.remove("opacity-100", "delay-1000")
		installPromptElement.classList.add("opacity-0")
	}, [])

	// Show the install prompt if it's available, unless it's already been dismissed...
	useEffect(() => {
		setShowInstallPrompt(
			(isApple() || globalThis.progressiveWebAppInstallEvent !== undefined) &&
				localStorage.getItem("dismissInstallPrompt") !== "true"
		)
	}, [])

	return (
		<div
			{...props}
			id={HTMLElementIdentifiers.InstallPrompt}
			className={`${longerOpacityTransitionStyles} ${showInstallPrompt ? "opacity-100 delay-1000" : "opacity-0"} fixed top-0 z-50 w-full p-4 sm:w-2/3 md:w-2/4`}>
			<div className="flex flex-col gap-y-2 rounded-md border border-controlBorder bg-secondaryButtonActive p-4 text-center shadow">
				{/* APPLE JUST HAVE TO BE DIFFERENT */}
				{isApple() ? (
					<AppleInstructions hideInstallPrompt={hideInstallPrompt} />
				) : (
					<InstallControls hideInstallPrompt={hideInstallPrompt} />
				)}
			</div>
		</div>
	)
}

/**
 * Controls for installing applicable to all devices, except Apple's.
 * @example <Instructions />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.1.0
 */
const InstallControls = ({ hideInstallPrompt }: { hideInstallPrompt: () => void }): JSX.Element => {
	// Runs when the install button is clicked...
	const onInstallClick = useCallback<OnClickCallback>(() => {
		if (!globalThis.progressiveWebAppInstallEvent) return // Do nothing if the event isn't available

		/* eslint-disable promise/prefer-await-to-then */
		globalThis.progressiveWebAppInstallEvent
			.prompt()
			.then((data: object) => {
				const { outcome } = data as {
					outcome: "dismissed" | "accepted"
					platform: string
				}

				if (outcome === "dismissed") console.warn("PWA installation aborted!")

				return data
			})
			.catch((error: unknown) => {
				console.warn(`Failed to install PWA! (${error?.toString() ?? "Unknown error"})`)
			})
			.finally(() => {
				hideInstallPrompt()
			})
	}, [hideInstallPrompt])

	return (
		<>
			<Paragraph className="font-bold">Install App</Paragraph>
			<Paragraph>
				Installing this app will add it to your device&apos;s home screen for easy launching in the future.
			</Paragraph>
			<div className="flex flex-row justify-between gap-x-4">
				<Button
					label="Install"
					wide={true}
					standardHeight={false}
					onClick={onInstallClick}
					startIcon={<ArrowDownTrayIcon width={20} height={20} />}
				/>
				<Button
					label="Dismiss"
					wide={true}
					standardHeight={false}
					onClick={hideInstallPrompt}
					startIcon={<XMarkIcon width={20} height={20} />}
				/>
			</div>
		</>
	)
}

/**
 * Instructions applicable to Apple devices.
 * @example <AppleInstructions />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.1.0
 */
const AppleInstructions = ({ hideInstallPrompt }: { hideInstallPrompt: () => void }): JSX.Element => (
	<>
		<Paragraph className="font-bold">Install Instructions</Paragraph>
		<Paragraph>
			Please add this to your mobile phone by pressing the{" "}
			<ArrowUpOnSquareIcon width={20} height={20} className="mb-1 inline" /> icon displayed on the browser bar
			either above or below, then scroll down and press &quot;add to home screen&quot;
		</Paragraph>
		<Image
			sourceUrl="/images/screenshots/safari-share.jpeg"
			screenReaderDescription="The share button on Safari."
			className="w-full rounded-lg border border-controlBorder shadow"
		/>
		<div className="flex flex-row">
			<Button
				label="Dismiss"
				wide={true}
				standardHeight={false}
				onClick={hideInstallPrompt}
				startIcon={<XMarkIcon width={20} height={20} />}
			/>
		</div>
	</>
)

export default InstallPrompt
