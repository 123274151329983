import type { ComponentWithChildrenProps } from "~/types/components/props"

/**
 * A pre-styled standard HTML paragraph.
 * @example <Paragraph>Hello World</Paragraph>
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const Paragraph = ({ children, ...props }: ComponentWithChildrenProps<HTMLParagraphElement>): JSX.Element => (
	<p {...props}>{children}</p>
)

export default Paragraph
