import { ArrowLeftIcon } from "@heroicons/react/24/solid"
import { useCallback } from "react"
import { useNavigate } from "react-router-dom"

import Button from "~/components/controls/button"
import Paragraph from "~/components/standard/text/paragraph"
import type { OnClickCallback } from "~/types/components/controls/button"
import type { ComponentWithChildrenProps } from "~/types/components/props"

/**
 * Default size of icons within section headings.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const sectionHeadingIconSize = 24

/**
 * A pre-styled standard HTML section with a heading (e.g., upcoming appointments on the home page).
 * @param {string} title The title of the section.
 * @param {JSX.Element} icon The icon to display next to the title.
 * @example <Section title="Hello World" icon={<CalendarDaysIcon width={24} />} >...</Section>
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const Section = ({
	title,
	icon,

	showBackButton = true,
	onBack,

	innerClassName,

	children,
	...props
}: ComponentWithChildrenProps<
	HTMLDivElement,
	{
		title?: string
		icon?: JSX.Element

		showBackButton?: boolean
		onBack?: OnClickCallback

		innerClassName?: string
	}
>): JSX.Element => {
	const navigate = useNavigate()

	// Runs when the back button is clicked...
	const onBackClick = useCallback<OnClickCallback>(
		event => {
			if (onBack) onBack(event)
			else navigate(-1) // Go back 1 page if no custom back function is provided
		},
		[navigate, onBack]
	)

	return (
		<section className={`flex flex-grow flex-col ${props.className ?? ""}`.trimEnd()}>
			{title !== undefined && (
				<div className="flex flex-row items-center gap-x-2 bg-white p-3">
					{showBackButton && window.history.length > 2 ? (
						<Button invisible={true} onClick={onBackClick}>
							<ArrowLeftIcon className="fill-black" width={24} />
						</Button>
					) : (
						icon
					)}
					<Paragraph className="font-bold text-text">{title}</Paragraph>
				</div>
			)}
			<div {...props} className={`flex flex-grow flex-col gap-y-4 p-4 ${innerClassName ?? ""}`.trimEnd()}>
				{children}
			</div>
		</section>
	)
}

export default Section
