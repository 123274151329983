import type { Appointment } from "~/api/osteo-physio/types/models/appointments/appointment"
import type { Modify } from "~/types/helpers/modify"

/**
 * Represents an appointment booking consumed by the API.
 * @see https://dev.orthoandphysio.com/appointments
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.1.0
 */
export type BookAppointment = Omit<Appointment, "id" | "cost">

/**
 * Represents an appointment booking returned by the API.
 * @property {number} user_id The unique identifier of the user (patient).
 * @property {number} therapy_id The unique identifier of the therapy.
 * @see https://dev.orthoandphysio.com/appointments
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.1.0
 */
export type BookedAppointment = Modify<
	Appointment,
	{
		id: number

		user_id?: number // Only for newly booked appointments!
		therapy_id: number

		invoice_issued?: boolean // Only on previous appointments!
		earlier_cancellation_requested?: boolean // Only on previous & future appointments!
	}
>

/**
 * Ensures an appointment is a booked appointment.
 * @param {Appointment | BookedAppointment} appointment The available/booked appointment to inflate.
 * @returns {appointment is BookedAppointment} Whether the appointment is a booked appointment.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.1.0
 */
export const isBookedAppointment = (appointment: Appointment | BookedAppointment): appointment is BookedAppointment =>
	appointment.id !== 0 &&
	"therapy_id" in appointment &&
	typeof appointment.therapy_id === "number" &&
	appointment.therapy_id >= 0
