/**
 * Converts a value to a string, but doesn't return "null" or "undefined" for null or undefined values.
 * This is purely for syntactic sugar.
 * @param {number | null | undefined} value The value to convert to a string.
 * @returns {string} The string representation of the value, or null/undefined if the parameter was null/undefined.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const toString = (value?: number | null): string | null | undefined => {
	if (value === null) return null
	if (value === undefined) return undefined

	return value.toString()
}

/**
 * Converts a value to a number, but accepts null or undefined.
 * This is purely for syntactic sugar.
 * @param {string | null | undefined} value The value to convert to a number.
 * @returns {number} The numeric value, or null/undefined if the parameter was null/undefined.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const toNumber = (value?: string | null): number | null | undefined => {
	if (value === null) return null
	if (value === undefined) return undefined

	const number = parseInt(value, 10)
	if (isNaN(number)) return undefined

	return number
}
