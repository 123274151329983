import { setTags, setUser } from "@sentry/react"
import { useCallback } from "react"

import type { User } from "~/api/osteo-physio/types/models/user/user"
import { PersistentKeys } from "~/helpers/localStorage"
import type { PartialUser, Token } from "~/state/slices/authSession"
import { slice } from "~/state/slices/authSession"
import { useReduxDispatch, useReduxSelector } from "~/state/store"

/**
 * React hook to update the logged in session.
 * @returns {object} Functions to update the session/authentication.
 * @example const { updateSessionUser, updateAuthenticationToken } = useAuthSessionDispatch()
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const useAuthSessionDispatch = (): {
	updateSessionUser: (user: User) => void
	updateAuthenticationToken: (token: Token) => void

	updateAuthSession: (id: number, user: PartialUser, token: Token) => void
	clearAuthSession: () => void
} => {
	const dispatch = useReduxDispatch()

	// Updates the user
	const updateSessionUser = useCallback(
		(user: User) => {
			localStorage.setItem(PersistentKeys.SessionUser, JSON.stringify(user))

			setUser({
				id: user.user_id,
				email: user.email ?? undefined,
				username: [user.first_name, user.last_name].filter(name => name !== "").join(" "),
				ip_address: "{{auto}}"
			})

			setTags({
				association: user.association_id
			})

			dispatch(
				slice.actions.update({
					user: user
				})
			)
		},
		[dispatch]
	)

	// Updates the token
	const updateAuthenticationToken = useCallback(
		(token: Token) => {
			localStorage.setItem(PersistentKeys.SessionToken, JSON.stringify(token))

			dispatch(
				slice.actions.update({
					token: token
				})
			)
		},
		[dispatch]
	)

	// Updates everything
	const updateAuthSession = useCallback(
		(id: number, user: PartialUser, token: Token) => {
			localStorage.setItem(PersistentKeys.SessionID, id.toString())
			localStorage.setItem(PersistentKeys.SessionUser, JSON.stringify(user))
			localStorage.setItem(PersistentKeys.SessionToken, JSON.stringify(token))

			setUser({
				id: user.id,
				email: undefined,
				username: user.fullName,
				ip_address: "{{auto}}"
			})

			dispatch(
				slice.actions.update({
					id: id,
					user: user,
					token: token
				})
			)
		},
		[dispatch]
	)

	// Clears everything
	const clearAuthSession = useCallback(() => {
		localStorage.removeItem(PersistentKeys.SessionID)
		localStorage.removeItem(PersistentKeys.SessionUser)
		localStorage.removeItem(PersistentKeys.SessionToken)

		dispatch(
			slice.actions.update({
				id: undefined,
				user: undefined,
				token: undefined
			})
		)
	}, [dispatch])

	return {
		updateSessionUser,
		updateAuthenticationToken,
		updateAuthSession,
		clearAuthSession
	}
}

/**
 * React hook to get the currently logged in session.
 * @returns {object} Data regarding the session/authentication.
 * @example const { id, user, token } = useAuthSessionSelector()
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const useAuthSessionSelector = (): {
	id: number | null
	user: PartialUser | User | null
	token: Token | null
	isSignedIn: boolean // This is computed based on the above values, just for convenience
} => {
	const reduxData = useReduxSelector(store => store.authSession)

	return {
		id: reduxData.id ?? null,
		user: reduxData.user ?? null,
		token: reduxData.token ?? null,

		isSignedIn:
			reduxData.id !== undefined && reduxData.user !== undefined && reduxData.token !== undefined ? true : false
	}
}
