import type { ButtonProps } from "~/components/controls/button"
import Button, { loadingButtonIconSize } from "~/components/controls/button"
import LoadingSpinner from "~/components/loadingSpinner"
import { useFormSelector } from "~/hooks/useForm"
import { ButtonThemes } from "~/types/components/controls/button"
import type { ComponentProps } from "~/types/components/props"

/**
 * A pre-styled standard HTML submit button for a form.
 * @example <SubmitButton />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const SubmitButton = ({
	label = "Continue",
	loadingLabel = "Loading...",

	spaceAbove = true,

	...props
}: ComponentProps<
	HTMLButtonElement,
	ButtonProps & {
		loadingLabel?: string
		spaceAbove?: boolean
	}
>): JSX.Element => {
	const { isLoading } = useFormSelector()

	// We use <button> instead of <input> so we can have children (i.e., loading spinner)
	return (
		<Button
			{...props}
			type="submit"
			label={isLoading ? loadingLabel : label}
			wide={true}
			theme={ButtonThemes.WhiteOnPrimary}
			className={`py-3 shadow-sm ${spaceAbove ? "mt-2" : ""} ${props.className ?? ""}`.trimEnd()}
			endIcon={<LoadingSpinner visible={false} inContainer={true} size={loadingButtonIconSize} />} // Only exists for equal spacing
			startIcon={<LoadingSpinner visible={isLoading} inContainer={true} size={loadingButtonIconSize} />}
		/>
	)
}

export default SubmitButton
