import { HashtagIcon } from "@heroicons/react/24/solid"
import { useCallback, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"

import { outOfSyncOffset, useLazyAttemptAuthenticationQuery } from "~/api/osteo-physio/client"
import { ErrorMessages, isAPIError, isHTTPError } from "~/api/osteo-physio/types/error"
import Form from "~/components/controls/forms/form"
import NumberInput from "~/components/controls/inputs/numberInput"
import BackOrContinueButtons from "~/components/onboarding/backOrContinueButtons"
import OfflineWarning from "~/components/onboarding/offlineWarning"
import Paragraphs from "~/components/onboarding/paragraphs"
import StageWrapper from "~/components/onboarding/wrapper"
import Paragraph from "~/components/standard/text/paragraph"
import { longerColorTransitionStyles } from "~/config/transitions"
import { inputDisabledColorStyles, inputIconSize } from "~/constants/components/input"
import { useAuthSessionDispatch } from "~/hooks/useAuthSession"
import { useFormDispatch } from "~/hooks/useForm"
import { useIsOnline } from "~/hooks/useIsOnline"
import { useOnboardingSelector } from "~/hooks/useOnboarding"
import { Routes } from "~/router"
import type { OnFormSubmitCallback } from "~/types/components/controls/form"
import { OnboardingStage } from "~/types/components/pages/onboarding"
import type { ComponentProps } from "~/types/components/props"

enum HTMLElementIdentifiers {
	VerifyPhoneNumber = "verifyPhoneNumber",
	VerificationCode = "verificationCode"
}

/**
 * The final stage of the onboarding process.
 * This stage verifies the user's phone number.
 * This should be wrapped in a <StageFlow /> component!
 * @example <VerifyCodeStage />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const VerifyCodeStage = ({ ...props }: ComponentProps<HTMLDivElement>): JSX.Element => {
	const { showWarning, updateLoading } = useFormDispatch(HTMLElementIdentifiers.VerifyPhoneNumber)

	const { phoneNumber } = useOnboardingSelector()

	const [attemptAuthentication, authenticationAttempt] = useLazyAttemptAuthenticationQuery()

	const { updateAuthSession } = useAuthSessionDispatch()

	const navigate = useNavigate()

	// Internet connectivity check
	const [isOnline] = useIsOnline()

	// Runs when the form is submitted...
	const onSubmitted = useCallback<OnFormSubmitCallback>(
		(values): void => {
			const verificationCode = values.get(HTMLElementIdentifiers.VerificationCode) as number | null

			// Shouldn't happen, but check just in case to please TypeScript
			if (verificationCode === null) {
				console.warn("No verification code was entered, even though the input is required?!")
				showWarning(HTMLElementIdentifiers.VerificationCode, "Enter the verification code!")
				return
			}

			if (phoneNumber === null) {
				console.warn("The phone number stage is not yet completed!")
				showWarning(HTMLElementIdentifiers.VerificationCode, "Previous stages are not yet complete.")
				return
			}

			/* eslint-disable promise/prefer-await-to-then */
			updateLoading(true)
			attemptAuthentication({
				phoneNumber,
				verificationCode: verificationCode.toString().padStart(4, "0"),
				userAgent: navigator.userAgent
			}).catch((error: unknown) => {
				console.warn(`Failed to attempt authentication! (${error?.toString() ?? "Unknown error"})`)
				showWarning(
					HTMLElementIdentifiers.VerificationCode,
					"Sorry, an unknown problem occurred. Please try again later."
				)
			})
		},
		[showWarning, updateLoading, attemptAuthentication, phoneNumber]
	)

	// Runs when the authentication attempt is complete...
	useEffect(() => {
		if (
			authenticationAttempt.isUninitialized ||
			authenticationAttempt.isFetching ||
			authenticationAttempt.isLoading
		)
			return

		updateLoading(false)

		if (authenticationAttempt.isError) {
			// API error message
			if (isAPIError(authenticationAttempt.error)) {
				if (authenticationAttempt.error.data.SystemErrorMessage === ErrorMessages.Success.valueOf()) return // API can return success within an error message?!

				if (
					authenticationAttempt.error.data.SystemErrorMessage ===
					ErrorMessages.InvalidVerificationCode.valueOf()
				) {
					console.warn("Invalid verification code entered.")
					showWarning(HTMLElementIdentifiers.VerificationCode, "Invalid verification code!")
					return
				}

				console.warn(
					`Failed to attempt authentication! (API said '${authenticationAttempt.error.data.SystemErrorMessage?.toString() ?? "Unknown error"}')`
				)
				showWarning(HTMLElementIdentifiers.VerificationCode, "API error! See console for more information.")
				return
			}

			// HTTP error code
			if (isHTTPError(authenticationAttempt.error)) {
				console.warn(
					`Failed to attempt authentication! (HTTP ${authenticationAttempt.error.status.toString()})`
				)
				showWarning(
					HTMLElementIdentifiers.VerificationCode,
					`HTTP ${authenticationAttempt.error.status.toString()}! See console for more information.`
				)
				return
			}

			// Unknown
			console.error(`Failed to attempt authentication! (${JSON.stringify(authenticationAttempt.error)})`)
			return
		}

		// Should never happen but pleases TypeScript
		if (!("access_token" in authenticationAttempt.data)) {
			console.error("Passed error check without catching the error?!")
			showWarning(
				HTMLElementIdentifiers.VerificationCode,
				"Sorry, there was an unexpected problem. Try again later."
			)
			return
		}

		// Give or take 3 minutes ago, just so we refresh slightly before the token expires
		const grantedAt = new Date(new Date().getTime() - outOfSyncOffset)

		const sessionId = authenticationAttempt.data.id

		const userId = parseInt(authenticationAttempt.data.user_id)
		const userName = authenticationAttempt.data.name

		const accessToken = authenticationAttempt.data.access_token
		const refreshToken = authenticationAttempt.data.refresh_token
		const expiresAt = new Date(authenticationAttempt.data.expires_at * 1000)

		// Do not continue if the API returns wonky data
		if (isNaN(userId)) {
			console.error("Failed to parse the user ID!")
			showWarning(
				HTMLElementIdentifiers.VerificationCode,
				"Sorry, there was an unexpected problem. Try again later."
			)
			return
		}

		console.info(`Granted token that expires at ${expiresAt.toISOString()}!`)
		updateAuthSession(
			sessionId,
			{
				id: userId,
				fullName: userName
			},
			{
				access: accessToken,
				refresh: refreshToken,
				expiresAt: expiresAt.toISOString(),

				grantedAt: new Date(grantedAt).toISOString()
			}
		)

		navigate(Routes.Home)
	}, [showWarning, updateLoading, updateAuthSession, navigate, authenticationAttempt])

	return (
		<StageWrapper {...props}>
			<Paragraphs>
				<Paragraph>
					Please enter the verification code we have sent by Email and SMS to sign into your account.
				</Paragraph>
			</Paragraphs>
			<OfflineWarning visible={!isOnline} />
			<div className="flex flex-col gap-y-6">
				<Form id={HTMLElementIdentifiers.VerifyPhoneNumber} onSubmit={onSubmitted}>
					<NumberInput
						id={HTMLElementIdentifiers.VerificationCode}
						label="Verification Code"
						placeholder="1234..."
						maximumLength={9999}
						tooltip="Please enter the verification code."
						missingValueWarningMessage="Enter the verification code!"
						isFocused={true}
						isDisabled={!isOnline}
						startIcon={isLoading => (
							<HashtagIcon
								className={`${longerColorTransitionStyles} ${isLoading ? inputDisabledColorStyles : "fill-primary"}`}
								width={inputIconSize}
								height={inputIconSize}
							/>
						)}
					/>

					<BackOrContinueButtons previousStage={OnboardingStage.PhoneNumber} isDisabled={!isOnline} />
				</Form>
				<Paragraph className="text-sm">
					Ensure you agree to our <Link to={Routes.TermsConditions}>Terms & Conditions</Link>.
				</Paragraph>
			</div>
		</StageWrapper>
	)
}

export default VerifyCodeStage
