import type { User } from "~/api/osteo-physio/types/models/user/user"
import { orNull } from "~/helpers/null"
import type { Modify } from "~/types/helpers/modify"

/**
 * Tidy up methods should take the exact data returned by the Osteo & Physio API and return a better typed version of it.
 * Property names should be kept identical. No additional properties should be added, and none removed either.
 */

/**
 * Represents the badly typed user returned by the API.
 * @see https://dev.osteoandphysio.co.uk/data
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export type BadlyTypedUser = Modify<
	User,
	{
		// API returns this as "" instead of null when unset...?!
		email?: string | null
		home_phone?: string | null
		address_line_1?: string | null
		address_line_2?: string | null
		address_line_3?: string | null
		city?: string | null
		state?: string | null
		post_code?: string | null

		// API returns "1" and "0"..., or sometimes an actual boolean?!
		agree_survey?: string | number | boolean | null
		agree_reminders?: string | number | boolean | null
		agree_contact?: string | number | boolean | null

		// API returns these NUMBERS as strings?!
		association_id?: string | number | null
		user_id?: string | number | null
	}
>

/**
 * Cleans up the types on a badly typed user returned by the API.
 * @param {BadlyTypedUser} user The badly typed user.
 * @returns {User} The cleaned up user.
 * @see https://dev.osteoandphysio.co.uk/data
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const tidyUpUser = (user: BadlyTypedUser): User => {
	const email = orNull(user.email)?.trim() ?? null
	const home_phone = orNull(user.home_phone)?.trim() ?? null
	const address_line_1 = orNull(user.address_line_1)?.trim() ?? null
	const address_line_2 = orNull(user.address_line_2)?.trim() ?? null
	const address_line_3 = orNull(user.address_line_3)?.trim() ?? null
	const city = orNull(user.city)?.trim() ?? null
	const state = orNull(user.state)?.trim() ?? null
	const post_code = orNull(user.post_code)?.trim() ?? null

	const agree_survey =
		(typeof user.agree_survey === "string" ? orNull(user.agree_survey)?.trim() : user.agree_survey) ?? null
	const agree_reminders =
		(typeof user.agree_reminders === "string" ? orNull(user.agree_reminders)?.trim() : user.agree_reminders) ?? null
	const agree_contact =
		(typeof user.agree_contact === "string" ? orNull(user.agree_contact)?.trim() : user.agree_contact) ?? null

	const association_id =
		(typeof user.association_id === "string" ? orNull(user.association_id)?.trim() : user.association_id) ?? null
	const user_id = (typeof user.user_id === "string" ? orNull(user.user_id)?.trim() : user.user_id) ?? null

	return {
		...user,

		email,
		home_phone,
		address_line_1,
		address_line_2,
		address_line_3,
		city,
		state,
		post_code,

		agree_survey: agree_survey === true || agree_survey === "1",
		agree_reminders: agree_reminders === true || agree_reminders === "1",
		agree_contact: agree_contact === true || agree_contact === "1",

		association_id: typeof association_id === "string" ? parseInt(association_id, 10) : (association_id ?? NaN),
		user_id: typeof user_id === "string" ? parseInt(user_id, 10) : (user_id ?? NaN)
	}
}
