import { XMarkIcon } from "@heroicons/react/16/solid"
import { BuildingOfficeIcon, MapPinIcon, UserIcon } from "@heroicons/react/24/solid"
import { useCallback, useMemo } from "react"

import { useFetchAppointmentFiltersQuery, useFetchCoreQuery } from "~/api/osteo-physio/client"
import { isAPIError, isHTTPError } from "~/api/osteo-physio/types/error"
import FilteredDatePicker from "~/components/appointments/filters/date"
import type { FormProps } from "~/components/controls/forms/form"
import Form from "~/components/controls/forms/form"
import DropDownInput from "~/components/controls/inputs/dropDown"
import ErrorMessage from "~/components/errorMessage"
import LoadingSpinner, { loadingSpinnerIconSize } from "~/components/loadingSpinner"
import { dropDownIconSize, dropDownIconStyles } from "~/constants/components/dropDown"
import { inputDisabledColorStyles, inputLinkIconSize } from "~/constants/components/input"
import { toString } from "~/helpers/convert"
import { discardIrrelevantFilterableTypes } from "~/helpers/filterableTypes"
import { orNull } from "~/helpers/null"
import { AnyIdentifier, prependToObject, toObject, toTransformedObject } from "~/helpers/object"
import { useAppointmentTypes } from "~/hooks/appointments/useAppointmentTypes"
import { useTherapyTypes } from "~/hooks/appointments/useTherapyTypes"
import {
	useAppointmentFilterDispatch,
	useChosenGenderIdentifier,
	useChosenLocationIdentifier,
	useChosenPractitionerIdentifier
} from "~/hooks/useAppointmentFilters"
import { useAuthSessionSelector } from "~/hooks/useAuthSession"
import { useFormDispatch, useFormSelector } from "~/hooks/useForm"
import { useMediaQueries } from "~/hooks/useMediaQueries"
import { isPartialUser } from "~/state/slices/authSession"
import type { OnItemChooseCallback } from "~/types/components/controls/dropDown"
import type { ComponentProps } from "~/types/components/props"

enum HTMLElementIdentifiers {
	BookAppointment = "bookAppointment",

	TherapyType = "therapyType", // Required
	AppointmentType = "appointmentType", // Required

	ClinicLocation = "clinicLocation",
	PractitionerGender = "practitionerGender",
	PractitionerName = "practitioner",

	Date = "date"
}

/**
 * The form to filter the available appointments.
 * This includes various drop-downs and a date selector.
 * @example <FilterForm />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.1.0
 */
const FilterForm = ({
	hideDatePicker = false,

	...props
}: ComponentProps<
	HTMLFormElement,
	Omit<FormProps, "id"> & {
		hideDatePicker?: boolean
	}
>): JSX.Element => {
	// Responsiveness
	const { isMediumWidth } = useMediaQueries()

	// Therapy & appointment types from Redux store
	const { chosenTherapyTypeIdentifier, setChosenTherapyTypeIdentifier, therapyTypes } = useTherapyTypes()
	const { chosenAppointmentTypeIdentifier, setChosenAppointmentTypeIdentifier, appointmentTypes } =
		useAppointmentTypes()

	// Remaining filters from Redux store
	const chosenLocationIdentifier = useChosenLocationIdentifier()
	const chosenPractitionerIdentifier = useChosenPractitionerIdentifier()
	const chosenGenderIdentifier = useChosenGenderIdentifier()
	const {
		setChosenLocationIdentifier,
		clearChosenLocationIdentifier,
		setChosenPractitionerIdentifier,
		clearChosenPractitionerIdentifier,
		setChosenGenderIdentifier,
		clearChosenGenderIdentifier
	} = useAppointmentFilterDispatch()

	// Fetch core data & possible appointment filters using the required filters
	const { data: core, error: coreError } = useFetchCoreQuery({}) // Cached from useTherapyTypes()/useAppointmentTypes() hooks above

	const { currentData: availableFilters, error: availableFiltersError } = useFetchAppointmentFiltersQuery(
		{
			therapyId: chosenTherapyTypeIdentifier ?? undefined,
			typeId: chosenAppointmentTypeIdentifier ?? undefined,

			locationId: chosenLocationIdentifier ?? undefined,
			practitionerId: chosenPractitionerIdentifier ?? undefined,
			genderId: chosenGenderIdentifier ?? undefined
		},
		{
			refetchOnMountOrArgChange: true // Always refetch whenever the therapy or appointment types change
		}
	)

	// Discard any types that don't apply to the returned filters
	const { genders, locations, practitioners } = useMemo(
		() => discardIrrelevantFilterableTypes(core, availableFilters),
		[core, availableFilters]
	)

	// Form state
	const { showWarning } = useFormDispatch(HTMLElementIdentifiers.BookAppointment)
	const { isLoading } = useFormSelector(HTMLElementIdentifiers.BookAppointment)

	// Signed in user
	const { user } = useAuthSessionSelector()

	// Runs whenever any drop-down's value changes...
	const onDropDownChoose = useCallback<OnItemChooseCallback>(
		(value, text, { currentTarget }) => {
			const elementId = currentTarget.id

			// Don't continue if we don't know what this element is (we won't be able to assign its respective property)
			if (!(Object.values(HTMLElementIdentifiers) as string[]).includes(elementId)) {
				showWarning(elementId, "Unknown form field!")
				console.warn(`Element ID '${elementId}' not found in known HTML element identifiers!`)
				return
			}

			// Don't continue if no value was chosen
			if (orNull(value) === null) {
				showWarning(elementId, "Please choose a value.")
				console.warn(`No value chosen for drop down '${elementId}'!`)
				return
			}

			// Don't continue if the chosen value is not a number
			const choiceId = parseInt(value)
			if (isNaN(choiceId)) {
				showWarning(elementId, "Invalid value chosen! Please try again.")
				console.warn(`Failed to parse identifier '${value}' (${text}) as number!`)
				return
			}

			switch (elementId) {
				// If this is the therapy type then nuke all other choices as they could all change
				case HTMLElementIdentifiers.TherapyType.valueOf():
					setChosenTherapyTypeIdentifier(choiceId)
					return

				// If this is the appointment type then nuke all other choices except therapy type as they could change
				case HTMLElementIdentifiers.AppointmentType.valueOf():
					setChosenAppointmentTypeIdentifier(choiceId)
					break

				// Otherwise, update Redux like normal
				case HTMLElementIdentifiers.ClinicLocation.valueOf():
					setChosenLocationIdentifier(choiceId)
					break
				case HTMLElementIdentifiers.PractitionerName.valueOf():
					setChosenPractitionerIdentifier(choiceId)
					break
				case HTMLElementIdentifiers.PractitionerGender.valueOf():
					setChosenGenderIdentifier(choiceId)
					break

				default:
					console.warn(`Unknown element with ID '${elementId}'!`)
					break
			}
		},
		[
			showWarning,
			setChosenTherapyTypeIdentifier,
			setChosenAppointmentTypeIdentifier,
			setChosenLocationIdentifier,
			setChosenPractitionerIdentifier,
			setChosenGenderIdentifier
		]
	)

	// Shortcuts to available IDs for each filter
	const therapyTypeIdentifiers = useMemo(() => (therapyTypes ? Array.from(therapyTypes.keys()) : []), [therapyTypes])
	const appointmentTypeIdentifiers = useMemo(
		() => (appointmentTypes ? Array.from(appointmentTypes.keys()) : []),
		[appointmentTypes]
	)
	const locationIdentifiers = useMemo(() => (locations ? Array.from(locations.keys()) : []), [locations])
	const practitionerIdentifiers = useMemo(
		() => (practitioners ? Array.from(practitioners.keys()) : []),
		[practitioners]
	)
	const genderIdentifiers = useMemo(() => (genders ? Array.from(genders.keys()) : []), [genders])

	// Convert filter pairs to choices for the drop-downs
	const therapyTypeChoices = useMemo(() => (therapyTypes ? toObject(therapyTypes) : undefined), [therapyTypes])
	const appointmentTypeChoices = useMemo(
		() => (appointmentTypes ? toObject(appointmentTypes) : undefined),
		[appointmentTypes]
	)
	const locationChoices = useMemo(() => {
		if (!locations) return undefined

		const choices = toTransformedObject(locations, ({ name }) => name)
		return Object.keys(choices).length > 1 ? prependToObject(choices) : choices
	}, [locations])
	const practitionerChoices = useMemo(() => {
		if (!practitioners) return undefined

		const choices = toTransformedObject(practitioners, ({ name }) => name)
		return Object.keys(choices).length > 1 ? prependToObject(choices) : choices
	}, [practitioners])
	const genderChoices = useMemo(() => {
		if (!genders) return undefined

		const choices = toObject(genders)
		return Object.keys(choices).length > 1 ? prependToObject(choices) : choices
	}, [genders])

	// Calculate appropriate initial values for the drop-downs
	const initialLocationIdentifier = useMemo(() => {
		// Use the location they've already chosen
		if (chosenLocationIdentifier !== null) return chosenLocationIdentifier.toString()

		// No value if we have no locations yet
		if (!locations) return undefined
		const entries = Array.from(locations.entries())

		// Try find a clinic location that matches the user's address
		const matchingIdentifier =
			user && !isPartialUser(user)
				? entries.find(([, { city }]) => city.toLowerCase() === user.city)?.[0]
				: undefined
		if (matchingIdentifier !== undefined) return matchingIdentifier.toString()

		// Default to 'Any' if there's more than one
		if (locations.size > 1) return AnyIdentifier.toString()

		// Fallback to the first location
		return entries[0]?.[0].toString()
	}, [locations, user, chosenLocationIdentifier])

	const initialPractitionerIdentifier = useMemo(() => {
		// Use the practitioner they've already chosen
		if (chosenPractitionerIdentifier !== null) return chosenPractitionerIdentifier.toString()

		// No value if we have no practitioners yet
		if (!practitioners) return undefined

		// Default to 'Any' if there's more than one
		if (practitioners.size > 1) return AnyIdentifier.toString()

		// Fallback to the first practitioner
		return Array.from(practitioners.entries())[0]?.[0].toString()
	}, [practitioners, chosenPractitionerIdentifier])

	const initialGenderIdentifier = useMemo(() => {
		// Use the gender they've already chosen
		if (chosenGenderIdentifier !== null) return chosenGenderIdentifier.toString()

		// No value if we have no genders yet
		if (!genders) return undefined

		// Default to 'Any' if there's more than one
		if (genders.size > 1) return AnyIdentifier.toString()

		// Fallback to the first practitioner
		return Array.from(genders.entries())[0]?.[0].toString()
	}, [genders, chosenGenderIdentifier])

	// Show fetch errors
	if (coreError) {
		if (isAPIError(coreError))
			return <ErrorMessage title="API Error" content={coreError.data.SystemErrorMessage?.toString()} />

		if (isHTTPError(coreError)) return <ErrorMessage title="HTTP Error" content={coreError.status.toString()} />

		return <ErrorMessage title="Unknown Error" content="Failed to fetch core data!" />
	}

	if (availableFiltersError) {
		if (isAPIError(availableFiltersError))
			return (
				<ErrorMessage title="API Error" content={availableFiltersError.data.SystemErrorMessage?.toString()} />
			)

		if (isHTTPError(availableFiltersError))
			return <ErrorMessage title="HTTP Error" content={availableFiltersError.status.toString()} />

		return <ErrorMessage title="Unknown Error" content="Failed to fetch appointment filters!" />
	}

	// Do not continue if a full user is not available
	if (!user || isPartialUser(user)) return <ErrorMessage title="User" content="User is not signed in!" />

	return (
		<Form {...props} id={HTMLElementIdentifiers.BookAppointment}>
			<DropDownInput
				id={HTMLElementIdentifiers.TherapyType}
				label="Therapy"
				tooltip="Select the type of therapy you would like."
				isDisabled={therapyTypeIdentifiers.length <= 1}
				isLoading={isLoading || !therapyTypeChoices}
				initialValue={toString(chosenTherapyTypeIdentifier) ?? undefined}
				choices={therapyTypeChoices}
				onChoose={onDropDownChoose}
				sideBySide={isMediumWidth}
				startIcon={
					<BuildingOfficeIcon
						className={`${dropDownIconStyles} ${isLoading ? inputDisabledColorStyles : "fill-primary"}`}
						width={dropDownIconSize}
						height={dropDownIconSize}
					/>
				}
			/>

			<DropDownInput
				id={HTMLElementIdentifiers.AppointmentType}
				label="Appointment"
				tooltip="Select the type of appointment."
				isDisabled={appointmentTypeIdentifiers.length <= 1}
				isLoading={isLoading || !appointmentTypeChoices}
				initialValue={toString(chosenAppointmentTypeIdentifier) ?? undefined}
				choices={appointmentTypeChoices}
				onChoose={onDropDownChoose}
				sideBySide={isMediumWidth}
				startIcon={
					<BuildingOfficeIcon
						className={`${dropDownIconStyles} ${isLoading ? inputDisabledColorStyles : "fill-primary"}`}
						width={dropDownIconSize}
						height={dropDownIconSize}
					/>
				}
			/>

			<DropDownInput
				id={HTMLElementIdentifiers.ClinicLocation}
				label="Clinic Location"
				tooltip="Select the location of the clinic you would prefer to visit."
				isRequired={false}
				isDisabled={locationIdentifiers.length <= 1}
				isLoading={isLoading || !locationChoices}
				initialValue={initialLocationIdentifier}
				choices={locationChoices}
				onChoose={onDropDownChoose}
				sideBySide={isMediumWidth}
				linkText={chosenLocationIdentifier !== null && locationIdentifiers.length <= 1 ? "Show all" : undefined}
				linkIcon={<XMarkIcon width={inputLinkIconSize} height={inputLinkIconSize} />}
				isLinkDisabled={false}
				onLinkClick={clearChosenLocationIdentifier}
				startIcon={
					<MapPinIcon
						className={`${dropDownIconStyles} ${isLoading ? inputDisabledColorStyles : "fill-primary"}`}
						width={dropDownIconSize}
						height={dropDownIconSize}
					/>
				}
			/>

			<DropDownInput
				id={HTMLElementIdentifiers.PractitionerGender}
				label="Practitioner Gender"
				tooltip="Select your preferred gender of the practitioner."
				isRequired={false}
				isDisabled={genderIdentifiers.length <= 1}
				isLoading={isLoading || !genderChoices}
				initialValue={initialGenderIdentifier}
				choices={genderChoices}
				onChoose={onDropDownChoose}
				sideBySide={isMediumWidth}
				linkText={chosenGenderIdentifier !== null && genderIdentifiers.length <= 1 ? "Show all" : undefined}
				linkIcon={<XMarkIcon width={inputLinkIconSize} height={inputLinkIconSize} />}
				isLinkDisabled={false}
				onLinkClick={clearChosenGenderIdentifier}
				startIcon={
					<UserIcon
						className={`${dropDownIconStyles} ${isLoading ? inputDisabledColorStyles : "fill-primary"}`}
						width={dropDownIconSize}
						height={dropDownIconSize}
					/>
				}
			/>

			<DropDownInput
				id={HTMLElementIdentifiers.PractitionerName}
				label="Preferred Practitioner"
				tooltip="Select your preferred practitioner."
				isRequired={false}
				isDisabled={practitionerIdentifiers.length <= 1}
				isLoading={isLoading || !practitionerChoices}
				initialValue={initialPractitionerIdentifier}
				choices={practitionerChoices}
				onChoose={onDropDownChoose}
				sideBySide={isMediumWidth}
				linkText={
					chosenPractitionerIdentifier !== null && practitionerIdentifiers.length <= 1
						? "Show all"
						: undefined
				}
				linkIcon={<XMarkIcon width={inputLinkIconSize} height={inputLinkIconSize} />}
				isLinkDisabled={false}
				onLinkClick={clearChosenPractitionerIdentifier}
				startIcon={
					<UserIcon
						className={`${dropDownIconStyles} ${isLoading ? inputDisabledColorStyles : "fill-primary"}`}
						width={dropDownIconSize}
						height={dropDownIconSize}
					/>
				}
			/>

			{!hideDatePicker &&
				(core && availableFilters ? (
					<FilteredDatePicker
						id={HTMLElementIdentifiers.Date}
						core={core}
						availableFilters={availableFilters}
						sideBySide={isMediumWidth}
					/>
				) : (
					<LoadingSpinner size={loadingSpinnerIconSize} className="mt-4" />
				))}
		</Form>
	)
}

export default FilterForm
