import { longerColorTransitionStyles } from "~/config/transitions"

/**
 * The styles for drop-down input icons.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const dropDownIconStyles = `${longerColorTransitionStyles} ms-2 flex-shrink-0`

/**
 * The width/height of drop-down input icons.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const dropDownIconSize = 28
