import { AtSymbolIcon } from "@heroicons/react/24/solid"

import type { InputProps } from "~/components/controls/inputs/input"
import Input from "~/components/controls/inputs/input"
import { longerColorTransitionStyles } from "~/config/transitions"
import { inputDisabledColorStyles, inputIconSize } from "~/constants/components/input"
import type { ComponentProps } from "~/types/components/props"

/**
 * A pre-styled standard HTML email address input.
 * @example <EmailAddressInput id="nameInput" />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const EmailAddressInput = ({
	label = "Email Address",

	placeholder = "john@example.com...",
	tooltip = "Please enter an email address.",

	startIcon,

	...props
}: ComponentProps<
	HTMLInputElement,
	Omit<InputProps, "type" | "minimumLength" | "regularExpression" | "missingValueWarningMessage">
>): JSX.Element => (
	<Input
		{...props}
		type="email"
		inputMode="email"
		label={label}
		tooltip={tooltip}
		placeholder={placeholder}
		minimumLength={3}
		regularExpression={"^.+@.+\\..+$"}
		missingValueWarningMessage="Enter your email address!"
		startIcon={isLoading =>
			/* eslint-disable indent */
			typeof startIcon === "function"
				? startIcon(isLoading)
				: (startIcon ?? (
						<AtSymbolIcon
							className={`${longerColorTransitionStyles} ${isLoading ? inputDisabledColorStyles : "fill-primary"}`}
							width={inputIconSize}
							height={inputIconSize}
						/>
					))
		}
	/>
)

export default EmailAddressInput
