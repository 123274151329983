import type { ButtonProps } from "~/components/controls/button"
import Button from "~/components/controls/button"
import type { ComponentProps } from "~/types/components/props"

/**
 * A wrapper around a button for positioning it at the bottom of the screen.
 * @example <StickyButton />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const StickyButton = ({ ...props }: ComponentProps<HTMLButtonElement, ButtonProps>): JSX.Element => (
	<div className="sticky bottom-0 p-4">
		<Button {...props} className={`!text-md shadow ${props.className ?? ""}`.trimEnd()} />
	</div>
)

export default StickyButton
