/**
 * Creates an array from a range of numbers.
 * @param {number} from The start of the range.
 * @param {number} to The end of the range.
 * @returns {number[]} The range of numbers.
 * @example const myRange = range(1, 5) // [1, 2, 3, 4, 5]
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.5.0
 */
export const fromRange = (from: number, to: number): number[] =>
	Array.from({ length: to - from + 1 }, (_, index) => index + from)
