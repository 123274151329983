import {
	BuildingOffice2Icon,
	BuildingOfficeIcon,
	CalendarDaysIcon,
	ClockIcon,
	CreditCardIcon,
	MapPinIcon,
	UserIcon
} from "@heroicons/react/24/outline"

import type { InflatedAppointment, InflatedAppointmentTime } from "~/classes/appointment"
import { appointmentCardProfilePictureSize } from "~/components/appointments/card"
import Image from "~/components/controls/image"
import ErrorMessage from "~/components/errorMessage"
import Divider from "~/components/standard/layout/divider"
import Paragraph from "~/components/standard/text/paragraph"
import { generateInitialsProfilePicture } from "~/helpers/initialsProfilePicture"
import { useAuthSessionSelector } from "~/hooks/useAuthSession"
import { useMediaQueries } from "~/hooks/useMediaQueries"
import { isPartialUser } from "~/state/slices/authSession"
import type { ComponentProps, ComponentWithChildrenProps } from "~/types/components/props"

// https://vitejs.dev/guide/env-and-mode#env-variables-and-modes
const googleMapsKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY
if (!googleMapsKey) throw new Error("The Google Maps JavaScript API key is missing!")

interface AppointmentDetailsProps {
	appointment: InflatedAppointment
	time?: InflatedAppointmentTime

	show?: {
		practitioner?: boolean
		types?: boolean
		datesTimes?: boolean
		address?: boolean
		cost?: boolean
		map?: boolean
	}
}

/**
 * Renders the details of an appointment.
 * @example <AppointmentDetails appointment={...} />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.1.0
 */
const AppointmentDetails = ({
	appointment,
	time,

	show = {
		practitioner: true,
		types: false,
		datesTimes: true,
		address: true,
		cost: true,
		map: false // Client doesn't want the Google Map any more
	},

	...props
}: ComponentProps<HTMLDivElement, AppointmentDetailsProps>): JSX.Element => {
	const { isLandscape } = useMediaQueries()

	// Do not continue if the user is not fully signed in
	const { user } = useAuthSessionSelector()
	if (!user || isPartialUser(user)) return <ErrorMessage title="User" content="User is not signed in!" />

	// Do not continue if the appointment is invalid
	if (!appointment.isValid())
		return <ErrorMessage title="Appointment" content="Appointment has bad properties/malformed data!" />

	return (
		<div
			{...props}
			className={`flex ${isLandscape ? "flex-row justify-between gap-x-2" : "flex-col"} ${props.className ?? ""}`.trimEnd()}>
			<div className="flex flex-grow flex-col">
				<Details appointment={appointment} time={time} show={show}>
					{show.map === true && !isLandscape && (
						<>
							<Divider />
							<Map appointment={appointment} className="min-h-96" />
						</>
					)}
				</Details>
			</div>

			{show.map === true && isLandscape && (
				<div className="flex flex-grow flex-col">
					<Map appointment={appointment} className="min-h-96" />
				</div>
			)}
		</div>
	)
}

const Details = ({
	appointment,
	time,

	show = {
		types: true,
		datesTimes: true,
		address: true,
		cost: true,
		map: true
	},

	children,
	...props
}: ComponentWithChildrenProps<HTMLDivElement, AppointmentDetailsProps>): JSX.Element => {
	// Generate a fallback URL for the profile picture
	const initialsImageUrl = generateInitialsProfilePicture(appointment.practitioner.name)

	return (
		<div {...props} className={`flex flex-col gap-y-4 ${props.className ?? ""}`.trimEnd()}>
			{show.practitioner === true && (
				<>
					<div className="flex flex-row items-center gap-x-5 rounded-lg bg-white px-4 py-3 shadow">
						{appointment.practitioner.image !== null ? (
							<>
								<Image
									className="aspect-square rounded-full object-cover"
									sourceUrl={appointment.practitioner.image}
									screenReaderDescription={`Picture of ${appointment.practitioner.name}'s face.`}
									width={appointmentCardProfilePictureSize}
									height={appointmentCardProfilePictureSize}
								/>
								<Paragraph className="text-lg">{appointment.practitioner.name}</Paragraph>
							</>
						) : initialsImageUrl !== null ? (
							<>
								<Image
									className="rounded-full"
									sourceUrl={initialsImageUrl}
									screenReaderDescription={`${appointment.practitioner.name}'s initials.`}
									width={appointmentCardProfilePictureSize}
									height={appointmentCardProfilePictureSize}
								/>
								<Paragraph className="text-lg">{appointment.practitioner.name}</Paragraph>
							</>
						) : (
							<div className="flex flex-row items-center gap-x-4 px-1">
								<UserIcon className="text-text" width={24} height={24} />
								<Paragraph>{appointment.practitioner.name}</Paragraph>
							</div>
						)}
					</div>

					{/* <Divider /> */}
				</>
			)}

			{show.types === true && (
				<>
					<div className="flex flex-col gap-y-4 rounded-lg bg-white px-4 py-3 shadow">
						{/* Showing the therapy type is "surplus information"...
						<div className="flex flex-row items-center gap-x-4">
							<BuildingOfficeIcon className="text-text" width={24} height={24} />
							<Paragraph>{appointment.therapy.name}</Paragraph>
						</div>
						*/}
						<div className="flex flex-row items-center gap-x-4">
							<BuildingOfficeIcon className="text-text" width={24} height={24} />
							<Paragraph>{appointment.type.name}</Paragraph>
						</div>
					</div>

					{/* <Divider /> */}
				</>
			)}

			{show.datesTimes === true && (
				<>
					<div className="flex flex-col gap-y-4 rounded-lg bg-white px-4 py-3 shadow">
						<div className="flex flex-row items-center gap-x-4">
							<CalendarDaysIcon className="text-text" width={24} height={24} />
							<Paragraph className="flex flex-row gap-x-1">
								{time?.getHumanStartDate() ?? appointment.getHumanStartDate()}
							</Paragraph>
						</div>
						<div className="flex flex-row items-center gap-x-4">
							<ClockIcon className="text-text" width={24} height={24} />
							<Paragraph>
								{time?.getHumanStartTime() ?? appointment.getHumanStartTime()} -{" "}
								{time?.getHumanFinishTime() ?? appointment.getHumanFinishTime()} (
								{time?.getDuration() ?? appointment.getDuration()} minutes)
							</Paragraph>
						</div>
					</div>

					{/* <Divider /> */}
				</>
			)}

			{show.address === true && (
				<>
					<div className="flex flex-col gap-y-4 rounded-lg bg-white px-4 py-3 shadow">
						{appointment.location.name && (
							<div className="flex flex-row gap-x-4">
								<BuildingOffice2Icon className="text-text" width={24} height={24} />
								<div className="flex flex-col">
									<Paragraph className="flex flex-row gap-x-1">{appointment.location.name}</Paragraph>
								</div>
							</div>
						)}
						<div className="flex flex-row gap-x-4">
							<MapPinIcon className="text-text" width={24} height={24} />
							<div className="flex flex-col">
								{appointment.location.addr_1 && <Paragraph>{appointment.location.addr_1},</Paragraph>}
								{appointment.location.addr_2 !== null && (
									<Paragraph>{appointment.location.addr_2},</Paragraph>
								)}
								{appointment.location.city && <Paragraph>{appointment.location.city},</Paragraph>}
								{appointment.location.state && <Paragraph>{appointment.location.state},</Paragraph>}
								{appointment.location.country && <Paragraph>{appointment.location.country},</Paragraph>}
								{appointment.location.post_code && (
									<Paragraph>{appointment.location.post_code}</Paragraph>
								)}
							</div>
						</div>
					</div>

					{/* <Divider /> */}
				</>
			)}

			{show.cost === true && (
				<>
					<div className="flex flex-col gap-y-4">
						<div className="flex flex-row gap-x-4 rounded-lg bg-white px-4 py-3 shadow">
							<CreditCardIcon className="text-text" width={24} height={24} />
							<Paragraph>{appointment.hasCost() ? `£${appointment.cost.toString()}` : "Free"}</Paragraph>
						</div>
					</div>

					{/* <Divider /> */}
				</>
			)}

			{children}
		</div>
	)
}

const Map = ({
	appointment,

	...props
}: ComponentProps<
	HTMLDivElement,
	{
		appointment: InflatedAppointment
	}
>): JSX.Element => {
	// Split up the coordinates
	const [latitude, longitude] = appointment.location.latlong

	return (
		<div {...props} className={`flex flex-row gap-x-4 ${props.className ?? ""}`.trimEnd()}>
			<div className="flex flex-grow bg-white p-2 font-mono">
				Google Map embed here...
				<br />
				{latitude}, {longitude}
			</div>
		</div>
	)
}

export default AppointmentDetails
