import { createSlice, type PayloadAction } from "@reduxjs/toolkit"

import type { User } from "~/api/osteo-physio/types/models/user/user"
import { getPersistentJSON, getPersistentNumber, PersistentKeys } from "~/helpers/localStorage"

/**
 * Structure of the OAuth 2.0 token granted after successfully logging in.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export interface Token {
	access: string
	refresh: string
	expiresAt: string // ISO 8601

	grantedAt: string // ISO 8601 - Set by us, not returned by API!
}

/**
 * A partial user returned by the API after successful authentication.
 * @example { id: 1, fullName: "John Doe" }
 * @see https://dev.orthoandphysio.com/users
 * @since 2.0.0
 */
export interface PartialUser {
	id: number
	fullName: string
}

/**
 * Structure of the logged in session/authentication slice.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export interface SliceStructure {
	id?: number
	user?: PartialUser | User
	token?: Token
}

/**
 * Redux slice for the logged in session/authentication data.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const slice = createSlice({
	name: "authenticationSession",
	initialState: {
		id: getPersistentNumber(PersistentKeys.SessionID) ?? undefined,
		user: getPersistentJSON<User>(PersistentKeys.SessionUser) ?? undefined,
		token: getPersistentJSON<Token>(PersistentKeys.SessionToken) ?? undefined
	} as SliceStructure,
	reducers: {
		update: (state, action: PayloadAction<SliceStructure>): SliceStructure => ({
			...state,
			...action.payload
		})
	}
})

/**
 * Actions for updating the Redux store.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const { update } = slice.actions

export default slice.reducer

/**
 * Ensures the user is a partial user.
 * @param {PartialUser | User} user The user to check.
 * @returns Whether the user is a partial user.
 * @example const isPartial = isPartialUser({ id: 1, fullName: "John Doe" }) // true
 * @see https://dev.orthoandphysio.com/users
 * @since 2.0.0
 */
export const isPartialUser = (user: PartialUser | User): user is PartialUser => "fullName" in user
