import type { SerializedError } from "@reduxjs/toolkit"
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { useCallback, useEffect, useMemo } from "react"

import { useFetchAppointmentFiltersQuery, useFetchCoreQuery } from "~/api/osteo-physio/client"
import type { FilterableTypes } from "~/helpers/filterableTypes"
import { discardIrrelevantFilterableTypes } from "~/helpers/filterableTypes"
import { AnyIdentifier } from "~/helpers/object"
import { useTherapyTypes } from "~/hooks/appointments/useTherapyTypes"
import { useChosenAppointmentTypeIdentifier, type IdentifierCallback } from "~/hooks/useAppointmentFilters"
import { slice } from "~/state/slices/appointmentFilters"
import { useReduxDispatch } from "~/state/store"
import type { EmptyCallback } from "~/types/components/callbacks"

/**
 * React hook for fetching & initialising the appointment types.
 * @returns {object} The appointment types, any query errors & a loading state.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.5.0
 */
export const useAppointmentTypes = (): {
	chosenAppointmentTypeIdentifier: number | null

	appointmentTypes: FilterableTypes | null
	queryError: FetchBaseQueryError | SerializedError | null
	isLoading: boolean

	setChosenAppointmentTypeIdentifier: IdentifierCallback
	clearChosenAppointmentTypeIdentifier: EmptyCallback
} => {
	// Use the therapy types, hopefully these have been initialised already!
	const { chosenTherapyTypeIdentifier } = useTherapyTypes()

	// Use relevant filters from Redux store
	const dispatch = useReduxDispatch()
	const chosenAppointmentTypeIdentifier = useChosenAppointmentTypeIdentifier()

	// Fetch core data & available appointment filters
	const { data: core, error: coreError } = useFetchCoreQuery({}) // This is going to be cached from the therapy types hook!
	const { currentData: availableFilters, error: availableFiltersError } = useFetchAppointmentFiltersQuery(
		{
			therapyId: chosenTherapyTypeIdentifier ?? undefined
		},
		{
			//refetchOnMountOrArgChange: true // Refetch when the therapy changes
		}
	)

	// Disregard any irrelevant appointment types
	const { appointmentTypes } = useMemo(
		() => discardIrrelevantFilterableTypes(core, availableFilters),
		[core, availableFilters]
	)

	// Updates the chosen appointment type identifier in the Redux store...
	const setChosenAppointmentTypeIdentifier = useCallback<IdentifierCallback>(
		identifier => {
			if (isNaN(identifier)) {
				console.error("The appointment type identifier must be a valid number")
				return
			}

			// This resets all other filters except the therapy type as they may be different for the new appointment type!
			dispatch(
				slice.actions.update({
					appointmentTypeIdentifier: identifier !== AnyIdentifier ? identifier : null,

					locationIdentifier: null,
					practitionerIdentifier: null,
					genderIdentifier: null,

					date: null
				})
			)
		},
		[dispatch]
	)

	// Wipes the chosen appointment type identifier from the Redux store...
	const clearChosenAppointmentTypeIdentifier = useCallback<EmptyCallback>(() => {
		dispatch(
			slice.actions.updateAppointmentTypeIdentifier({
				appointmentTypeIdentifier: null
			})
		)
	}, [dispatch])

	// Tries to set the initial appointment type identifier using sensible values...
	useEffect(() => {
		if (!core || !availableFilters) return // Don't bother if we do not have required data
		if (!appointmentTypes) return // Don't bother if there are no appointment types to choose from
		if (chosenTherapyTypeIdentifier === null) return // Don't bother if we can't filter by therapy type yet
		if (chosenAppointmentTypeIdentifier !== null) return // Don't bother if already set

		// Try initialise using the first appointment type
		const firstAppointmentTypeIdentifier = Array.from(appointmentTypes.keys())[0] ?? null
		if (firstAppointmentTypeIdentifier === null) return

		setChosenAppointmentTypeIdentifier(firstAppointmentTypeIdentifier)
		console.info(`The initial appointment type identifier is ${firstAppointmentTypeIdentifier.toString()}!`)
	}, [
		setChosenAppointmentTypeIdentifier,
		clearChosenAppointmentTypeIdentifier,
		core,
		availableFilters,
		appointmentTypes,
		chosenTherapyTypeIdentifier,
		chosenAppointmentTypeIdentifier
	])

	return {
		chosenAppointmentTypeIdentifier: chosenAppointmentTypeIdentifier ?? null,

		appointmentTypes: appointmentTypes ?? null,
		queryError: coreError ?? availableFiltersError ?? null, // Forward API query errors
		isLoading: !core || !availableFilters || !appointmentTypes, // Waiting for queries to finish

		setChosenAppointmentTypeIdentifier,
		clearChosenAppointmentTypeIdentifier
	}
}
