/**
 * Partial of the navigator.userAgentData object.
 * This is for reading the Sec-CH-UA-Platform request header.
 * @property {boolean} mobile Whether the browser is on a mobile.
 * @property {string} platform The platform the browser is on (e.g., Android, iOS).
 * @property {object[]} brands The brand of the browser (e.g., Google Chrome).
 */
interface UserAgentData {
	mobile: boolean
	platform: string
	brands: {
		brand: string
		version: string
	}[]
}

/**
 * Checks if the device is an Apple product (i.e., iPhone, iPad, etc.).
 * @returns {boolean} Whether the device is an Apple product.
 * @example const isApple = isApple() // true
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.1.0
 */
export const isApple = (): boolean => {
	// Check Sec-CH-UA-Platform request header
	if ("userAgentData" in navigator) {
		const userAgentData = navigator.userAgentData as UserAgentData
		const platformName = userAgentData.platform.toLowerCase()

		// https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Sec-CH-UA-Platform#directives
		if (platformName === "ios" || platformName === "macOS") return true
	}

	// Fallback to User-Agent header matching
	return ["iphone", "ipad", "ipod", "mac", "os x"].some(device => navigator.userAgent.toLowerCase().includes(device))
}
