import Paragraph from "~/components/standard/text/paragraph"
import { standardOpacityTransitionStyles } from "~/config/transitions"
import type { ComponentProps } from "~/types/components/props"

/**
 * A warning message to tell the user they're offline.
 * This uses the same red as other warning messages (e.g., input validation).
 * @example <OfflineWarning visible={true} />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.7.0
 */
const OfflineWarning = ({
	visible,
	...props
}: ComponentProps<
	HTMLDivElement,
	{
		visible: boolean
	}
>): JSX.Element => (
	<div
		{...props}
		className={`${standardOpacityTransitionStyles} flex flex-col ${visible ? "opacity-100" : "opacity-0"} ${props.className ?? ""}`.trimEnd()}>
		<Paragraph className="text-sm font-bold text-red-600">
			You are offline. Please check your Wi-Fi or data connection to continue.
		</Paragraph>
	</div>
)

export default OfflineWarning
