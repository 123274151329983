import type { ComponentWithChildrenProps } from "~/types/components/props"

const classes = "flex flex-col h-dvh w-dvw overflow-x-hidden"

/**
 * A wrapper for creating full-screen (i.e., 100 vertical width & height) pages.
 * @param {boolean} useMain True to render a <main> element, false to render a <div> element.
 * @example <FullScreen>...</FullScreen>
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const FullScreen = ({
	useMain = true,

	children,
	...props
}: ComponentWithChildrenProps<
	HTMLDivElement,
	{
		useMain?: boolean
	}
>): JSX.Element =>
	useMain ? (
		<main {...props} className={`${classes} ${props.className ?? ""}`.trimEnd()}>
			{children}
		</main>
	) : (
		<div {...props} className={`${classes} ${props.className ?? ""}`.trimEnd()}>
			{children}
		</div>
	)

export default FullScreen
