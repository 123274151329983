import type { MouseEvent } from "react"

/**
 * Themes for the <Button /> component.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export enum ButtonThemes {
	PrimaryOnWhite,
	SecondaryOnWhite,

	WhiteOnPrimary,
	WhiteOnSecondary,

	TextOnWhite,

	PrimaryOnSecondary
}

/**
 * A standard HTML button click callback.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export type OnClickCallback = <Type extends HTMLElement>(event: MouseEvent<Type>) => void
