import type { ComponentWithChildrenProps } from "~/types/components/props"

const StageWrapper = ({ children, ...props }: ComponentWithChildrenProps<HTMLDivElement>): JSX.Element => (
	<section
		{...props}
		className={`flex flex-grow flex-col justify-between gap-y-6 ${props.className ?? ""}`.trimEnd()}>
		{children}
	</section>
)

export default StageWrapper
