import { useCallback, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { isAPIError, isHTTPError } from "~/api/osteo-physio/types/error"
import FilterForm from "~/components/appointments/filters/form"
import AvailableAppointmentsList from "~/components/appointments/list"
import Button from "~/components/controls/button"
import ErrorMessage from "~/components/errorMessage"
import LoadingSpinner, { loadingSpinnerIconSize } from "~/components/loadingSpinner"
import Divider from "~/components/standard/layout/divider"
import Page from "~/components/standard/layout/page"
import Section from "~/components/standard/layout/section"
import { useAppointmentTypes } from "~/hooks/appointments/useAppointmentTypes"
import { useTherapyTypes } from "~/hooks/appointments/useTherapyTypes"
import { useAppointmentFilterDispatch } from "~/hooks/useAppointmentFilters"
import { useAppointmentNavigationState } from "~/hooks/useAppointmentNavigationState"
import { useMediaQueries } from "~/hooks/useMediaQueries"
import { Routes } from "~/router"
import type { OnClickCallback } from "~/types/components/controls/button"
import type { ComponentProps } from "~/types/components/props"
import type { NavigationState } from "~/types/router/navigation"

/**
 * The book & reschedule appointments page.
 * This page handles changing filtering options to find available appointments.
 * This contains the therapy & appointment types context providers for initial load.
 * @example <BookAppointmentPage />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const BookAppointmentPage = ({ ...props }: ComponentProps<HTMLDivElement>): JSX.Element => {
	// Responsiveness
	const { isLargeHeight, isMediumHeight, isLandscape } = useMediaQueries()
	const isTooSmall = useMemo<boolean>(
		() => (isLandscape ? isMediumHeight : isLargeHeight),
		[isLandscape, isMediumHeight, isLargeHeight]
	)

	// Only set when rescheduling an appointment
	const appointment = useAppointmentNavigationState()
	const isRescheduling = useMemo<boolean>(() => appointment !== null, [appointment])

	// Read the initial therapy type choice from the last URL segment, we hope & pray this matches one from the API
	// NOTE: Not even worth it now as the action buttons on home were removed :/
	const { pathname } = useLocation()
	const initialTherapyName = pathname.split("/").slice(-1)[0]?.toLowerCase()

	// Therapy & appointment types from Redux store
	const {
		chosenTherapyTypeIdentifier,
		queryError: therapyTypesError,
		isLoading: isTherapyTypesLoading
	} = useTherapyTypes(initialTherapyName)
	const {
		chosenAppointmentTypeIdentifier,
		queryError: appointmentTypesError,
		isLoading: isAppointmentTypesLoading
	} = useAppointmentTypes()

	// Redirect when the show availability button is clicked...
	const navigate = useNavigate()
	const { clearChosenDate } = useAppointmentFilterDispatch()
	const onShowAvailabilityClick = useCallback<OnClickCallback>(() => {
		// Clear chosen date
		clearChosenDate()

		// Redirect with the reschedule appointment state if set
		if (appointment) {
			navigate(Routes.AvailableAppointments, {
				state: {
					inflatedAppointmentJSON: appointment.toJSON()
				} as NavigationState
			})

			return
		}

		navigate(Routes.AvailableAppointments)
	}, [navigate, clearChosenDate, appointment])

	// Show query errors
	if (therapyTypesError) {
		if (isAPIError(therapyTypesError))
			return <ErrorMessage title="API Error" content={therapyTypesError.data.SystemErrorMessage?.toString()} />

		if (isHTTPError(therapyTypesError))
			return <ErrorMessage title="HTTP Error" content={therapyTypesError.status.toString()} />

		return <ErrorMessage title="Unknown Error" content="Failed to use therapy types!" />
	}
	if (appointmentTypesError) {
		if (isAPIError(appointmentTypesError))
			return (
				<ErrorMessage title="API Error" content={appointmentTypesError.data.SystemErrorMessage?.toString()} />
			)

		if (isHTTPError(appointmentTypesError))
			return <ErrorMessage title="HTTP Error" content={appointmentTypesError.status.toString()} />

		return <ErrorMessage title="Unknown Error" content="Failed to use therapy types!" />
	}

	// Wait before rendering
	if (isTherapyTypesLoading || isAppointmentTypesLoading)
		return <LoadingSpinner size={loadingSpinnerIconSize} className="mt-4" />

	// Ensure we have the required filters
	if (chosenTherapyTypeIdentifier === null)
		return <ErrorMessage title="No Therapy Type" content="Choose a therapy type!" />
	if (chosenAppointmentTypeIdentifier === null)
		return <ErrorMessage title="No Appointment Type" content="Choose an appointment type!" />

	return (
		<Page {...props} className={`gap-y-2 ${props.className ?? ""}`.trimEnd()}>
			<Section
				title={`${isRescheduling ? "Reschedule" : "Book"} Appointment`}
				className={`!flex-grow-0 ${props.className ?? ""}`.trimEnd()}
				innerClassName="py-6 pb-4">
				<FilterForm hideDatePicker={isTooSmall} />
			</Section>

			<div className="px-4">
				<Divider />
			</div>

			{/* Old app functionality, woo! */}
			<div className="p-4">
				{isTooSmall ? (
					<Button label="Show availability" wide={true} onClick={onShowAvailabilityClick} />
				) : (
					<AvailableAppointmentsList
						rescheduleAppointmentId={appointment?.id ?? undefined}
						chosenTherapyTypeIdentifier={chosenTherapyTypeIdentifier}
						chosenAppointmentTypeIdentifier={chosenAppointmentTypeIdentifier}
					/>
				)}
			</div>
		</Page>
	)
}

export default BookAppointmentPage
