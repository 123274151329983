import { CheckIcon, XMarkIcon } from "@heroicons/react/16/solid"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"

import Button from "~/components/controls/button"
import LoadingSpinner from "~/components/loadingSpinner"
import Paragraph from "~/components/standard/text/paragraph"
import { longerOpacityTransitionStyles, longerTransitionStyles } from "~/config/transitions"
import { useFormSelector } from "~/hooks/useForm"
import { useModalDispatch, useModalSelector } from "~/hooks/useModal"
import type { Modal } from "~/state/slices/userInterface"
import type { OnClickCallback } from "~/types/components/controls/button"
import { ButtonThemes } from "~/types/components/controls/button"
import type { ComponentProps } from "~/types/components/props"

/**
 * A popup modal for showing notices, errors, and other important messages to the user.
 * This is a global component that can be shown from anywhere in the app by updating state.
 * There should only be one instance of this component in the app at any given time.
 * @example <PopupModal />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const PopupModal = ({ ...props }: ComponentProps<HTMLDivElement>): JSX.Element => {
	const { hideModal } = useModalDispatch()
	const currentModal = useModalSelector()
	const {
		title: currentTitle,
		content: currentContent,
		formIdentifier: currentFormIdentifier,
		buttons: currentButtons,
		shouldShow
	} = currentModal

	// Form
	const { isLoading } = useFormSelector(currentFormIdentifier ?? undefined)

	// Stores the previous state of the modal, so nothing jumps around when its cleared in Redux
	const [previousModal, setPreviousModal] = useState<Modal>(currentModal)
	const { title: previousTitle, content: previousContent, buttons: previousButtons } = previousModal

	// Reference to the full width/height & absolute container
	const containerReference = useRef<HTMLDivElement>(null)

	// Hide the modal when anywhere outside the modal is pressed...
	const onDismissClick = useCallback<OnClickCallback>(
		event => {
			// Don't run if we're not directly targeting the container, so that we don't get two events when the positive/negative buttons are clicked
			if (event.target !== containerReference.current) return

			hideModal()
		},
		[hideModal]
	)

	// Hide the modal when the negative button is pressed...
	const onNegativeClick = useCallback<OnClickCallback>(() => {
		hideModal()
	}, [hideModal])

	// Submit the form when the positive button is pressed...
	const onPositiveClick = useCallback<OnClickCallback>(() => {
		// Don't continue if we don't have a form to submit
		if (currentFormIdentifier === undefined || currentFormIdentifier === null) {
			hideModal()
			return
		}

		// Find the form
		const formElement = document.getElementById(currentFormIdentifier) as HTMLFormElement | null
		if (!formElement) {
			console.warn(`Could not find form '${currentFormIdentifier}' to submit.`)
			hideModal()
			return
		}

		// Fire the event, hiding the modal is up to them now!
		formElement.requestSubmit()
	}, [hideModal, currentFormIdentifier])

	// Updates the previous state of the modal
	useEffect(() => {
		// Don't update if the modal hasn't changed
		if (currentModal === previousModal) return
		if (currentModal.title === previousModal.title && currentModal.content === previousModal.content) return

		// Delay the update if this is closing...
		if (
			currentModal.title === null &&
			currentModal.content === null &&
			Object.entries(currentModal.buttons).every(([, value]) => value === null)
		) {
			setTimeout(() => {
				setPreviousModal(currentModal)
			}, 5000)

			return
		}

		// Otherwise, update instantly
		setPreviousModal(currentModal)
	}, [currentModal, previousModal])

	// Expand state for convenience
	const title = useMemo(() => currentTitle ?? previousTitle, [currentTitle, previousTitle])
	const content = useMemo(() => currentContent ?? previousContent, [currentContent, previousContent])
	const buttons = useMemo(
		() => ({
			dismiss: currentButtons.dismiss ?? previousButtons.dismiss,
			negative: currentButtons.negative ?? previousButtons.negative,
			positive: currentButtons.positive ?? previousButtons.positive
		}),
		[currentButtons, previousButtons]
	)

	return (
		<div
			{...props}
			ref={containerReference}
			className={`${longerTransitionStyles} ${shouldShow ? "pointer-events-auto bg-opacity-20" : "pointer-events-none bg-opacity-0"} absolute left-0 top-0 z-50 flex h-full w-full flex-col justify-center bg-black p-8 ${props.className ?? ""}`.trimEnd()}
			onClick={onDismissClick}>
			<div
				className={`${longerOpacityTransitionStyles} ${shouldShow ? "!opacity-100" : "!opacity-0"} flex w-full flex-col gap-y-4 rounded-md border border-controlBorder bg-secondary p-4 shadow`}>
				<div className="flex flex-col gap-y-2">
					{title !== null && <Paragraph className="font-lg font-bold">{title}</Paragraph>}
					{content !== null && (typeof content === "string" ? <Paragraph>{content}</Paragraph> : content())}
				</div>
				<div className="flex flex-row justify-between gap-x-2">
					{/* Dismiss always shows to correctly position the negative & positive buttons */}
					<Button
						label={buttons.dismiss ?? "Dismiss"}
						className="pointer-events-none opacity-0"
						wide={true}
						standardHeight={false}
						theme={ButtonThemes.WhiteOnPrimary}
						onClick={onDismissClick}
					/>
					<div className="flex flex-row justify-between gap-x-2">
						{buttons.positive !== null && (
							<Button
								label={buttons.positive}
								isLoading={isLoading}
								wide={true}
								standardHeight={false}
								theme={ButtonThemes.WhiteOnPrimary}
								onClick={onPositiveClick}
								startIcon={<CheckIcon className="me-1" width={20} height={20} />}
								endIcon={
									isLoading ? (
										<LoadingSpinner
											className="ms-1"
											visible={isLoading}
											inContainer={true}
											size={16}
										/>
									) : undefined
								}
							/>
						)}
						{buttons.negative !== null && (
							<Button
								label={buttons.negative}
								isLoading={isLoading}
								wide={true}
								standardHeight={false}
								theme={ButtonThemes.WhiteOnPrimary}
								onClick={onNegativeClick}
								startIcon={<XMarkIcon className="me-1" width={20} height={20} />}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default PopupModal
