import { UserIcon } from "@heroicons/react/24/solid"
import { useCallback } from "react"

import Form from "~/components/controls/forms/form"
import TextInput from "~/components/controls/inputs/text"
import BackOrContinueButtons from "~/components/onboarding/backOrContinueButtons"
import Paragraphs from "~/components/onboarding/paragraphs"
import StageWrapper from "~/components/onboarding/wrapper"
import Paragraph from "~/components/standard/text/paragraph"
import { longerColorTransitionStyles } from "~/config/transitions"
import { inputDisabledColorStyles, inputIconSize } from "~/constants/components/input"
import { useFlowContext } from "~/contexts/flow"
import { useFormDispatch } from "~/hooks/useForm"
import { useOnboardingDispatch, useOnboardingSelector } from "~/hooks/useOnboarding"
import type { OnFormSubmitCallback } from "~/types/components/controls/form"
import { OnboardingStage } from "~/types/components/pages/onboarding"
import type { ComponentProps } from "~/types/components/props"

enum HTMLElementIdentifiers {
	NameForm = "registerName",
	FirstName = "firstName",
	LastName = "lastName"
}

/**
 * The second stage of the onboarding process.
 * This stage collects the user's first & last name for registering a new account.
 * This should be wrapped in a <StageFlow /> component!
 * @example <NameStage />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const NameStage = ({ ...props }: ComponentProps<HTMLDivElement>): JSX.Element => {
	const { transfer } = useFlowContext()

	const { showWarning } = useFormDispatch(HTMLElementIdentifiers.NameForm)

	const { setName, clearName } = useOnboardingDispatch()
	const { name } = useOnboardingSelector()

	const onSubmitted = useCallback<OnFormSubmitCallback>(
		(values): void => {
			const firstName = values.get(HTMLElementIdentifiers.FirstName) as string | null
			const lastName = values.get(HTMLElementIdentifiers.LastName) as string | null

			// Shouldn't happen, but check just in case to please TypeScript
			if (firstName === null) {
				console.warn("No first name was entered, even though the input is required?!")
				showWarning(HTMLElementIdentifiers.FirstName, "Enter your first name!")
				clearName()
				return
			}

			// Shouldn't happen, but check just in case to please TypeScript
			if (lastName === null) {
				console.warn("No last name was entered, even though the input is required?!")
				showWarning(HTMLElementIdentifiers.LastName, "Enter your last name!")
				clearName()
				return
			}

			setName({
				first: firstName,
				last: lastName
			})
			transfer(OnboardingStage.DateOfBirth)
		},
		[showWarning, clearName, setName, transfer]
	)

	return (
		<StageWrapper {...props}>
			<Paragraphs>
				<Paragraph>Please enter your name below to begin creating your account.</Paragraph>
			</Paragraphs>
			<Form id={HTMLElementIdentifiers.NameForm} onSubmit={onSubmitted}>
				<TextInput
					id={HTMLElementIdentifiers.FirstName}
					label="First Name"
					placeholder="John..."
					initialValue={name?.first}
					missingValueWarningMessage="Enter your first name!"
					isFocused={true}
					startIcon={isLoading => (
						<UserIcon
							className={`${longerColorTransitionStyles} ${isLoading ? inputDisabledColorStyles : "fill-primary"}`}
							width={inputIconSize}
							height={inputIconSize}
						/>
					)}
				/>
				<TextInput
					id={HTMLElementIdentifiers.LastName}
					label="Last Name"
					placeholder="Doe..."
					initialValue={name?.last}
					missingValueWarningMessage="Enter your last name!"
					startIcon={isLoading => (
						<UserIcon
							className={`${longerColorTransitionStyles} ${isLoading ? inputDisabledColorStyles : "fill-primary"}`}
							width={inputIconSize}
							height={inputIconSize}
						/>
					)}
				/>
				<BackOrContinueButtons previousStage={OnboardingStage.PhoneNumber} />
			</Form>
		</StageWrapper>
	)
}

export default NameStage
