/**
 * Generates an appropriate greeting based on the time of day.
 * @param hour The hour of the day.
 * @returns The greeting.
 * @example getGreeting(13) // "Good afternoon"
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const generateGreeting = (hour?: number): string => {
	hour ??= new Date().getHours()

	if (hour >= 0 && hour < 12) return "Good morning"
	else if (hour >= 12 && hour < 18) return "Good afternoon"
	else if (hour >= 18 && hour < 24) return "Good evening"

	return "Good evening"
}
