/**
 * Ensures the HTML element is a generic input.
 * @param {HTMLElement} element The HTML element to check.
 * @returns Whether the HTML element is a generic input.
 * @example if (isHTMLInputElement(document.getElementById("myInput")) {...
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const isHTMLInputElement = (element: HTMLElement): element is HTMLInputElement =>
	element.tagName.toLowerCase() === "input"

/**
 * Ensures the HTML element is a number input.
 * @param {HTMLElement} element The HTML element to check.
 * @returns Whether the HTML element is a number input.
 * @example if (isHTMLNumberInputElement(document.getElementById("myNumberInput")) {...
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.5.0
 */
export const isHTMLNumberInputElement = (element: HTMLElement): element is HTMLInputElement & { type: "number" } =>
	isHTMLInputElement(element) && element.type === "number"

/**
 * Ensures the HTML element is a checkbox input.
 * @param {HTMLElement} element The HTML element to check.
 * @returns Whether the HTML element is a checkbox input.
 * @example if (isHTMLCheckBoxInputElement(document.getElementById("myCheckBoxInput")) {...
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.5.0
 */
export const isHTMLCheckBoxInputElement = (element: HTMLElement): element is HTMLInputElement & { type: "checkbox" } =>
	isHTMLInputElement(element) && element.type === "checkbox"

/**
 * Ensures the HTML element is a drop-down.
 * @param {HTMLElement} element The HTML element to check.
 * @returns Whether the HTML element is a drop-down.
 * @example if (isHTMLSelectElement(document.getElementById("myDropDown")) {...
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const isHTMLSelectElement = (element: HTMLElement): element is HTMLSelectElement =>
	element.tagName.toLowerCase() === "select"
