import Paragraph from "~/components/standard/text/paragraph"
import type { ComponentWithChildrenProps } from "~/types/components/props"

/**
 * A pre-styled standard HTML heading.
 * Only supports level 1 & 2 for now, anything else will fallback to a paragraph.
 * @param {number} level The level of heading.
 * @example <Heading>Hello World</Heading>
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.3.0
 */
const Heading = ({
	level = 1,

	children,
	...props
}: ComponentWithChildrenProps<
	HTMLHeadingElement,
	{
		level?: number
	}
>): JSX.Element => {
	switch (level) {
		case 1:
			return (
				<h1 {...props} className={`text-3xl ${props.className ?? ""}`.trimEnd()}>
					{children}
				</h1>
			)

		case 2:
			return (
				<h2 {...props} className={`text-2xl ${props.className ?? ""}`.trimEnd()}>
					{children}
				</h2>
			)

		default:
			return (
				<Paragraph {...props} className={`text-xl font-bold ${props.className ?? ""}`.trimEnd()}>
					{children}
				</Paragraph>
			)
	}
}

export default Heading
