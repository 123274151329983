import type { AppointmentFilters } from "~/api/osteo-physio/types/endpoints/appointments/filters"
import type { Core } from "~/api/osteo-physio/types/endpoints/data/core"
import { orNull } from "~/helpers/null"

/**
 * Checks if a day is available based on the available filters.
 * @param {Date} date The date to check.
 * @param {Core} core The core data.
 * @param {AppointmentFilters} filters The available filters.
 * @returns {boolean} Whether the day is available.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const isDayAvailable = (date: Date, core: Core, filters: AppointmentFilters): boolean => {
	// Convert day number to day of week name
	const nameOfDay = date.toLocaleDateString("en-GB", { weekday: "long" }).toLowerCase()

	// Get the identifier for this day of the week
	const weekdayId = core.appointment_options.weekdays.find(
		({ name }) => orNull(name)?.trim().toLowerCase() === nameOfDay
	)?.id
	if (weekdayId === undefined) return true // Assume the day is available if we don't know about this day of the week

	return filters.weekday_id.includes(weekdayId)
}
