import { createSlice, type PayloadAction } from "@reduxjs/toolkit"

import { deepObjectMerge } from "~/helpers/merge"

/**
 * Structure of the data regarding a specific input within a form.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export interface Input {
	warningMessage: string | null
}

/**
 * Structure of the data regarding a specific form.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export interface Form {
	isLoading?: boolean
	inputs?: Record<string, Input> | null // Input ID -> Input Data
}

/**
 * An input modal's callback function, which provides a boolean (positive/negative) result.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.1.0
 */
export type ModalCallback = (result: boolean) => void

/**
 * The modal's data.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export interface Modal {
	title: string | null
	content: string | (() => JSX.Element) | null
	formIdentifier?: string | null

	buttons: {
		dismiss: string | null // Cancel, Dismiss, etc.
		negative: string | null // No, Deny, etc.
		positive: string | null // Yes, Okay, Accept, etc.
	}
}

/**
 * Structure of the user interface slice.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export interface SliceStructure {
	forms: Record<string, Form> // Form ID -> Form Data

	modal: Modal
}

/**
 * Redux slice for the user interface components.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const slice = createSlice({
	name: "userInterface",
	initialState: {
		views: {},
		forms: {},
		modal: {
			title: null,
			content: null,

			buttons: {
				dismiss: null,
				negative: null,
				positive: null
			}
		}
	} as SliceStructure,
	reducers: {
		// Reducer for updating just the form state
		updateForm: (state, action: PayloadAction<Pick<SliceStructure, "forms">["forms"]>): SliceStructure => ({
			...state,
			...action.payload,
			forms: deepObjectMerge(state.forms, action.payload)
		}),

		// Reducer for updating just the modal state
		updateModal: (state, action: PayloadAction<Pick<SliceStructure, "modal">["modal"]>): SliceStructure => ({
			...state,
			...action.payload,
			modal: { ...state.modal, ...action.payload }
		})
	}
})

/**
 * Actions for updating the Redux store.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const { updateForm, updateModal } = slice.actions

export default slice.reducer
