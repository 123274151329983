import type { FormEvent } from "react"
import { useCallback } from "react"

import Heading from "~/components/standard/text/heading"
import { FormContextProvider } from "~/contexts/form"
import { toNumber } from "~/helpers/convert"
import { isHTMLCheckBoxInputElement, isHTMLNumberInputElement, isHTMLSelectElement } from "~/helpers/input"
import { orNull } from "~/helpers/null"
import { useFormDispatch } from "~/hooks/useForm"
import type { FormInputTypes, FormInputValues, OnFormSubmitCallback } from "~/types/components/controls/form"
import type { ComponentWithChildrenProps } from "~/types/components/props"

export interface FormProps {
	id: string
	heading?: string
	onSubmit?: OnFormSubmitCallback
}

/**
 * A pre-styled standard HTML form.
 * @param {number} id The unique identifier of the form.
 * @param {string | undefined} heading The heading to display above the form.
 * @param {OnFormSubmitCallback | undefined} onSubmit The callback to run when the form is submitted, with all the input values.
 * @example <Form id="welcomeForm" heading="Hello World">...</Form>
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const Form = ({
	id,
	heading,
	onSubmit,

	children,
	...props
}: ComponentWithChildrenProps<
	HTMLFormElement,
	{
		id: string
		heading?: string
		onSubmit?: OnFormSubmitCallback
	}
>): JSX.Element => {
	// Required to clear all warnings before submitting the form
	const { clearWarnings } = useFormDispatch(id)

	// We override the submit event to retrieve & validate the values of all the inputs & pass them in the event handler
	const onInternalSubmit = useCallback(
		(event: FormEvent<HTMLFormElement>): void => {
			// Do not perform the default form submission request
			event.preventDefault()

			// Hide all warnings before validating
			clearWarnings()

			// Get the visible control elements in this form
			const inputElements = Array.from(
				event.currentTarget
					.querySelectorAll("input:not(.invisible), select:not(.invisible), textarea:not(.invisible)")
					.values()
			) as FormInputTypes[]

			// Get the values of all controls within this form
			const inputValues: FormInputValues = new Map(
				// TypeScript's inference fails here, it thinks the return type is '[string, boolean] | [string, number | null] | [string, string | null]'
				inputElements.map<[string, string | boolean | number | null]>(input => {
					if (input.id === "selectedAddress") return [input.id, orNull(input.value)] // Hacky, but this is the only drop-down that uses strings as entry keys

					// Numbers & drop-downs should be parsed appropriately
					if (isHTMLNumberInputElement(input) || isHTMLSelectElement(input))
						return [input.id, toNumber(input.value) ?? null]

					// Checkboxes use .checked instead of .value
					if (isHTMLCheckBoxInputElement(input)) {
						if (input.id) return [input.id, input.checked]

						// Headless UI switches don't attach the ID to the input, so we have to find the button...
						const button = input.parentElement?.querySelector("button")
						if (button?.id !== undefined) return [button.id, input.checked]
					}

					// Handle text inputs, dates, etc. as strings which are null when empty
					return [input.id, orNull(input.value)]
				})
			)

			// They will set the loading state
			onSubmit?.(inputValues, event)
		},
		[clearWarnings, onSubmit]
	)

	return (
		<FormContextProvider value={{ id }}>
			<form
				{...props}
				id={id}
				className={`flex flex-col gap-y-4 ${props.className ?? ""}`.trimEnd()}
				onSubmit={onInternalSubmit}>
				{heading !== undefined && (
					<Heading level={2} className="text-base font-bold text-formLabel">
						{heading}
					</Heading>
				)}
				<div className="flex flex-col gap-y-4">{children}</div>
			</form>
		</FormContextProvider>
	)
}

export default Form
