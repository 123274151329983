import type { Location } from "~/api/osteo-physio/types/models/data/core/location"
import { orNull } from "~/helpers/null"
import type { Modify } from "~/types/helpers/modify"

/**
 * Tidy up methods should take the exact data returned by the Osteo & Physio API and return a better typed version of it.
 * Property names should be kept identical. No additional properties should be added, and none removed either.
 */

/**
 * Represents the badly typed location returned by the API.
 * @see https://dev.osteoandphysio.co.uk/data
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export type BadlyTypedLocation = Modify<
	Location,
	{
		name?: string | null // Can be an empty string
		addr_1?: string | null // Can be an empty string
		addr_2?: string | null // Can be an empty string
		city?: string | null // Can be an empty string
		state?: string | null // Can be an empty string
		post_code?: string | null // Can be an empty string

		latlong?: string | null // Comma-separated latitude & longitude floats, can be an empty string

		directions?: string | null // Can be an empty string
	}
>

/**
 * Represents a cleaned up location returned by the API.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export type NullableLocation = Modify<
	Location,
	{
		name: string | null
		addr_1: string | null
		city: string | null
		state: string | null
		post_code: string | null

		latlong: number[] | null
	}
>

/**
 * Cleans up the types on a badly typed location returned by the API.
 * @param {BadlyTypedLocation} location The badly typed location.
 * @returns {Location} The cleaned up location.
 * @see https://dev.osteoandphysio.co.uk/data
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const tidyUpLocation = (location: BadlyTypedLocation): NullableLocation => {
	const name = orNull(location.name)?.trim() ?? null
	const addr_1 = orNull(location.addr_1)?.trim() ?? null
	const addr_2 = orNull(location.addr_2)?.trim() ?? null
	const city = orNull(location.city)?.trim() ?? null
	const state = orNull(location.state)?.trim() ?? null
	const post_code = orNull(location.post_code)?.trim() ?? null

	const latlong = orNull(location.latlong)?.trim() ?? null

	const directions = orNull(location.directions)?.trim() ?? null

	return {
		...location,

		name,
		addr_1,
		addr_2,
		city,
		state,
		post_code,

		// This is a custom field
		address: [addr_1, addr_2, city, state, post_code].filter(value => value !== null).join(", "),

		latlong: latlong?.split(",", 2).map(value => parseFloat(value.trim())) ?? null,

		directions: directions
	}
}

/**
 * Cleans up the types on multiple badly typed location returned by the API.
 * This automatically removes any locations without a first address line, city or post code.
 * @param {BadlyTypedLocation} location The badly typed location.
 * @returns {Location} The cleaned up location.
 * @see https://dev.osteoandphysio.co.uk/data
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const tidyUpLocations = (locations: BadlyTypedLocation[]): Location[] =>
	locations
		.map(tidyUpLocation)
		.filter(
			location =>
				location.name !== null &&
				location.addr_1 !== null &&
				location.city !== null &&
				location.post_code !== null
		) as Location[]
