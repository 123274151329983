import { useMediaQuery } from "usehooks-ts"

/**
 * React hook to execute media queries.
 * @returns {object} Results of various media queries.
 * @example const { isLandscape, isMediumHeight } = useMediaQueries()
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.1.0
 */
export const useMediaQueries = (): {
	isLandscape: boolean
	isPortrait: boolean

	isMediumHeight: boolean
	isSmallHeight: boolean
	isLargeHeight: boolean

	isMediumWidth: boolean
} => ({
	isLandscape: useMediaQuery("(orientation: landscape)"),
	isPortrait: useMediaQuery("(orientation: portrait)"),

	isSmallHeight: useMediaQuery("(max-height: 500px)"),
	isMediumHeight: useMediaQuery("(max-height: 750px)"),
	isLargeHeight: useMediaQuery("(max-height: 900px)"),

	isMediumWidth: useMediaQuery("(min-width: 750px)")
})
