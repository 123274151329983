import { useEffect, useState } from "react"

import { standardOpacityTransitionStyles } from "~/config/transitions"
import type { ComponentWithChildrenProps } from "~/types/components/props"

/**
 * A wrapper that fades in its children when mounted.
 * This applies a very short delay so the DOM can update before triggering the transition.
 * @param {number | undefined} delay The time to wait in milliseconds before triggering the transition.
 * @example <FadeIn>...</FadeIn>
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const FadeIn = ({
	delay = 150, // Milliseconds
	opacity,

	children,
	...props
}: ComponentWithChildrenProps<
	HTMLDivElement,
	{
		delay?: number
		opacity?: number
	}
>): JSX.Element => {
	const [isLoaded, setIsLoaded] = useState(false)

	useEffect(() => {
		let timeoutId: NodeJS.Timeout | null = null

		if (!isLoaded) {
			timeoutId = setTimeout(() => {
				setIsLoaded(true)
			}, delay)
		}

		return (): void => {
			if (timeoutId !== null) clearTimeout(timeoutId)
		}
	}, [delay, isLoaded])

	return (
		<div
			{...props}
			className={`${standardOpacityTransitionStyles} ${isLoaded ? "opacity-100" : "opacity-0"} ${props.className ?? ""}`.trimEnd()}
			style={{
				opacity: opacity
			}}>
			{children}
		</div>
	)
}

export default FadeIn
