import { ClockIcon, CreditCardIcon, XMarkIcon } from "@heroicons/react/24/outline"
import { useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"

import { useLazyInvoiceAppointmentQuery } from "~/api/osteo-physio/client"
import type { InflatedAppointment } from "~/classes/appointment"
import AppointmentCard, { appointmentCardButtonIconSize } from "~/components/appointments/card"
import Button from "~/components/controls/button"
import LoadingSpinner from "~/components/loadingSpinner"
import { useModalDispatch } from "~/hooks/useModal"
import { Routes } from "~/router"
import type { OnClickCallback } from "~/types/components/controls/button"
import { ButtonThemes } from "~/types/components/controls/button"
import type { ComponentProps } from "~/types/components/props"
import type { NavigationState } from "~/types/router/navigation"

/**
 * A booked appointment card.
 * Displays information about an appointment, and actions to perform on it.
 * @example <BookedAppointmentCard appointment={...} />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const BookedAppointmentCard = ({
	appointment,
	refreshHome,

	...props
}: ComponentProps<
	HTMLDivElement,
	{
		appointment: InflatedAppointment
		refreshHome: () => void
	}
>): JSX.Element => {
	const navigate = useNavigate()

	const { showNoticeModal } = useModalDispatch()

	// Loading state
	const [isRescheduling, setIsRescheduling] = useState<boolean>(false)
	const [isSendingInvoice, setIsInvoicing] = useState<boolean>(false)

	// API queries
	const [invoiceAppointment] = useLazyInvoiceAppointmentQuery()

	// Views future appointment details...
	const onCardClick = useCallback<OnClickCallback>(() => {
		navigate(Routes.AppointmentDetails, {
			state: {
				inflatedAppointmentJSON: appointment.toJSON()
			} as NavigationState
		})
	}, [navigate, appointment])

	// Reschedules the appointment...
	const onRescheduleClick = useCallback<OnClickCallback>(() => {
		if (!appointment.isFuture()) return // This feature isn't for previous appointments!

		// Rescheduling is handled on another page!
		setIsRescheduling(true)
		navigate(Routes.RescheduleAppointment, {
			state: {
				inflatedAppointmentJSON: appointment.toJSON()
			} as NavigationState
		})
	}, [navigate, appointment])

	// Cancels the appointment...
	const onCancelClick = useCallback<OnClickCallback>(() => {
		if (!appointment.isFuture()) return // This feature isn't for previous appointments!

		// Cancelling is handled on another page!
		setIsRescheduling(true)
		navigate(Routes.CancelAppointment, {
			state: {
				inflatedAppointmentJSON: appointment.toJSON()
			} as NavigationState
		})
	}, [navigate, appointment])

	// Invoices the appointment...
	const onInvoiceClick = useCallback<OnClickCallback>(() => {
		if (!appointment.isPast()) return // This feature isn't for future appointments!

		/* eslint-disable promise/prefer-await-to-then */
		setIsInvoicing(true)
		invoiceAppointment({
			id: appointment.id
		})
			.then(value => {
				refreshHome()

				showNoticeModal("Invoice Sent", "Your invoice has been sent, please check your email.")

				return value
			})
			.catch((error: unknown) => {
				console.warn(
					`Failed to invoice appointment '${appointment.id.toString()}'! (${error?.toString() ?? "Unknown error"})`
				)
			})
			.finally(() => {
				setIsInvoicing(false)
			})
	}, [invoiceAppointment, refreshHome, showNoticeModal, appointment])

	const showInvoiceButton = appointment.isPast() && appointment.hasCost() && appointment.canBeInvoiced

	return (
		<AppointmentCard
			{...props}
			className={!appointment.isFuture() && !showInvoiceButton ? "!pb-1" : undefined}
			appointment={appointment}
			onContentClick={onCardClick}>
			<div className="flex flex-row gap-x-2">
				{appointment.isFuture() && (
					<>
						<Button
							label="Reschedule"
							theme={ButtonThemes.PrimaryOnSecondary}
							onClick={onRescheduleClick}
							isLoading={isRescheduling}
							standardHeight={false}
							startIcon={
								<ClockIcon
									width={appointmentCardButtonIconSize}
									height={appointmentCardButtonIconSize}
								/>
							}
							endIcon={isLoading =>
								isLoading ? <LoadingSpinner className="ms-1" inContainer={true} size={16} /> : null
							}
						/>
						<Button
							label="Cancel"
							theme={ButtonThemes.PrimaryOnSecondary}
							onClick={onCancelClick}
							standardHeight={false}
							startIcon={
								<XMarkIcon
									width={appointmentCardButtonIconSize}
									height={appointmentCardButtonIconSize}
								/>
							}
						/>
					</>
				)}

				{showInvoiceButton && (
					<Button
						label={isSendingInvoice ? "Emailing invoice..." : "Email invoice"}
						theme={ButtonThemes.PrimaryOnSecondary}
						isLoading={isSendingInvoice}
						onClick={onInvoiceClick}
						standardHeight={false}
						startIcon={
							<CreditCardIcon
								width={appointmentCardButtonIconSize}
								height={appointmentCardButtonIconSize}
							/>
						}
						endIcon={isLoading =>
							isLoading ? <LoadingSpinner className="ms-1" inContainer={true} size={16} /> : null
						}
					/>
				)}
			</div>
		</AppointmentCard>
	)
}

export default BookedAppointmentCard
