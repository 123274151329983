import FadeIn from "~/components/wrappers/fadeIn"
import type { ComponentWithChildrenProps } from "~/types/components/props"

/**
 * A pre-styled standard HTML wrapper around <main> for standard (i.e., non full-screen) pages.
 * The children will animate in when mounted.
 * @example <Page>...</Page>
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const Page = ({ children, ...props }: ComponentWithChildrenProps<HTMLDivElement>): JSX.Element => (
	<main {...props} className={`flex flex-grow ${props.className ?? ""}`.trimEnd()}>
		<FadeIn {...props} className={`flex max-w-[100dvw] flex-grow flex-col ${props.className ?? ""}`.trimEnd()}>
			{children}
		</FadeIn>
	</main>
)

export default Page
