import { useRouteError } from "react-router-dom"

import ErrorMessage from "~/components/errorMessage"
import FullScreen from "~/components/wrappers/fullScreen"
import type { ComponentProps } from "~/types/components/props"
import { isRouteError } from "~/types/router/error"

/**
 * The route error page.
 * This is a full-screen page. It should not be accompanied by header & footer components.
 * @example <ErrorPage />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const ErrorPage = ({ ...props }: ComponentProps<HTMLDivElement>): JSX.Element => {
	const error = useRouteError()

	return (
		<FullScreen {...props} className={`justify-center ${props.className ?? ""}`.trimEnd()}>
			{isRouteError(error) ? (
				<ErrorMessage title={`${error.status.toString()} ${error.statusText}`} content={error.data} />
			) : (
				<ErrorMessage />
			)}
		</FullScreen>
	)
}

export default ErrorPage
