/* eslint-disable indent */

/**
 * Converts a map to an object.
 * @type {ValueType} The type of the values in the map.
 * @param {Map<number | string, ValueType>} map The map to convert.
 * @param {(key: number, value: ValueType) => boolean} predicate The function to filter the map by.
 * @returns {Record<string, ValueType>} The object.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.5.0
 */
export const toObject = <ValueType>(
	map: Map<number, ValueType>,
	predicate?: (key: number, value: ValueType) => boolean
): Record<string, ValueType> =>
	Object.fromEntries(Array.from(map.entries()).filter(pair => (predicate ? predicate(...pair) : true)))

/**
 * Converts a map to an object by passing each value through a transform function.
 * @type {ValueType} The type of the values in the map.
 * @type {TransformType} The type of the transformed values in the object.
 * @param {Map<number | string, ValueType>} map The map to convert.
 * @param {(value: ValueType) => TransformType} transform The function to transform the original value into a new value.
 * @returns {Record<string, ValueType>} The object with the transformed values.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.5.0
 */
export const toTransformedObject = <ValueType, TransformType>(
	map: Map<number, ValueType>,
	transform: (value: ValueType) => TransformType
): Record<string, TransformType> =>
	Object.fromEntries(Array.from(map.entries()).map(([key, value]) => [key.toString(), transform(value)]))

/**
 * The key/identifier for the 'Any' option.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.5.0
 */
export const AnyIdentifier = 0

/**
 * Prepends the given object with an option.
 * @param {Record<number, string>} object The object to prepend.
 * @returns {Record<number, string>} The prepended object.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const prependToObject = (
	object: Record<number, string>,
	key = AnyIdentifier,
	value = "Any"
): Record<number, string> => ({
	[key]: value,
	...object
})
