import type { BookedAppointment } from "~/api/osteo-physio/types/endpoints/appointments/booked"
import { orNull } from "~/helpers/null"
import type { Modify } from "~/types/helpers/modify"

/**
 * Tidy up methods should take the exact data returned by the Osteo & Physio API and return a better typed version of it.
 * Property names should be kept identical. No additional properties should be added, and none removed either.
 */

/**
 * Represents a badly typed booked appointment returned by the API.
 * @see https://dev.osteoandphysio.co.uk/appointments/reschedule
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.1.0
 */
export type BadlyTypedBookedAppointment = Modify<
	BookedAppointment,
	{
		// Why are these numbers returned as strings?!
		therapy_id?: string | number | null
		earlier_cancellation_requested?: string | number | boolean | null // Only for future appointments!
	}
>

/**
 * Cleans up the types on a badly typed booked appointment returned by the API.
 * @param {BadlyTypedBookedAppointment} appointment The badly typed booked appointment.
 * @returns {BookedAppointment} The cleaned up booked appointment.
 * @see https://dev.osteoandphysio.co.uk/appointments/reschedule
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.1.0
 */
export const tidyUpBookedAppointment = (appointment: BadlyTypedBookedAppointment): BookedAppointment => {
	const therapy_id =
		(typeof appointment.therapy_id === "string"
			? orNull(appointment.therapy_id)?.trim()
			: appointment.therapy_id) ?? null
	const earlier_cancellation_requested =
		(typeof appointment.earlier_cancellation_requested === "string"
			? orNull(appointment.earlier_cancellation_requested)?.trim()
			: appointment.earlier_cancellation_requested) ?? null

	return {
		...appointment,

		therapy_id: typeof therapy_id === "string" ? parseInt(therapy_id, 10) : (therapy_id ?? NaN),
		earlier_cancellation_requested:
			earlier_cancellation_requested !== null
				? earlier_cancellation_requested === true || earlier_cancellation_requested === "1"
				: undefined
	} as BookedAppointment
}
