import { Color } from "~/colors"

/**
 * Generates a URL to an image containing the initials of a name.
 * @param {string} name The name to generate initials for.
 * @param {boolean} whiteBackground Whether the background should be white. Useful if this will be rendered on secondary coloured backgrounds.
 * @returns {string} the URL to an image of the initials.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const generateInitialsProfilePicture = (name: string, whiteBackground = false): string | null => {
	const [firstName, lastName] = name.split(" ", 2)
	if (firstName === undefined || lastName === undefined) return null

	const firstInitial = firstName.substring(0, 1)
	const lastInitial = lastName.substring(0, 1)
	if (!firstInitial || !lastInitial) return null

	return `https://placehold.co/256x256/${whiteBackground ? "ffffff" : Color.Secondary.substring(1)}/${Color.Primary.substring(1)}.png?text=${firstInitial}${lastInitial}&font=Istok%20Web`
}
