import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import type { BaseResponse, ErrorResponse } from "~/api/cloud-run/types/response"
import type { Usage } from "~/api/cloud-run/types/usage"
import type { EmptyRequest } from "~/api/empty"

// https://vitejs.dev/guide/env-and-mode#env-variables-and-modes
const token = import.meta.env.VITE_GOOGLE_CLOUD_RUN_GET_ADDRESS_ADMIN_AUTH_TOKEN
if (!token) throw new Error("The Google Cloud Run Get Address Admin API authentication token is missing!")

const baseUrl = import.meta.env.VITE_BASE_URL
if (!baseUrl) throw new Error("The base URL is missing!")

const contactEmailAddress = import.meta.env.VITE_CONTACT_EMAIL_ADDRESS
if (!contactEmailAddress) throw new Error("The Osteo & Physio contact email address is missing!")

/**
 * The Redux Toolkit API client for the Google Cloud Run Get Address Admin API.
 * @see https://getaddress.io
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.2.0
 */
export const api = createApi({
	reducerPath: "cloud-run",
	baseQuery: fetchBaseQuery({
		baseUrl: "https://get-address-admin-rieqjz3ihq-nw.a.run.app",
		timeout: 5000, // 5 seconds
		prepareHeaders: headers => {
			// Authentication
			headers.set("Authorization", `Token ${token}`)

			// Identification
			headers.set(
				"User-Agent",
				`Osteo & Physio PWA/${VITE_BITBUCKET_TAG ?? "0.0.0"} (${contactEmailAddress}; ${baseUrl})`
			)
			headers.set("From", contactEmailAddress)

			// Technologies
			headers.set("X-Powered-By", "React (https://react.dev)")
			headers.set("X-Requested-With", "Redux Toolkit (https://redux-toolkit.js.org)")
		}
	}),
	endpoints: builder => ({
		/**
		 * Gets the API usage & limits.
		 * @see https://api.getaddress.io/usage
		 * @see https://documentation.getaddress.io/usage
		 * @author Jay Hunter <jh@yello.studio>
		 * @since 2.2.0
		 */
		usage: builder.query<Usage, EmptyRequest>({
			keepUnusedDataFor: 0, // Never cache
			query: () => ({
				method: "GET",
				url: "/usage"
			}),
			transformResponse: (response: BaseResponse) => response.data as Usage,
			transformErrorResponse: (error: ErrorResponse) => new Error(error.data.reason)
		})
	})
})

export const useLazyUsageQuery: typeof api.endpoints.usage.useLazyQuery = api.endpoints.usage.useLazyQuery
export const useUsageQuery: typeof api.endpoints.usage.useQuery = api.endpoints.usage.useQuery
