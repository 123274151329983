import { Navigate } from "react-router-dom"

import Image from "~/components/controls/image"
import InstallPrompt from "~/components/installPrompt"
import ManualAddressStage from "~/components/onboarding/stages/address/manual"
import PartialAddressStage from "~/components/onboarding/stages/address/partial"
import SuggestedAddressStage from "~/components/onboarding/stages/address/suggested"
import AgreementStage from "~/components/onboarding/stages/agreement"
import AssociationStage from "~/components/onboarding/stages/association"
import DateOfBirthStage from "~/components/onboarding/stages/dateOfBirth"
import EmailAddressStage from "~/components/onboarding/stages/emailAddress"
import NameStage from "~/components/onboarding/stages/name"
import PhoneNumberStage from "~/components/onboarding/stages/phoneNumber"
import VerifyCodeStage from "~/components/onboarding/stages/verifyCode"
import PopupModal from "~/components/popupModal"
import Heading from "~/components/standard/text/heading"
import FadeIn from "~/components/wrappers/fadeIn"
import FullScreen from "~/components/wrappers/fullScreen"
import StageFlow from "~/components/wrappers/stageFlow"
import { useAuthSessionSelector } from "~/hooks/useAuthSession"
import { useMediaQueries } from "~/hooks/useMediaQueries"
import { Routes } from "~/router"
import { OnboardingStage } from "~/types/components/pages/onboarding"
import type { ComponentProps } from "~/types/components/props"

/**
 * The onboarding page.
 * This page handles the account registration & login process.
 * This is a full-screen page. It should not be accompanied by header & footer components.
 * @example <OnboardingPage />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const OnboardingPage = ({ ...props }: ComponentProps<HTMLDivElement>): JSX.Element => {
	// Responsiveness
	const { isMediumHeight, isLandscape } = useMediaQueries()

	// Auth
	const { isSignedIn } = useAuthSessionSelector()

	// Redirect if the user is already signed in...
	if (isSignedIn && !import.meta.env.DEV) return <Navigate to={Routes.Home} />

	// This has the same root components as <App />
	return (
		<FullScreen
			{...props}
			className={`items-center justify-between text-center ${isLandscape ? "py-16" : isMediumHeight ? "py-8" : "py-28"} ${props.className ?? ""}`.trimEnd()}>
			<InstallPrompt />
			<PopupModal />

			<FadeIn className={"flex flex-col items-center justify-center gap-y-4"}>
				{/* Cannot be SVG as it isn't transparent! */}
				<Image
					sourceUrl="/images/logo/transparent.webp"
					screenReaderDescription="The Osteo & Physio circular blue logo."
					width={isMediumHeight ? 175 : 250}
					height={isMediumHeight ? 175 : 250}
				/>
				<Heading>Welcome!</Heading>
			</FadeIn>
			<div className="flex flex-shrink-0 flex-grow overflow-clip sm:w-2/3 md:w-1/2">
				<StageFlow
					initialStage={OnboardingStage.PhoneNumber.valueOf()}
					stages={{
						[OnboardingStage.PhoneNumber.valueOf()]: <PhoneNumberStage />,
						[OnboardingStage.VerifyCode.valueOf()]: <VerifyCodeStage />,
						[OnboardingStage.Name.valueOf()]: <NameStage />,
						[OnboardingStage.DateOfBirth.valueOf()]: <DateOfBirthStage />,
						[OnboardingStage.EmailAddress.valueOf()]: <EmailAddressStage />,
						[OnboardingStage.PartialAddress.valueOf()]: <PartialAddressStage />,
						[OnboardingStage.ManualAddress.valueOf()]: <ManualAddressStage />,
						[OnboardingStage.SuggestedAddresses.valueOf()]: <SuggestedAddressStage />,
						[OnboardingStage.Association.valueOf()]: <AssociationStage />,
						[OnboardingStage.Agreement.valueOf()]: <AgreementStage />
					}}
				/>
			</div>
		</FullScreen>
	)
}

export default OnboardingPage
