import { useCallback } from "react"

/**
 * React callbacks for working with URLs.
 * @returns {object} Callbacks for opening URLs & downloading files.
 * @example const { openUrl } = useUrlMethods()
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.1.0
 */
export const useUrlMethods = (): {
	openUrl: (url: string) => void
	downloadFile: (fileName: string, objectUrl: string) => void
} => ({
	// Opens a URL in a new tab...
	openUrl: useCallback((url: string): void => {
		const link = document.createElement("a")
		link.href = url
		link.target = "_blank"
		link.rel = "noopener noreferrer"
		link.click()
		link.remove()
	}, []),

	// Downloads a file from a URL.createObjectURL()...
	downloadFile: useCallback((fileName: string, objectUrl: string): void => {
		const link = document.createElement("a")
		link.href = objectUrl
		link.download = fileName
		link.click()
		link.remove()
	}, [])
})
