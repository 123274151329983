import { createContext, useContext } from "react"

/**
 * The structure of a flow context.
 * @property {number} stage The current stage within the flow.
 * @property {function} transfer A function to transfer to another stage within the flow.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export interface FlowContext {
	currentStage: number
	previousStage: number | null
	transfer: (stage: number) => void
}

/**
 * The default values of the flow context.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const FlowContext = createContext<FlowContext>({
	currentStage: 0,
	previousStage: null,
	transfer: () => {
		console.warn("No flow context provider yet!")
	}
})

/**
 * A hook to access the current flow's context.
 * @example const flow = useFlowContext()
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const useFlowContext = (): FlowContext => useContext(FlowContext)

/**
 * The provider for the flow context.
 * @example <FlowContextProvider value={{ stage: 1 }}>...</FlowContextProvider>
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const FlowContextProvider = FlowContext.Provider
