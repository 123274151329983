import type { RouteObject } from "react-router-dom"
import { createBrowserRouter } from "react-router-dom"

import App from "~/app"
import AccountPage from "~/pages/account"
import AvailableAppointmentsPage from "~/pages/appointment/available"
import BookAppointmentPage from "~/pages/appointment/book"
import CancelAppointmentPage from "~/pages/appointment/cancel"
import ConfirmAppointmentPage from "~/pages/appointment/confirm"
import AppointmentDetailsPage from "~/pages/appointment/details"
import ControlsDemoPage from "~/pages/fullScreen/controlsDemo"
import ErrorPage from "~/pages/fullScreen/error"
import OnboardingPage from "~/pages/fullScreen/onboarding"
import HomePage from "~/pages/home"
import SettingsPage from "~/pages/settings"
import TermsPage from "~/pages/terms"

/**
 * The URL paths for the router.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export enum Routes {
	Home = "/home",

	BookAppointment = "/book",
	AvailableAppointments = "/available",
	ConfirmAppointment = "/confirm",
	RescheduleAppointment = "/reschedule",
	CancelAppointment = "/cancel",
	AppointmentDetails = "/details",

	Settings = "/settings",
	Account = "/account",

	TermsConditions = "/terms",

	Onboarding = "/onboarding" // Fullscreen
}

/**
 * The routes for the router.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const routes: RouteObject[] = [
	// Pages that require sign in...
	{
		path: "/",
		element: <App />,
		errorElement: <ErrorPage />,
		children: [
			{
				path: Routes.Home,
				element: <HomePage />
			},
			{
				path: `${Routes.BookAppointment}/*`,
				element: <BookAppointmentPage />
			},
			{
				path: Routes.AvailableAppointments,
				element: <AvailableAppointmentsPage />
			},
			{
				path: Routes.ConfirmAppointment,
				element: <ConfirmAppointmentPage />
			},
			{
				path: Routes.RescheduleAppointment,
				element: <BookAppointmentPage />
			},
			{
				path: Routes.CancelAppointment,
				element: <CancelAppointmentPage />
			},
			{
				path: Routes.AppointmentDetails,
				element: <AppointmentDetailsPage />
			},
			{
				path: Routes.Settings,
				element: <SettingsPage />
			},
			{
				path: Routes.Account,
				element: <AccountPage />
			}
		]
	},

	// Pages that do not require sign in...
	{
		path: Routes.Onboarding,
		element: <OnboardingPage />
	},
	{
		path: Routes.TermsConditions,
		element: <TermsPage />
	}
]

// Add the controls demonstration page if in development mode
if (import.meta.env.DEV)
	routes.push({
		path: "/controls",
		element: <ControlsDemoPage />
	})

/**
 * The router for navigating between pages.
 * @see https://reactrouter.com/en/main/start/tutorial
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const router = createBrowserRouter(routes)
