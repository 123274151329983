import { isObject } from "~/helpers/merge"

/**
 * A standard React router error (e.g., page not found).
 * @property {number} status The HTTP status code (e.g., 404).
 * @property {string} statusText The HTTP status message (e.g., Not Found).
 * @property {string} data A message describing the error.
 * @property {Error} error The internal Error.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export interface RouteError {
	status: number
	statusText: string

	data: string // Message

	error: Error
}

/**
 * Checks if the error is a React router error.
 * @param {unknown} error The error returned by the useRouteError() hook.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const isRouteError = (error: unknown): error is RouteError =>
	isObject(error) &&
	"status" in error &&
	typeof error.status === "number" &&
	"statusText" in error &&
	typeof error.statusText === "string" &&
	"data" in error &&
	typeof error.data === "string" &&
	"error" in error &&
	error.error instanceof Error
