import type { InflatedAppointment, InflatedAppointmentTime } from "~/classes/appointment"
import AppointmentCard from "~/components/appointments/card"
import Button from "~/components/controls/button"
import ErrorMessage from "~/components/errorMessage"
import Paragraph from "~/components/standard/text/paragraph"
import { ButtonThemes } from "~/types/components/controls/button"
import type { ComponentProps } from "~/types/components/props"

/**
 * The callback for when a time is clicked.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export type OnTimeClickCallback = (time: InflatedAppointmentTime) => void

/**
 * An available appointment card.
 * @example <AvailableAppointmentCard appointment={appointment} />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const AvailableAppointmentCard = ({
	appointment,

	initialTime, // Was for rescheduled appointments, though no longer possible now that the reschedule page is identical to book page
	onTimeClick,

	...props
}: ComponentProps<
	HTMLDivElement,
	{
		appointment: InflatedAppointment

		initialTime?: InflatedAppointmentTime
		onTimeClick?: OnTimeClickCallback
	}
>): JSX.Element => {
	//if (!appointment.isGrouped())
	if (appointment.times === null)
		return <ErrorMessage title="Appointment Type" content="Appointment does not have times!" />

	const times = removeDuplicateAppointmentTimes(appointment)

	return (
		<AppointmentCard {...props} appointment={appointment}>
			{times.length > 0 ? (
				<div className="flex flex-row flex-wrap justify-between gap-2">
					{times.map(time => (
						<Button
							key={time.getUniqueKey()}
							className="flex-grow basis-0" // Really awesome way to make everything the same size!
							label={time.getHumanStartTime()}
							standardHeight={false}
							theme={initialTime === time ? ButtonThemes.WhiteOnPrimary : ButtonThemes.PrimaryOnSecondary}
							onClick={() => {
								onTimeClick?.(time)
							}}
						/>
					))}
				</div>
			) : (
				<Paragraph className="px-4 text-center font-bold text-text">
					Appointment found but no available times.
				</Paragraph>
			)}
		</AppointmentCard>
	)
}

const removeDuplicateAppointmentTimes = (appointment: InflatedAppointment): InflatedAppointmentTime[] => {
	// Do not continue if this appointment has no times
	//if (!appointment.isGrouped()) return []
	if (appointment.times === null) return []

	const times: Record<string, InflatedAppointmentTime> = {}

	for (const time of appointment.times) {
		// Skip & warn about any duplicate times so we don't get React duplicate key errors
		if (times[time.getUniqueKey()]) {
			console.warn(
				`Duplicate appointment time found (${time.getUniqueKey()}, ${time.getHumanStartDate()} @ ${time.getHumanStartTime()} - ${time.getHumanFinishDate()} @ ${time.getHumanFinishTime()})!`
			)

			continue
		}

		// Append to array
		times[time.getUniqueKey()] = time
	}

	return Object.values(times)
}

export default AvailableAppointmentCard
