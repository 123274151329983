import { useCallback, useEffect } from "react"

import { useLazyCreateUserQuery, useLazySendVerificationCodeQuery } from "~/api/osteo-physio/client"
import {
	ErrorMessages,
	isAPIError,
	isHTTPError,
	isReduxDataAPIError,
	isStandardError
} from "~/api/osteo-physio/types/error"
import Form from "~/components/controls/forms/form"
import SwitchInput from "~/components/controls/inputs/switch"
import BackOrContinueButtons from "~/components/onboarding/backOrContinueButtons"
import OfflineWarning from "~/components/onboarding/offlineWarning"
import Paragraphs from "~/components/onboarding/paragraphs"
import StageWrapper from "~/components/onboarding/wrapper"
import Paragraph from "~/components/standard/text/paragraph"
import { useFlowContext } from "~/contexts/flow"
import { tidyUpUser } from "~/helpers/tidy-ups/user/user"
import { useAuthSessionDispatch } from "~/hooks/useAuthSession"
import { useFormDispatch } from "~/hooks/useForm"
import { useIsOnline } from "~/hooks/useIsOnline"
import { useOnboardingDispatch, useOnboardingSelector } from "~/hooks/useOnboarding"
import type { OnFormSubmitCallback } from "~/types/components/controls/form"
import { OnboardingStage } from "~/types/components/pages/onboarding"
import type { ComponentProps } from "~/types/components/props"

enum HTMLElementIdentifiers {
	Agreements = "confirmAgreements",
	Contact = "contact",
	Reminders = "reminders",
	Surveys = "surveys",
	TermsConditions = "termsOfService"
}

/**
 * The sixth stage of the onboarding process.
 * This stage allows the user to confirm various options.
 * This should be wrapped in a <StageFlow /> component!
 * @example <AgreementStage />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const AgreementStage = ({ ...props }: ComponentProps<HTMLDivElement>): JSX.Element => {
	const { transfer } = useFlowContext()

	const { showWarning, updateLoading } = useFormDispatch(HTMLElementIdentifiers.Agreements)

	const { setAgreements } = useOnboardingDispatch()
	const {
		phoneNumber,
		name,
		dateOfBirth,
		emailAddress,
		manualAddress: address,
		association,
		agreements
	} = useOnboardingSelector()

	const [createUser, createdUser] = useLazyCreateUserQuery()
	const [sendVerificationCode, verificationCodeResponse] = useLazySendVerificationCodeQuery()

	const { updateSessionUser } = useAuthSessionDispatch()

	const [isOnline] = useIsOnline()

	useEffect(() => {
		if (phoneNumber === null) return
		if (createdUser.isUninitialized || createdUser.isFetching || createdUser.isLoading) return

		updateLoading(false)

		if (createdUser.isError) {
			// API error message
			if (isAPIError(createdUser.error)) {
				if (createdUser.error.data.SystemErrorMessage === ErrorMessages.Success.valueOf()) return // API can return success within an error message?!

				// This shouldn't be possible unless the final stage is submitted twice, like if it fails the first time
				if (createdUser.error.data.SystemErrorMessage === ErrorMessages.UserAlreadyExists.valueOf()) {
					console.warn("This user already exists?!")
					showWarning(HTMLElementIdentifiers.TermsConditions, "A user already exists with these details.")
					return
				}

				console.warn(
					`Failed to register user! (API said '${createdUser.error.data.SystemErrorMessage?.toString() ?? "Unknown error"}')`
				)
				showWarning(HTMLElementIdentifiers.TermsConditions, "API error! See console for more information.")
				return
			}

			// HTTP error code
			if (isHTTPError(createdUser.error)) {
				console.info(`Verification code sent to '${phoneNumber.toString()}'!`)
				console.warn(`Failed to register user! (HTTP ${createdUser.error.status.toString()})`)
				showWarning(
					HTMLElementIdentifiers.TermsConditions,
					`HTTP ${createdUser.error.status.toString()}! See console for more information.`
				)
				return
			}

			// Fetch aborted (e.g., timeout?)
			if (isStandardError(createdUser.error) && createdUser.error.name === "AbortError") {
				console.warn("Failed to send verification code! (Request was aborted)")
				showWarning(HTMLElementIdentifiers.TermsConditions, "The request was aborted! Please try again.")
				return
			}

			// Unknown
			console.error(`Failed to register user! (${JSON.stringify(createdUser.error)})`)
			return
		}

		// Why?!
		if (isReduxDataAPIError(createdUser.data) && createdUser.data.SystemErrorMessage === ErrorMessages.Success) {
			console.warn("Failed to register user, but the API didn't give a reason?!")
			showWarning(
				HTMLElementIdentifiers.TermsConditions,
				"Sorry, there was an unexpected problem. Try again later."
			)
			return
		}

		// Should never happen but pleases TypeScript
		if (!("details" in createdUser.data)) {
			console.error("Passed error check without catching the error?!")
			showWarning(
				HTMLElementIdentifiers.TermsConditions,
				"Sorry, there was an unexpected problem. Try again later."
			)
			return
		}

		// Clean up the types returned by the API >:(
		const user = tidyUpUser(createdUser.data.details)

		// Great success
		console.info(`Registered new user '${user.first_name} ${user.last_name}'!`)
		updateSessionUser(user)

		/* eslint-disable promise/prefer-await-to-then */
		updateLoading(true)
		sendVerificationCode({
			mobileNumber: phoneNumber,
			userAgent: window.navigator.userAgent
		}).catch((error: unknown) => {
			console.error(`Failed to send verification code! (${error?.toString() ?? "Unknown error"})`)
			showWarning(
				HTMLElementIdentifiers.TermsConditions,
				"Sorry, an unknown problem occurred! Please try again later."
			)
		})
	}, [showWarning, updateLoading, updateSessionUser, sendVerificationCode, createdUser, phoneNumber])

	useEffect(() => {
		if (
			verificationCodeResponse.isUninitialized ||
			verificationCodeResponse.isFetching ||
			verificationCodeResponse.isLoading
		)
			return

		updateLoading(false)

		if (verificationCodeResponse.isError) {
			// API error message
			if (isAPIError(verificationCodeResponse.error)) {
				if (verificationCodeResponse.error.data.SystemErrorMessage === ErrorMessages.Success.valueOf()) return // API can return success within an error message?!

				if (
					verificationCodeResponse.error.data.SystemErrorMessage ===
					ErrorMessages.MobileNumberNotFound.valueOf()
				) {
					console.warn("Mobile phone number not found directly following account creation?!")
					showWarning(
						HTMLElementIdentifiers.TermsConditions,
						"Sorry, there was an unexpected problem. Try again later."
					)
					return
				}

				console.warn(
					`Failed to send verification code! (API said '${verificationCodeResponse.error.data.SystemErrorMessage?.toString() ?? "Unknown error"}')`
				)
				showWarning(HTMLElementIdentifiers.TermsConditions, "API error! See console for more information.")
				return
			}

			// HTTP error code
			if (isHTTPError(verificationCodeResponse.error)) {
				console.warn(
					`Failed to send verification code! (HTTP ${verificationCodeResponse.error.status.toString()})`
				)
				showWarning(
					HTMLElementIdentifiers.TermsConditions,
					`HTTP ${verificationCodeResponse.error.status.toString()}! See console for more information.`
				)
				return
			}

			// Unknown
			console.error(`Failed to send verification code! (${JSON.stringify(verificationCodeResponse.error)})`)
			return
		}

		// This is only necessary for verification code responses!
		if (verificationCodeResponse.data.SystemErrorMessage !== ErrorMessages.Success.valueOf()) {
			console.warn(
				`Failed to send verification code! (API said '${verificationCodeResponse.data.SystemErrorMessage?.toString() ?? "Unknown error"}')`
			)
			showWarning(HTMLElementIdentifiers.TermsConditions, "API error! See console for more information.")
			return
		}

		// Great success
		transfer(OnboardingStage.VerifyCode)
	}, [showWarning, updateLoading, transfer, verificationCodeResponse])

	const onSubmitted = useCallback<OnFormSubmitCallback>(
		(values): void => {
			if (
				phoneNumber === null ||
				name === null ||
				dateOfBirth === null ||
				emailAddress === null ||
				address === null ||
				association === null
			) {
				console.warn("One or more registration stages are not yet complete!")
				showWarning(HTMLElementIdentifiers.Agreements, "Previous stages are not yet complete.")
				return
			}

			const confirmContact = (values.get(HTMLElementIdentifiers.Contact) as boolean | undefined) ?? false
			const confirmReminders = (values.get(HTMLElementIdentifiers.Reminders) as boolean | undefined) ?? false
			const confirmSurveys = (values.get(HTMLElementIdentifiers.Surveys) as boolean | undefined) ?? false
			const confirmTermsConditions =
				(values.get(HTMLElementIdentifiers.TermsConditions) as boolean | undefined) ?? false

			if (!confirmTermsConditions) {
				console.warn("Terms & Conditions not agreed to!")
				showWarning(HTMLElementIdentifiers.TermsConditions, "You must agree to the Terms & Conditions.")
				return
			}

			const dateOfBirthYYYYMMDD = dateOfBirth.toISOString().split("T")[0]
			if (dateOfBirthYYYYMMDD === undefined) {
				console.warn("Date of Birth not set to a valid value?!")
				showWarning(HTMLElementIdentifiers.Agreements, "Previous stages are not yet complete.")
				return
			}

			setAgreements({
				contact: confirmContact,
				reminders: confirmReminders,
				surveys: confirmSurveys,
				termsOfService: confirmTermsConditions
			})

			/* eslint-disable promise/prefer-await-to-then */
			updateLoading(true)
			createUser({
				user: {
					first_name: name.first,
					last_name: name.last,

					email: emailAddress,

					mobile_phone: phoneNumber,
					home_phone: null, // We don't collect this

					date_of_birth: dateOfBirthYYYYMMDD,

					address_line_1: address.firstLine,
					address_line_2: address.secondLine, // This is optional
					address_line_3: null, // We don't collect this
					city: address.city,
					state: address.county,
					post_code: address.postCode,

					agree_contact: confirmContact,
					agree_reminders: confirmReminders,
					agree_survey: confirmSurveys,

					association_id: association.id
				},
				userAgent: navigator.userAgent
			}).catch((error: unknown) => {
				console.error(`Failed to create user! (${error?.toString() ?? "Unknown error"})`)
				showWarning(
					HTMLElementIdentifiers.Agreements,
					"Sorry, an unknown problem occurred! Please try again later."
				)
			})
		},
		[
			showWarning,
			setAgreements,
			updateLoading,
			createUser,
			phoneNumber,
			name,
			dateOfBirth,
			emailAddress,
			address,
			association
		]
	)

	return (
		<StageWrapper {...props}>
			<Paragraphs>
				<Paragraph>Please confirm your choices of the following to finalise your account.</Paragraph>
			</Paragraphs>
			<OfflineWarning visible={!isOnline} />
			<Form id={HTMLElementIdentifiers.Agreements} onSubmit={onSubmitted}>
				<SwitchInput
					id={HTMLElementIdentifiers.Contact}
					label="Contacting You"
					tooltip="Receiving messages regarding appointments"
					initialValue={agreements?.contact ?? true}
					isDisabled={!isOnline}
				/>
				<SwitchInput
					id={HTMLElementIdentifiers.Reminders}
					label="Appointment Reminders"
					tooltip="Emails & texts about upcoming appointments"
					initialValue={agreements?.reminders ?? true}
					isDisabled={!isOnline}
				/>
				<SwitchInput
					id={HTMLElementIdentifiers.Surveys}
					label="Feedback & Surveys"
					tooltip="Helpful questionnaires after your appointments"
					initialValue={agreements?.surveys ?? true}
					isDisabled={!isOnline}
				/>
				<SwitchInput
					id={HTMLElementIdentifiers.TermsConditions}
					label="Terms & Conditions"
					url="/terms"
					isFocused={true}
					isDisabled={!isOnline}
				/>
				<BackOrContinueButtons
					previousStage={OnboardingStage.Association}
					submitLabel="Finish"
					submitLoadingLabel="Finishing..."
					isDisabled={!isOnline}
				/>
			</Form>
		</StageWrapper>
	)
}

export default AgreementStage
