import { PhoneIcon } from "@heroicons/react/24/solid"

import type { InputProps } from "~/components/controls/inputs/input"
import Input from "~/components/controls/inputs/input"
import { longerColorTransitionStyles } from "~/config/transitions"
import { inputDisabledColorStyles, inputIconSize } from "~/constants/components/input"
import type { ComponentProps } from "~/types/components/props"

/**
 * A pre-styled standard HTML phone number input.
 * @example <PhoneNumberInput id="mobileNumberInput" />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const PhoneNumberInput = ({
	placeholder = "01234 567890...",
	tooltip = "Please enter a phone number",

	...props
}: ComponentProps<
	HTMLInputElement,
	Omit<InputProps, "type" | "minimumLength" | "maximumLength" | "missingValueWarningMessage">
>): JSX.Element => (
	<Input
		{...props}
		type="tel"
		inputMode="tel"
		tooltip={tooltip}
		placeholder={placeholder}
		minimumLength={11}
		maximumLength={15}
		missingValueWarningMessage="Enter your phone number!"
		startIcon={isLoading => (
			<PhoneIcon
				className={`${longerColorTransitionStyles} ${isLoading ? inputDisabledColorStyles : "fill-primary"}`}
				width={inputIconSize}
				height={inputIconSize}
			/>
		)}
	/>
)

export default PhoneNumberInput
