import { Link } from "react-router-dom"

import Paragraphs from "~/components/onboarding/paragraphs"
import Paragraph from "~/components/standard/text/paragraph"
import FadeIn from "~/components/wrappers/fadeIn"
import { useAuthSessionDispatch } from "~/hooks/useAuthSession"
import { Routes } from "~/router"
import type { ComponentProps } from "~/types/components/props"

/**
 * An error message.
 * @example <ErrorMessage title="Failure" content="There was an error!" />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const ErrorMessage = ({
	title = "Unknown Error",
	content = "See console for more information.",

	showContact = true,
	showRoutes = true,

	...props
}: ComponentProps<
	HTMLDivElement,
	{
		title?: string
		content?: string

		showContact?: boolean
		showRoutes?: boolean
	}
>): JSX.Element => {
	const { clearAuthSession } = useAuthSessionDispatch()

	return (
		<FadeIn
			{...props}
			className={`flex flex-grow flex-col justify-center text-center font-mono ${props.className ?? ""}`.trimEnd()}>
			<Paragraphs>
				{title && <Paragraph className="text-xl font-bold">{title}</Paragraph>}
				{content && <Paragraph className="text-sm">{content}</Paragraph>}
				{title === "" && content === "" && (
					<Paragraph className="text-md py-4 font-mono font-bold">An unknown error occurred!</Paragraph>
				)}
				{showContact && (
					<Paragraph className="mt-6 text-sm">
						Report this issue by contacting{" "}
						<a href="mailto:support@osteoandphysio.co.uk" target="_blank" rel="noopener noreferrer">
							support@osteoandphysio.co.uk
						</a>
						.
					</Paragraph>
				)}
				{showRoutes && (
					<Paragraph className="mt-4 text-sm">
						<Link to={Routes.Home}>Return to Home</Link> or{" "}
						<a href="#" onClick={clearAuthSession}>
							Sign Out
						</a>
					</Paragraph>
				)}
			</Paragraphs>
		</FadeIn>
	)
}

export default ErrorMessage
