import { ImSpinner9 } from "react-icons/im"

import { longerOpacityTransitionStyles } from "~/config/transitions"
import type { ComponentProps } from "~/types/components/props"

/**
 * Default size for the loading spinner icon.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const loadingSpinnerIconSize = 32

/**
 * A loading spinner.
 * @example <ErrorMessage title="Failure" content="There was an error!" />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const LoadingSpinner = ({
	visible = true,
	inContainer = false,
	size = loadingSpinnerIconSize,
	...props
}: ComponentProps<
	HTMLDivElement,
	{
		visible?: boolean
		inContainer?: boolean
		size?: number
	}
>): JSX.Element => (
	<div
		{...props}
		className={`${longerOpacityTransitionStyles} flex items-center justify-center ${!inContainer ? "flex-grow" : ""} ${visible ? "opacity-100" : "opacity-0"} ${props.className ?? ""}`.trimEnd()}>
		<ImSpinner9 className={`animate-spin ${!inContainer ? "fill-primary" : ""}`.trimEnd()} size={size} />
		{/* width & height props don't work too well here :? */}
	</div>
)

export default LoadingSpinner
