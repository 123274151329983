import { useCallback } from "react"

import type { Modal } from "~/state/slices/userInterface"
import { slice } from "~/state/slices/userInterface"
import { useReduxDispatch, useReduxSelector } from "~/state/store"

/**
 * React hook to update the modal.
 * @returns {object} Functions to update the state of the modal.
 * @example const { showModal, hideModal } = useModalDispatch()
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const useModalDispatch = (): {
	showNoticeModal: (title: string, content: string | (() => JSX.Element)) => void
	showInputModal: (formId: string, title: string, component: () => JSX.Element) => void
	hideModal: () => void
} => {
	const dispatch = useReduxDispatch()

	// Shows a notice (acknowledgement/information) modal
	const showNoticeModal = useCallback(
		(title: string, content: string | (() => JSX.Element)) => {
			dispatch(
				slice.actions.updateModal({
					title,
					content,
					formIdentifier: null,

					buttons: {
						dismiss: null,

						positive: "Okay",
						negative: null
					}
				})
			)
		},
		[dispatch]
	)

	// Shows a modal requiring user input
	const showInputModal = useCallback(
		(formId: string, title: string, component: () => JSX.Element) => {
			dispatch(
				slice.actions.updateModal({
					title,
					content: component,
					formIdentifier: formId,

					buttons: {
						dismiss: null,

						positive: "Continue",
						negative: "Cancel"
					}
				})
			)
		},
		[dispatch]
	)

	// Hides the modal
	const hideModal = useCallback(() => {
		dispatch(
			slice.actions.updateModal({
				title: null,
				content: null,
				formIdentifier: null,

				buttons: {
					dismiss: null,

					positive: null,
					negative: null
				}
			})
		)
	}, [dispatch])

	return {
		showNoticeModal,
		showInputModal,
		hideModal
	}
}

/**
 * React hook to get the state for the modal.
 * @returns {Modal} The data for the modal.
 * @example const { title, content } = useModalSelector()
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const useModalSelector = (): Modal & {
	shouldShow: boolean // Computed for convenience
} => {
	const modal = useReduxSelector(store => store.userInterface.modal)

	return {
		...modal,
		shouldShow:
			modal.title !== null &&
			modal.content !== null &&
			Object.entries(modal.buttons).some(([, value]) => value !== null)
	}
}
