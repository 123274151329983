/**
 * The colors used across the app.
 * @see tailwind.config.js
 */
export enum Color {
	Primary = "#037899", // Dark Cyan
	Secondary = "#dbebf5", // Light Blue

	//Bird = "#00a4bf", // Cyan
	//Badge = "#f59400", // Orange

	Text = "#00144d", // Dark Blue
	Warning = "#f13948", // Light Red

	FormLabel = "#0885ab", // Light Cyan
	ControlValue = "#404040", // Dark Gray
	ControlBorder = "#cccccc", // Gray

	// These are based on the primary colour and get progressively darker
	ButtonHover = "#017291",
	ButtonActive = "#066984",
	ButtonDisabled = "#115366",

	Positive = "#00a837", // Green

	White = "#ffffff"
}
