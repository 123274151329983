import { createSlice, type PayloadAction } from "@reduxjs/toolkit"

import type { CustomDate } from "~/contexts/datePicker"

/**
 * The structure of the appointment filters slice.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.5.0
 */
export interface SliceStructure {
	therapyTypeIdentifier: number | null
	appointmentTypeIdentifier: number | null

	locationIdentifier: number | null
	practitionerIdentifier: number | null
	genderIdentifier: number | null

	date: CustomDate | null
}

/**
 * The Redux slice for storing the appointment filters.
 * All filters are initially populated as null.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.5.0
 */
export const slice = createSlice({
	name: "appointmentFilters",
	initialState: {
		therapyTypeIdentifier: null,
		appointmentTypeIdentifier: null,

		locationIdentifier: null,
		practitionerIdentifier: null,
		genderIdentifier: null,

		date: null
	} as SliceStructure,
	reducers: {
		// All properties on this one are optional - https://www.typescriptlang.org/docs/handbook/utility-types.html#partialtype
		update: (state, action: PayloadAction<Partial<SliceStructure>>): SliceStructure => ({
			...state,
			...action.payload
		}),

		updateTherapyTypeIdentifier: (
			state,
			action: PayloadAction<Pick<SliceStructure, "therapyTypeIdentifier">>
		): SliceStructure => ({
			...state,
			...action.payload,
			therapyTypeIdentifier: action.payload.therapyTypeIdentifier
		}),
		updateAppointmentTypeIdentifier: (
			state,
			action: PayloadAction<Pick<SliceStructure, "appointmentTypeIdentifier">>
		): SliceStructure => ({
			...state,
			...action.payload,
			appointmentTypeIdentifier: action.payload.appointmentTypeIdentifier
		}),

		updateLocationIdentifier: (
			state,
			action: PayloadAction<Pick<SliceStructure, "locationIdentifier">>
		): SliceStructure => ({
			...state,
			...action.payload,
			locationIdentifier: action.payload.locationIdentifier
		}),
		updatePractitionerIdentifier: (
			state,
			action: PayloadAction<Pick<SliceStructure, "practitionerIdentifier">>
		): SliceStructure => ({
			...state,
			...action.payload,
			practitionerIdentifier: action.payload.practitionerIdentifier
		}),
		updateGenderIdentifier: (
			state,
			action: PayloadAction<Pick<SliceStructure, "genderIdentifier">>
		): SliceStructure => ({
			...state,
			...action.payload,
			genderIdentifier: action.payload.genderIdentifier
		}),

		updateDate: (state, action: PayloadAction<Pick<SliceStructure, "date">>): SliceStructure => ({
			...state,
			...action.payload,
			date: action.payload.date
		})
	}
})

/**
 * Actions for updating the appointment filters Redux store.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.5.0
 */
export const {
	updateTherapyTypeIdentifier,
	updateAppointmentTypeIdentifier,
	updateLocationIdentifier,
	updatePractitionerIdentifier,
	updateGenderIdentifier,
	updateDate
} = slice.actions

export default slice.reducer
