import type { SerializedError } from "@reduxjs/toolkit"
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { useCallback, useEffect, useMemo } from "react"

import { useFetchAppointmentFiltersQuery, useFetchCoreQuery } from "~/api/osteo-physio/client"
import type { FilterableTypes } from "~/helpers/filterableTypes"
import { discardIrrelevantFilterableTypes } from "~/helpers/filterableTypes"
import { AnyIdentifier } from "~/helpers/object"
import { useChosenTherapyTypeIdentifier, type IdentifierCallback } from "~/hooks/useAppointmentFilters"
import { slice } from "~/state/slices/appointmentFilters"
import { useReduxDispatch } from "~/state/store"
import type { EmptyCallback } from "~/types/components/callbacks"

/**
 * React hook for fetching & initialising the therapy types.
 * @param {string | undefined} initialTherapyTypeName The name of the therapy type to initially use.
 * @returns {object} The therapy types, any query errors & a loading state.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.5.0
 */
export const useTherapyTypes = (
	initialTherapyTypeName?: string
): {
	chosenTherapyTypeIdentifier: number | null

	therapyTypes: FilterableTypes | null
	queryError: FetchBaseQueryError | SerializedError | null
	isLoading: boolean

	setChosenTherapyTypeIdentifier: IdentifierCallback
	clearChosenTherapyTypeIdentifier: EmptyCallback
} => {
	// Use relevant filters from Redux store
	const dispatch = useReduxDispatch()
	const chosenTherapyTypeIdentifier = useChosenTherapyTypeIdentifier()

	// Fetch core data & available appointment filters
	const { data: core, error: coreError } = useFetchCoreQuery({}) // This is going to be cached from the home page!
	const { currentData: availableFilters, error: availableFiltersError } = useFetchAppointmentFiltersQuery({})

	// Extract just the therapy types
	// NOTE: This usually removes irrelevant types, but that isn't performed here as this is the root filter
	const { therapyTypes } = useMemo(
		() => discardIrrelevantFilterableTypes(core, availableFilters),
		[core, availableFilters]
	)

	// Updates the chosen therapy type identifier in the Redux store...
	const setChosenTherapyTypeIdentifier = useCallback<IdentifierCallback>(
		identifier => {
			if (isNaN(identifier)) {
				console.error("The therapy type identifier must be a valid number")
				return
			}

			// This resets all other filters as they may be different for the new therapy type!
			dispatch(
				slice.actions.update({
					therapyTypeIdentifier: identifier !== AnyIdentifier ? identifier : null,
					appointmentTypeIdentifier: null,

					locationIdentifier: null,
					practitionerIdentifier: null,
					genderIdentifier: null,

					date: null
				})
			)
		},
		[dispatch]
	)

	// Wipes the chosen therapy type identifier from the Redux store...
	const clearChosenTherapyTypeIdentifier = useCallback<EmptyCallback>(() => {
		dispatch(
			slice.actions.updateTherapyTypeIdentifier({
				therapyTypeIdentifier: null
			})
		)
	}, [dispatch])

	// Tries to set the initial therapy type identifier using sensible values...
	useEffect(() => {
		if (!core || !availableFilters) return // Don't bother if we do not have required data
		if (!therapyTypes) return // Don't bother if there are no therapy types to choose from
		if (chosenTherapyTypeIdentifier !== null) return // Don't bother if already set

		// Try find a therapy type with the same name as passed in the URL
		if (initialTherapyTypeName !== undefined) {
			const matchingTherapyTypeIdentifier = Array.from(therapyTypes.entries()).find(
				([, therapyName]) => therapyName.toLowerCase() === initialTherapyTypeName
			)?.[0]
			if (matchingTherapyTypeIdentifier !== undefined) {
				setChosenTherapyTypeIdentifier(matchingTherapyTypeIdentifier)
				console.info(
					`The initial therapy type identifier is ${matchingTherapyTypeIdentifier.toString()} (via matching name '${initialTherapyTypeName}')!`
				)
				return
			}
		}

		// Fallback to the first therapy type
		const firstTherapyTypeIdentifier = Array.from(therapyTypes.keys())[0]
		if (firstTherapyTypeIdentifier !== undefined) {
			setChosenTherapyTypeIdentifier(firstTherapyTypeIdentifier)
			console.info(`The initial therapy type identifier is ${firstTherapyTypeIdentifier.toString()}!`)
			return
		}
	}, [
		setChosenTherapyTypeIdentifier,
		clearChosenTherapyTypeIdentifier,
		core,
		availableFilters,
		therapyTypes,
		chosenTherapyTypeIdentifier,
		initialTherapyTypeName
	])

	return {
		chosenTherapyTypeIdentifier: chosenTherapyTypeIdentifier ?? null,

		therapyTypes: therapyTypes ?? null,
		queryError: coreError ?? availableFiltersError ?? null, // Forward API query errors
		isLoading: !core || !availableFilters || !therapyTypes, // Waiting for queries to finish

		setChosenTherapyTypeIdentifier: setChosenTherapyTypeIdentifier,
		clearChosenTherapyTypeIdentifier: clearChosenTherapyTypeIdentifier
	}
}
