import { ArrowTopRightOnSquareIcon } from "@heroicons/react/16/solid"
import { CubeIcon } from "@heroicons/react/24/outline"
import { ChevronDownIcon } from "@heroicons/react/24/solid"
import type { FormEvent } from "react"
import { useCallback, useEffect, useRef } from "react"

import type { InputProps } from "~/components/controls/inputs/input"
import Paragraph from "~/components/standard/text/paragraph"
import FadeIn from "~/components/wrappers/fadeIn"
import { dropDownIconStyles } from "~/constants/components/dropDown"
import { inputDisabledColorStyles, inputIconSize, inputLinkIconSize } from "~/constants/components/input"
import { orNull } from "~/helpers/null"
import { useFormSelector } from "~/hooks/useForm"
import { useInputDispatch, useInputSelector } from "~/hooks/useInput"
import type { OnItemChooseCallback } from "~/types/components/controls/dropDown"
import type { ComponentProps } from "~/types/components/props"

/**
 * A pre-styled standard HTML select (drop-down) input.
 * @example <DropDownInput id="dropDownInput" choices={["Hello", "World"]} />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const DropDownInput = ({
	id,

	label,
	tooltip = "Please select a choice.",

	initialValue,

	startIcon,

	wide = true,
	sideBySide = false,

	isDisabled = false,
	isRequired = true,
	isFocused,
	isLoading,

	choices,

	linkText,
	linkIcon,
	isLinkDisabled = isDisabled,
	onLinkClick,

	//chooseOnLoad = false,
	onChoose,

	containerClassName, // This is hacky

	...props
}: ComponentProps<
	HTMLSelectElement,
	Pick<
		InputProps,
		| "id"
		| "label"
		| "tooltip"
		| "initialValue"
		| "startIcon"
		| "isDisabled"
		| "isRequired"
		| "isFocused"
		| "isLoading"
		| "linkText"
		| "linkIcon"
		| "isLinkDisabled"
		| "onLinkClick"
		| "sideBySide"
	> & {
		wide?: boolean

		choices?: Record<string, string> | Map<string, string>

		//chooseOnLoad?: boolean
		onChoose?: OnItemChooseCallback

		containerClassName?: string
	}
>): JSX.Element => {
	const { isLoading: isFormLoading } = useFormSelector()

	const { showWarning, clearWarning } = useInputDispatch(id)
	const { warningMessage } = useInputSelector(id)

	const inputReference = useRef<HTMLSelectElement | null>(null)
	useEffect(() => {
		if (isFocused === true && inputReference.current && inputReference.current.value === "")
			inputReference.current.focus()
	})

	const onInvalid = useCallback(
		(event: FormEvent<HTMLSelectElement>): void => {
			event.preventDefault() // Prevent showing the browser's default validation message

			// If there's already a warning message, don't show another one
			if (warningMessage !== null) return

			const input = event.currentTarget

			// TODO: Handle all possible errors, check if the selection was in the choices prop, etc.
			if (!input.validity.valid) {
				console.warn("Invalid selection value?!")
				showWarning("Select an option from the list.")
			}
		},
		[showWarning, warningMessage]
	)

	return (
		// items-center here breaks spacing between label & link text
		<div
			className={`flex flex-shrink flex-grow-0 flex-col gap-y-2 ${wide ? "w-full flex-grow" : "w-fit"} ${containerClassName ?? ""}`.trimEnd()}>
			<div className={`flex ${sideBySide ? "flex-row gap-x-4" : "flex-col gap-y-2"}`}>
				{(label !== undefined || linkText !== undefined) && (
					<div
						className={`flex ${sideBySide ? "flex-shrink-0 basis-40 flex-col gap-y-1" : "flex-row items-end justify-between"}`}>
						{label !== undefined && (
							<label htmlFor={id} className="text-sm font-bold text-text">
								{label}
								{isRequired && <span className="ms-1 text-red-600">*</span>}
							</label>
						)}
						{linkText !== undefined && (
							<Paragraph
								className={`flex flex-row gap-x-1 text-right text-xs ${!choices || (isDisabled && isLinkDisabled) || (isLoading ?? isFormLoading) ? "text-gray-400 hover:cursor-not-allowed" : "text-primary hover:cursor-pointer hover:underline"}`}
								onClick={onLinkClick}>
								{linkText}
								{/* eslint-disable indent */}
								{typeof linkIcon === "function"
									? linkIcon(isLoading ?? isFormLoading, !choices || (isDisabled && isLinkDisabled))
									: (linkIcon ?? (
											<ArrowTopRightOnSquareIcon
												width={inputLinkIconSize}
												height={inputLinkIconSize}
											/>
										))}
							</Paragraph>
						)}
					</div>
				)}
				<div
					key={initialValue}
					className={`flex flex-row rounded-lg border border-controlBorder py-2 text-sm text-text shadow-sm ${!choices || isDisabled || (isLoading ?? isFormLoading) ? "bg-gray-300 hover:cursor-not-allowed" : "bg-white"} ${wide ? "w-full flex-grow" : "w-fit"}`}>
					{/* eslint-disable indent */}
					{typeof startIcon === "function"
						? startIcon(false)
						: (startIcon ?? (
								<CubeIcon
									className={`${dropDownIconStyles} ${!choices || isDisabled || (isLoading ?? isFormLoading) ? inputDisabledColorStyles : "fill-primary"}`}
									width={inputIconSize}
									height={inputIconSize}
								/>
							))}
					<select
						ref={inputReference}
						{...props}
						className={`z-20 -mx-8 -my-2 flex w-[100%] rounded-lg px-10 text-text ${!choices || isDisabled || (isLoading ?? isFormLoading) ? "pointer-events-none cursor-not-allowed" : "pointer-events-auto cursor-auto"} ${props.className ?? ""}`.trimEnd()}
						id={id}
						name={id}
						defaultValue={
							initialValue ??
							(choices instanceof Map
								? Array.from(choices.keys())[0]
								: choices !== undefined
									? Object.keys(choices)[0]
									: undefined)
						}
						//aria-placeholder={initialValue ?? choices?.[0]?.toLowerCase()}
						title={tooltip}
						aria-label={label}
						disabled={!choices || isDisabled || (isLoading ?? isFormLoading)}
						//aria-disabled={(!choices || isDisabled) ?? (isLoading ?? isFormLoading)}
						required={isRequired}
						//aria-required={isRequired}
						autoFocus={isFocused}
						onInvalid={onInvalid}
						onChange={event => {
							if (!onChoose) return

							if (warningMessage !== null) clearWarning()

							const value = event.currentTarget.value
							const text = event.currentTarget.selectedOptions[0]?.text
							if (value === "" || text === undefined) return

							event.preventDefault()

							onChoose(value, text, event)
						}}
						// onLoad={event => {
						// 	if (!chooseOnLoad) return
						// 	if (!onChoose) return

						// 	const value = event.currentTarget.value
						// 	const text = event.currentTarget.selectedOptions[0]?.text
						// 	if (!value || !text) return

						// 	onChoose(value, text, event as ChangeEvent<HTMLSelectElement>) // This cast will probably cause issues down the line :?
						// }}
					>
						{choices ? (
							Array.from(Object.entries(choices) as [string, string][]).map(([value, choice], index) => (
								<option key={index} value={value}>
									{orNull(choice) ?? `Unknown (${value})`}
								</option>
							))
						) : (
							<option>Loading...</option>
						)}
					</select>
					<ChevronDownIcon className="z-10 me-2 fill-text" width={inputIconSize} height={inputIconSize} />
				</div>
			</div>
			{warningMessage !== null && (
				<FadeIn>
					<Paragraph className="text-center text-xs font-bold text-warning">{warningMessage}</Paragraph>
				</FadeIn>
			)}
		</div>
	)
}

export default DropDownInput
