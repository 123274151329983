import type { Dispatch, ReactNode, SetStateAction } from "react"
import { createContext, useState } from "react"

import type { Day, Month } from "~/types/components/controls/datePicker"

export interface CustomDate {
	year: number
	month: Month
	day: Day
}

export interface State {
	date: CustomDate

	options: {
		shouldRunChooseCallback: boolean
		findAvailableDaysFromEnd: boolean
	}

	extras: {
		daysInPreviousMonth: number | null
	}
}

export interface DatePickerContext {
	futureYearCount: number

	state: State
	setState: Dispatch<SetStateAction<State>>
}

// eslint-disable-next-line react-refresh/only-export-components
export const DatePickerContext = createContext<DatePickerContext>({
	futureYearCount: 0,

	state: {
		date: {
			year: 2024,
			month: 0,
			day: 16
		},

		options: {
			shouldRunChooseCallback: false,
			findAvailableDaysFromEnd: false
		},

		extras: {
			daysInPreviousMonth: null
		}
	},
	setState: () => {
		console.warn("Date picker context not yet initialised!")
	}
})

const InitialiseDatePickerContext = ({
	futureYearCount,
	children
}: {
	futureYearCount: number

	children: ReactNode
}): JSX.Element => {
	const [state, setState] = useState<State>(() => {
		const currentDateTime = new Date()

		return {
			date: {
				year: currentDateTime.getFullYear(),
				month: currentDateTime.getMonth() as Month,
				day: currentDateTime.getDate() as Day
			},

			options: {
				shouldRunChooseCallback: false,
				findAvailableDaysFromEnd: false
			},

			extras: {
				daysInPreviousMonth: null
			}
		}
	})

	return (
		<DatePickerContext.Provider
			value={{
				futureYearCount,

				state,
				setState
			}}>
			{children}
		</DatePickerContext.Provider>
	)
}

export default InitialiseDatePickerContext
