import { useFetchAppointmentFiltersQuery, useFetchCoreQuery } from "~/api/osteo-physio/client"
import { isAPIError, isHTTPError } from "~/api/osteo-physio/types/error"
import FilteredDatePicker from "~/components/appointments/filters/date"
import AvailableAppointmentsList from "~/components/appointments/list"
import ErrorMessage from "~/components/errorMessage"
import LoadingSpinner, { loadingSpinnerIconSize } from "~/components/loadingSpinner"
import Divider from "~/components/standard/layout/divider"
import Page from "~/components/standard/layout/page"
import Section from "~/components/standard/layout/section"
import { useAppointmentTypes } from "~/hooks/appointments/useAppointmentTypes"
import { useTherapyTypes } from "~/hooks/appointments/useTherapyTypes"
import { useAppointmentNavigationState } from "~/hooks/useAppointmentNavigationState"
import { useMediaQueries } from "~/hooks/useMediaQueries"
import type { ComponentProps } from "~/types/components/props"

enum HTMLElementIdentifiers {
	Date = "date"
}

/**
 * The available appointments appointment page.
 * This page handles changing the chosen filter date & listing the available appointments.
 * This contains the therapy & appointment types context providers for initial load.
 * @example <AvailableAppointmentsPage />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.5.0
 */
const AvailableAppointmentsPage = ({ ...props }: ComponentProps<HTMLDivElement>): JSX.Element => {
	// Only set when rescheduling an appointment
	const appointment = useAppointmentNavigationState()

	// Therapy & appointment types from Redux store
	const {
		chosenTherapyTypeIdentifier,
		queryError: therapyTypesError,
		isLoading: isTherapyTypesLoading
	} = useTherapyTypes()
	const {
		chosenAppointmentTypeIdentifier,
		queryError: appointmentTypesError,
		isLoading: isAppointmentTypesLoading
	} = useAppointmentTypes()

	// Show query errors
	if (therapyTypesError) {
		if (isAPIError(therapyTypesError))
			return <ErrorMessage title="API Error" content={therapyTypesError.data.SystemErrorMessage?.toString()} />

		if (isHTTPError(therapyTypesError))
			return <ErrorMessage title="HTTP Error" content={therapyTypesError.status.toString()} />

		return <ErrorMessage title="Unknown Error" content="Failed to use therapy types!" />
	}
	if (appointmentTypesError) {
		if (isAPIError(appointmentTypesError))
			return (
				<ErrorMessage title="API Error" content={appointmentTypesError.data.SystemErrorMessage?.toString()} />
			)

		if (isHTTPError(appointmentTypesError))
			return <ErrorMessage title="HTTP Error" content={appointmentTypesError.status.toString()} />

		return <ErrorMessage title="Unknown Error" content="Failed to use therapy types!" />
	}

	// Wait before rendering
	if (isTherapyTypesLoading || isAppointmentTypesLoading)
		return <LoadingSpinner size={loadingSpinnerIconSize} className="mt-4" />

	// Ensure we have the required filters
	if (chosenTherapyTypeIdentifier === null)
		return <ErrorMessage title="No Therapy Type" content="Choose a therapy type!" />
	if (chosenAppointmentTypeIdentifier === null)
		return <ErrorMessage title="No Appointment Type" content="Choose an appointment type!" />

	return (
		<Page {...props} className={`gap-y-2 ${props.className ?? ""}`.trimEnd()}>
			<Section title="Available Appointments">
				<DatePickerWrapper
					chosenTherapyTypeIdentifier={chosenTherapyTypeIdentifier}
					chosenAppointmentTypeIdentifier={chosenAppointmentTypeIdentifier}
				/>

				<Divider />

				<AvailableAppointmentsList
					rescheduleAppointmentId={appointment?.id ?? undefined}
					chosenTherapyTypeIdentifier={chosenTherapyTypeIdentifier}
					chosenAppointmentTypeIdentifier={chosenAppointmentTypeIdentifier}
				/>
			</Section>
		</Page>
	)
}

const DatePickerWrapper = ({
	chosenTherapyTypeIdentifier,
	chosenAppointmentTypeIdentifier,

	...props
}: ComponentProps<
	HTMLDivElement,
	{
		chosenTherapyTypeIdentifier: number
		chosenAppointmentTypeIdentifier: number
	}
>): JSX.Element => {
	const { isMediumWidth } = useMediaQueries()

	// Fetch core data & possible appointment filters using the required filters
	const { data: core, error: coreError } = useFetchCoreQuery({})
	const { currentData: availableFilters, error: availableFiltersError } = useFetchAppointmentFiltersQuery(
		{
			therapyId: chosenTherapyTypeIdentifier,
			typeId: chosenAppointmentTypeIdentifier
		},
		{
			refetchOnMountOrArgChange: true // Always refetch whenever the therapy or appointment types change
		}
	)

	// Show fetch errors
	if (coreError) {
		if (isAPIError(coreError))
			return <ErrorMessage title="API Error" content={coreError.data.SystemErrorMessage?.toString()} />

		if (isHTTPError(coreError)) return <ErrorMessage title="HTTP Error" content={coreError.status.toString()} />

		return <ErrorMessage title="Unknown Error" content="Failed to fetch core data!" />
	}

	if (availableFiltersError) {
		if (isAPIError(availableFiltersError))
			return (
				<ErrorMessage title="API Error" content={availableFiltersError.data.SystemErrorMessage?.toString()} />
			)

		if (isHTTPError(availableFiltersError))
			return <ErrorMessage title="HTTP Error" content={availableFiltersError.status.toString()} />

		return <ErrorMessage title="Unknown Error" content="Failed to fetch appointment filters!" />
	}

	// Wait before rendering
	if (!core || !availableFilters) return <LoadingSpinner size={loadingSpinnerIconSize} className="mt-4" />

	return (
		<FilteredDatePicker
			{...props}
			id={HTMLElementIdentifiers.Date}
			core={core}
			availableFilters={availableFilters}
			sideBySide={isMediumWidth}
		/>
	)
}

export default AvailableAppointmentsPage
