import type { PositiveNumber, Range } from "~/types/helpers/range"

/**
 * A callback that runs when a date is chosen.
 * @param {Date} date The chosen date.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.1.0
 */
//export type OnDateChooseCallback = (date: Date) => void

/**
 * A callback that runs when the month changes.
 * @param {Month} index The index of the new month.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.5.0
 */
export type OnMonthChangeCallback = (index: Month) => void

/**
 * A callback that runs when the year changes.
 * @param {number} year The new year.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.5.0
 */
export type OnYearChangeCallback = (year: number) => void

/**
 * A callback for deciding if a date is available.
 * @param {Date} date The date to check.
 * @returns {boolean} Whether the date is available.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.1.0
 */
export type IsDateAvailableCallback = (date: Date) => boolean

/**
 * The short names of each day of the week.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.5.0
 */
export type ShortDayNames = "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat" | "Sun"

/**
 * Clamped range representing the days throughout a month.
 * This does not take into account which month it is, so the maximum is always 31.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.5.0
 */
export type Day = Range<1, 32>

/**
 * Clamped range for the indexes of the months throughout a year.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.5.0
 */
export type Month = Range<0, 12>

/**
 * Always positive number to represent a year.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.5.0
 */
export type Year<Integer extends number> = PositiveNumber<Integer>

/**
 * Ensures the number is a day of the month.
 * @param {number} day The day of the month.
 * @example if (isDay(16)) {...
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.5.0
 */
export const isDay = (day?: number | null): day is Day =>
	day !== undefined && day !== null && !isNaN(day) && day >= 1 && day <= 31

/**
 * Ensures the number is a month of the year.
 * @param {number} month The month of the year.
 * @example if (isMonth(1)) {...
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.5.0
 */
export const isMonth = (month?: number | null): month is Month =>
	month !== undefined && month !== null && !isNaN(month) && month >= 0 && month <= 11

/**
 * Ensures the number is a year.
 * @param {number} day The year.
 * @example if (isYear(2024)) {...
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.5.0
 */
export const isYear = <Integer extends number>(year?: number | null): year is Year<Integer> =>
	year !== undefined && year !== null && !isNaN(year) && year >= 0
