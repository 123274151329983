import { useLocation } from "react-router-dom"

import { InflatedAppointment } from "~/classes/appointment"
import type { NavigationState } from "~/types/router/navigation"

/**
 * React hook to inflate the appointment from the navigation state.
 * @returns {InflatedAppointment | null} The appointment from the navigation state, or null.
 * @example const appointment = useAppointmentNavigationState()
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.5.0
 */
export const useAppointmentNavigationState = (): InflatedAppointment | null => {
	const location = useLocation()
	const state = location.state as NavigationState | null

	// Don't bother if we don't have a state
	if (state === null) return null

	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	if (state.inflatedAppointmentJSON === undefined) {
		console.warn("State was passed but is missing the inflated appointment?!")
		return null
	}

	return InflatedAppointment.fromJSON(state.inflatedAppointmentJSON)
}
