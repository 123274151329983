import { createSlice, type PayloadAction } from "@reduxjs/toolkit"

import type { AutoCompletionSuggestion } from "~/api/get-address/types/models/autoComplete"
import type { NearestAddress } from "~/api/get-address/types/models/nearest"
import { getPersistentJSON, getPersistentValue, PersistentKeys } from "~/helpers/localStorage"

/**
 * Structure of the data from the onboarding name stage.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export interface Name {
	first: string
	last: string
}

/**
 * Structure of the data from the onboarding address stage.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export interface PartialAddress {
	houseNumber: string | null // e.g., 11A
	postCode: string // e.g., EX4 3LS
}

/**
 * Structure of the data from the onboarding address stage.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export interface ManualAddress extends PartialAddress {
	firstLine: string // e.g., 11A Gandy Street
	secondLine: string | null
	city: string // e.g., Exeter
	county: string // e.g., Devon
}

/**
 * Structure of the data from the onboarding association stage.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export interface Association {
	id: number
	name: string
}

/**
 * Structure of the data from the onboarding agreements stage.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export interface Agreements {
	contact: boolean
	reminders: boolean
	surveys: boolean
	termsOfService: boolean
}

/**
 * Structure of the onboarding slice.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export interface SliceStructure {
	phoneNumber?: string
	name?: Name
	dateOfBirth?: string // ISO 8601
	emailAddress?: string
	address?: {
		partial?: PartialAddress
		manual?: ManualAddress
		suggestions?: AutoCompletionSuggestion[] | NearestAddress[]
	}
	association?: Association
	agreements?: Agreements
}

/**
 * Redux slice for the onboarding process.
 * This is initially populated by values from local storage.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const slice = createSlice({
	name: "onboarding",
	initialState: {
		phoneNumber: getPersistentValue(PersistentKeys.OnboardingPhoneNumber) ?? undefined,
		name: getPersistentJSON<Name>(PersistentKeys.OnboardingName) ?? undefined,
		dateOfBirth: getPersistentValue(PersistentKeys.OnboardingDateOfBirth) ?? undefined,
		emailAddress: getPersistentValue(PersistentKeys.OnboardingEmailAddress) ?? undefined,
		address: {
			partial: getPersistentJSON<PartialAddress>(PersistentKeys.OnboardingPartialAddress) ?? undefined,
			manual: getPersistentJSON<ManualAddress>(PersistentKeys.OnboardingManualAddress) ?? undefined,
			suggestions: []
		},
		association: getPersistentJSON<Association>(PersistentKeys.OnboardingAssociation) ?? undefined,
		agreements: getPersistentJSON<Agreements>(PersistentKeys.OnboardingAgreements) ?? undefined
	} as SliceStructure,
	reducers: {
		update: (state, action: PayloadAction<SliceStructure>): SliceStructure => ({
			...state,
			...action.payload,
			phoneNumber: action.payload.phoneNumber ?? state.phoneNumber,
			name: action.payload.name ?? state.name,
			dateOfBirth: action.payload.dateOfBirth ?? state.dateOfBirth,
			emailAddress: action.payload.emailAddress ?? state.emailAddress,
			address: {
				...state.address,
				...action.payload.address,
				partial: action.payload.address?.partial ?? state.address?.partial,
				manual: action.payload.address?.manual ?? state.address?.manual,
				suggestions: action.payload.address?.suggestions ?? state.address?.suggestions
			},
			association: action.payload.association ?? state.association,
			agreements: action.payload.agreements ?? state.agreements
		})
	}
})

/**
 * Actions for updating the Redux store.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const { update } = slice.actions

export default slice.reducer
