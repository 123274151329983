/**
 * Represents an option consumed & returned by the API.
 * @property {number} id The option's unique identifier.
 * @property {string} name The option's name.
 * @see https://dev.orthoandphysio.com/data
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export interface GenericOption {
	id: number
	name?: string | null
}

/**
 * Converts an object to an array of generic options.
 * @param {Record<string, string>} object The object to convert.
 * @returns {GenericOption[]} The array of generic options.
 * @example const options = toGenericOptions({ "1": "Option 1", "2": "Option 2" })
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.5.0
 */
export const toGenericOptions = (object: Record<string, string>): GenericOption[] =>
	Array.from(Object.entries(object)).map(
		([key, value]) =>
			({
				id: parseInt(key, 10),
				name: value
			}) as GenericOption
	)

/**
 * Converts an object to a single generic option.
 * This will ignore all but the first key-value pair.
 * @param {Record<string, string>} object The object to convert.
 * @returns {GenericOption} The generic option.
 * @example const option = toGenericOption({ "1": "Option 1" })
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.5.0
 */
export const toGenericOption = (object: Record<string, string>): GenericOption | undefined =>
	toGenericOptions(object)[0]
