import { useFetchCoreQuery } from "~/api/osteo-physio/client"
import { isAPIError, isHTTPError } from "~/api/osteo-physio/types/error"
import ErrorMessage from "~/components/errorMessage"
import LoadingSpinner, { loadingSpinnerIconSize } from "~/components/loadingSpinner"
import Page from "~/components/standard/layout/page"
import Section from "~/components/standard/layout/section"
import type { ComponentProps } from "~/types/components/props"

/**
 * The settings page.
 * This page handles adjusting preferences.
 * @example <SettingsPage />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const SettingsPage = ({ ...props }: ComponentProps<HTMLDivElement>): JSX.Element => {
	const { data: core, error } = useFetchCoreQuery({})

	if (error) {
		if (isAPIError(error))
			return <ErrorMessage title="API Error" content={error.data.SystemErrorMessage?.toString()} />

		if (isHTTPError(error)) return <ErrorMessage title="HTTP Error" content={error.status.toString()} />

		return <ErrorMessage title="Unknown Error" content="Failed to fetch core data!" />
	}

	if (!core) return <LoadingSpinner size={loadingSpinnerIconSize} />

	return (
		<Page {...props}>
			<Section title="Settings">
				<ErrorMessage title="To-Do" content="Settings view needs to be implemented!" />
			</Section>
		</Page>
	)
}

export default SettingsPage
