import { Transition } from "@headlessui/react"
import { useCallback, useState } from "react"

import ErrorMessage from "~/components/errorMessage"
import { longestTransitionStyles } from "~/config/transitions"
import { FlowContextProvider } from "~/contexts/flow"
import { clamp } from "~/helpers/clamp"
import { FlowDirection } from "~/types/components/wrappers/flow"

// The time to wait between rendering stages (we must wait for the transition to complete, otherwise the translation won't apply)
const waitDurationBetweenStages = 50 // Milliseconds

/**
 * A wrapper for creating animated stages on a page (e.g., onboarding process).
 * @param {number} initialStage The default stage to be on.
 * @param {number} stages A map of stage numbers to React components.
 * @example <StageFlow stages={{ 0: ..., ... }} />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const StageFlow = ({
	initialStage = 0,
	stages
}: {
	initialStage?: number
	stages: Record<number, JSX.Element>
}): JSX.Element => {
	const [activeStage, setActiveStage] = useState<number>(initialStage)
	const [previousStage, setPreviousStage] = useState<number | null>(null)
	const [pendingStage, setPendingStage] = useState<number>(initialStage)

	const [flowDirection, setFlowDirection] = useState<FlowDirection>(FlowDirection.Forwards)

	const minimumStage = Math.min(...Object.keys(stages).map(Number))
	const maximumStage = Math.max(...Object.keys(stages).map(Number))

	const transferToStage = useCallback(
		(stage: number) => {
			// Clamp to minimum & maximum stages
			stage = clamp(stage, minimumStage, maximumStage)

			// Do nothing if this stage isn't a number
			if (isNaN(stage)) {
				console.error(`Stage ${stage.toString()} is invalid!`)
				return
			}

			// Do nothing if we're already on this stage
			if (stage === activeStage) {
				console.error(`Not transitioning to already active stage ${stage.toString()}!`)
				return
			}

			// Do nothing if there's no React component for this stage
			if (!stages[stage]) {
				console.error(`No component for stage ${stage.toString()}?!`)
				return
			}

			console.info(`Transferring from stage ${activeStage.toString()} to stage ${stage.toString()}...`)

			// Animate backwards if the stage is less than the current stage, otherwise animate forwards
			setFlowDirection(stage < activeStage ? FlowDirection.Backwards : FlowDirection.Forwards)

			// Begin transition to the new stage
			setPendingStage(stage)
		},
		[minimumStage, maximumStage, activeStage, stages]
	)

	const onAfterLeave = useCallback(() => {
		// Timeout required to give transition component enough time to switch from leaveTo to enterFrom classes
		setTimeout(() => {
			// Remember the history, but only if its from before the current stage.
			// We don't want to remember future stages & end up in a loop!
			if (previousStage === null) {
				setPreviousStage(pendingStage)
			} else {
				if (pendingStage > activeStage) setPreviousStage(activeStage)
			}

			setActiveStage(pendingStage)
		}, waitDurationBetweenStages)
	}, [setActiveStage, setPreviousStage, activeStage, pendingStage, previousStage])

	return (
		<FlowContextProvider
			value={{
				currentStage: activeStage,
				previousStage: previousStage,
				transfer: transferToStage
			}}>
			<Transition
				appear={false}
				unmount={false}
				show={activeStage === pendingStage}
				className="flex flex-grow px-12"
				enter={`${longestTransitionStyles} transform`}
				enterFrom={`opacity-0 ${flowDirection === FlowDirection.Forwards ? "translate-x-32" : "-translate-x-32"}`}
				enterTo="opacity-100 translate-x-0"
				leave={`${longestTransitionStyles} transform`}
				leaveFrom="opacity-100 translate-x-0"
				leaveTo={`opacity-0 ${flowDirection === FlowDirection.Forwards ? "-translate-x-32" : "translate-x-32"}`}
				afterLeave={onAfterLeave}>
				{stages[activeStage] ?? (
					<ErrorMessage title="No Component" content={`Unknown stage ${initialStage.toString()}!`} />
				)}
			</Transition>
		</FlowContextProvider>
	)
}

export default StageFlow
