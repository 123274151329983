import { useCallback } from "react"
import { useNavigate } from "react-router-dom"

import { useFetchCoreQuery } from "~/api/osteo-physio/client"
import { isAPIError, isHTTPError } from "~/api/osteo-physio/types/error"
import { InflatedAppointment } from "~/classes/appointment"
import BookedAppointmentCard from "~/components/appointments/booked"
import Button from "~/components/controls/button"
import Image from "~/components/controls/image"
import ErrorMessage from "~/components/errorMessage"
import LoadingSpinner, { loadingSpinnerIconSize } from "~/components/loadingSpinner"
import Page from "~/components/standard/layout/page"
import Section from "~/components/standard/layout/section"
import Paragraph from "~/components/standard/text/paragraph"
import StickyButton from "~/components/wrappers/inputs/stickyButton"
import { tidyUpBookedAppointment } from "~/helpers/tidy-ups/appointment/booked"
import { Routes } from "~/router"
import type { OnClickCallback } from "~/types/components/controls/button"
import { ButtonThemes } from "~/types/components/controls/button"
import type { ComponentProps } from "~/types/components/props"

/**
 * The home page.
 * This page handles showing future/past appointments & actions to book new appointments.
 * @example <HomePage />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const HomePage = ({ ...props }: ComponentProps<HTMLDivElement>): JSX.Element => {
	const navigate = useNavigate()

	const {
		currentData: core,
		error,
		refetch
	} = useFetchCoreQuery(
		{},
		{
			refetchOnMountOrArgChange: true // Always refresh in case a new appointment was booked
		}
	)

	// Runs when the book another appointment button is clicked...
	const onBookAppointmentClick = useCallback<OnClickCallback>(() => {
		navigate(Routes.BookAppointment)
	}, [navigate])

	/* eslint-disable promise/prefer-await-to-then */
	// Runs when the refreshing...
	const onRefresh = useCallback(() => {
		refetch().catch((error: unknown) => {
			console.warn(`Failed to refresh core! (${error?.toString() ?? "Unknown error"})`)
		})
	}, [refetch])

	// Show fetch errors
	if (error) {
		if (isAPIError(error))
			return <ErrorMessage title="API Error" content={error.data.SystemErrorMessage?.toString()} />

		if (isHTTPError(error)) return <ErrorMessage title="HTTP Error" content={error.status.toString()} />

		return <ErrorMessage title="Unknown Error" content="Failed to fetch core data!" />
	}

	// Wait for the core application data...
	if (!core) return <LoadingSpinner size={loadingSpinnerIconSize} />

	// Tidy up & inflate the appointments
	const pastAppointments = core.past_appointments
		.map(tidyUpBookedAppointment)
		.map(appointment => InflatedAppointment.inflateOrNull({ appointment, core, discardWithoutFilters: true }))
		.filter(appointment => appointment?.isPast() === true) as InflatedAppointment[]
	const futureAppointments = core.future_appointments
		.map(tidyUpBookedAppointment)
		.map(appointment => InflatedAppointment.inflateOrNull({ appointment, core, discardWithoutFilters: true }))
		.filter(appointment => appointment?.isFuture() === true) as InflatedAppointment[]

	return (
		<Page {...props} className={`justify-between ${props.className ?? ""}`.trimEnd()}>
			<div className="flex flex-col gap-y-4">
				{futureAppointments.length >= 1 && (
					<FutureAppointments appointments={futureAppointments} refreshHome={onRefresh} />
				)}
				{pastAppointments.length >= 1 && (
					<PastAppointments appointments={pastAppointments} refreshHome={onRefresh} />
				)}
				{futureAppointments.length <= 0 && pastAppointments.length <= 0 && <BookAppointmentActions />}
			</div>

			{(futureAppointments.length >= 1 || pastAppointments.length >= 1) && (
				<StickyButton label="Book another appointment" onClick={onBookAppointmentClick} wide={true} />
			)}
		</Page>
	)
}

/**
 * Renders the list of future appointments.
 * @example <FutureAppointments appointments={[...]} />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const FutureAppointments = ({
	appointments,
	refreshHome,

	...props
}: ComponentProps<
	HTMLDivElement,
	{
		appointments: InflatedAppointment[]
		refreshHome: () => void
	}
>): JSX.Element => (
	<Section {...props} innerClassName="text-text" title="Upcoming Appointments" showBackButton={false}>
		{appointments.map(appointment => (
			<BookedAppointmentCard key={appointment.id} appointment={appointment} refreshHome={refreshHome} />
		))}
	</Section>
)

/**
 * Renders the list of previous appointments.
 * @example <PastAppointments appointments={[...]} />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const PastAppointments = ({
	appointments,
	refreshHome,

	...props
}: ComponentProps<
	HTMLDivElement,
	{
		appointments: InflatedAppointment[]
		refreshHome: () => void
	}
>): JSX.Element => (
	<Section {...props} innerClassName="text-text" title="Previous Appointments" showBackButton={false}>
		{appointments.map(appointment => (
			<BookedAppointmentCard key={appointment.id} appointment={appointment} refreshHome={refreshHome} />
		))}
	</Section>
)

/**
 * The action buttons for booking new appointments.
 * This should only be shown when there are no future/past appointments.
 * @example <BookAppointmentActions />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const BookAppointmentActions = ({ ...props }: ComponentProps<HTMLDivElement>): JSX.Element => {
	const navigate = useNavigate()

	const onClick = useCallback<OnClickCallback>(() => {
		navigate(Routes.BookAppointment)
	}, [navigate])

	return (
		<Section {...props}>
			<Button
				wide={true}
				tooltip="Book a new appointment."
				theme={ButtonThemes.WhiteOnPrimary}
				className="min-h-[130px] flex-col gap-y-2"
				onClick={onClick}>
				<Image
					sourceUrl="/images/bird.png"
					screenReaderDescription="Osteo & Physio swift."
					className="aspect-square"
					width={72}
					height={72}
				/>
				<Paragraph className="font-bold text-white">Book appointment</Paragraph>
			</Button>
		</Section>
	)
}

export default HomePage
