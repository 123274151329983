import { CalendarDaysIcon } from "@heroicons/react/24/solid"
import type { ChangeEventHandler, FormEventHandler } from "react"
import { useCallback, useRef, useState } from "react"

import type { InputProps } from "~/components/controls/inputs/input"
import Input from "~/components/controls/inputs/input"
import { longerColorTransitionStyles } from "~/config/transitions"
import { inputDisabledColorStyles, inputIconSize } from "~/constants/components/input"
import { isDate, prettyDate } from "~/helpers/date"
import type { OnClickCallback } from "~/types/components/controls/button"
import type { ComponentProps } from "~/types/components/props"

/**
 * A pre-styled standard HTML date input.
 * This does not accept time.
 * @example <DateInput id="dateInput" />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const DateInput = ({
	initialValue,

	placeholder = new Date("2024-01-16"),
	tooltip = "Please enter a date.",

	startIcon,

	pickerTopOffset = 0,
	pickerLeftOffset = 0,

	...props
}: ComponentProps<
	HTMLInputElement,
	Omit<InputProps, "type" | "minimumLength" | "maximumLength" | "initialValue" | "placeholder"> & {
		initialValue?: Date

		placeholder?: Date

		pickerTopOffset?: number
		pickerLeftOffset?: number
	}
>): JSX.Element => {
	const dateInputReference = useRef<HTMLInputElement>(null)

	const [value, setValue] = useState<string>(prettyDate(initialValue) ?? "")

	// Runs when the box is clicked...
	const onClick = useCallback<OnClickCallback>(event => {
		event.preventDefault()

		// Show the picker from the invisible input
		dateInputReference.current?.showPicker()
	}, [])

	// Runs when the box text changes...
	const onInput = useCallback<FormEventHandler<HTMLInputElement>>(event => {
		event.preventDefault()

		// Update internal state
		setValue(event.currentTarget.value)
	}, [])

	// Runs when the date picker changes...
	const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(event => {
		event.preventDefault()

		// Update the visible input with the date selected from the picker
		const date = event.currentTarget.valueAsDate
		if (isDate(date)) setValue(prettyDate(date) ?? "")
	}, [])

	return (
		<div key={initialValue?.toISOString() ?? value}>
			<Input
				{...props}
				type="date"
				value={value}
				tooltip={tooltip}
				placeholder={prettyDate(placeholder) ?? undefined}
				regularExpression="^\d{1,2}\s*/\s*\d{1,2}\s*/\s*\d{2,4}$"
				onClick={onClick}
				onInput={onInput}
				startIcon={isLoading =>
					/* eslint-disable indent */
					typeof startIcon === "function"
						? startIcon(isLoading)
						: (startIcon ?? (
								<CalendarDaysIcon
									className={`${longerColorTransitionStyles} ${isLoading ? inputDisabledColorStyles : "fill-primary"}`}
									width={inputIconSize}
									height={inputIconSize}
								/>
							))
				}
			/>
			<input
				ref={dateInputReference}
				type="date"
				title={props.title}
				placeholder={prettyDate(placeholder) ?? undefined}
				className="invisible absolute"
				style={{
					marginTop: pickerTopOffset,
					marginLeft: pickerLeftOffset
				}}
				onChange={onChange}
			/>
		</div>
	)
}

export default DateInput
