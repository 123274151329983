/**
 * Default size for input icons.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const inputIconSize = 28

/**
 * Default color for disabled inputs.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const inputDisabledColorStyles = "fill-gray-500"

/**
 * The size of link icons.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const inputLinkIconSize = 16

/**
 * The size of the input warning icon.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const inputWarningIconSize = 32
