import { MapPinIcon } from "@heroicons/react/24/solid"
import { useCallback, useEffect } from "react"

import { useFetchAssociationsQuery } from "~/api/osteo-physio/client"
import Form from "~/components/controls/forms/form"
import DropDownInput from "~/components/controls/inputs/dropDown"
import BackOrContinueButtons from "~/components/onboarding/backOrContinueButtons"
import OfflineWarning from "~/components/onboarding/offlineWarning"
import Paragraphs from "~/components/onboarding/paragraphs"
import StageWrapper from "~/components/onboarding/wrapper"
import Paragraph from "~/components/standard/text/paragraph"
import { longerColorTransitionStyles } from "~/config/transitions"
import { dropDownIconStyles } from "~/constants/components/dropDown"
import { inputDisabledColorStyles, inputIconSize } from "~/constants/components/input"
import { useFlowContext } from "~/contexts/flow"
import { orNull } from "~/helpers/null"
import { useFormDispatch } from "~/hooks/useForm"
import { useIsOnline } from "~/hooks/useIsOnline"
import { useOnboardingDispatch, useOnboardingSelector } from "~/hooks/useOnboarding"
import type { OnFormSubmitCallback } from "~/types/components/controls/form"
import { OnboardingStage } from "~/types/components/pages/onboarding"
import type { ComponentProps } from "~/types/components/props"

enum HTMLElementIdentifiers {
	AssociationForm = "registerAssociation",
	AssociationSelection = "selectAssociation"
}

/**
 * The fifth stage of the onboarding process.
 * This stage collects the user's association for registering a new account.
 * This should be wrapped in a <StageFlow /> component!
 * @example <AssociationStage />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const AssociationStage = ({ ...props }: ComponentProps<HTMLDivElement>): JSX.Element => {
	// Form
	const { showWarning, updateLoading } = useFormDispatch(HTMLElementIdentifiers.AssociationForm)

	// Onboarding
	const { transfer } = useFlowContext()
	const { setAssociation, clearAssociation } = useOnboardingDispatch()
	const { manualAddress: address, association } = useOnboardingSelector()

	// Internet connectivity check
	const [isOnline] = useIsOnline()

	// API
	const { data: associations } = useFetchAssociationsQuery(
		{},
		{
			skip: !isOnline
		}
	)

	// Default to the user's county if it matches an association
	const matchingAssociation = associations?.find(
		association => orNull(association.name)?.trim().toLowerCase() === address?.county.toLowerCase()
	)

	// Loading while we wait for the associations to be fetched
	useEffect(() => {
		updateLoading(!associations)
	}, [updateLoading, associations])

	// Runs when the form is submitted...
	const onSubmitted = useCallback<OnFormSubmitCallback>(
		(values): void => {
			if (!associations) return

			const chosenAssociationId = values.get(HTMLElementIdentifiers.AssociationSelection) as number | null

			// Shouldn't happen, but check just in case to please TypeScript
			if (chosenAssociationId === null) {
				console.warn("No association was entered, even though the input is required?!")
				showWarning(HTMLElementIdentifiers.AssociationSelection, "Choose an association!")
				clearAssociation()
				return
			}

			const chosenAssociation = associations.find(association => association.id === chosenAssociationId)
			if (!chosenAssociation) {
				console.warn("The chosen association was not found in the list of associations?!")
				showWarning(HTMLElementIdentifiers.AssociationSelection, "Unknown association! Please choose another.")
				clearAssociation()
				return
			}

			const name = orNull(chosenAssociation.name)?.trim() ?? null
			if (name === null) {
				console.warn("The chosen association has no name?!")
				showWarning(HTMLElementIdentifiers.AssociationSelection, "Unknown association! Please choose another.")
				clearAssociation()
				return
			}

			setAssociation({ ...chosenAssociation, name })
			transfer(OnboardingStage.Agreement)
		},
		[showWarning, clearAssociation, setAssociation, transfer, associations]
	)

	// Fallback while the associations load
	const dropDownEntries: Record<string, string> = Object.fromEntries(
		(associations
			?.map(association => [association.id.toString(), orNull(association.name)?.trim() ?? null])
			.filter(([, name]) => name !== null) as [string, string][] | undefined) ?? [
			["0", "Loading list of associations..."]
		]
	)

	return (
		<StageWrapper {...props}>
			<Paragraphs>
				<Paragraph>Please select your association below to continue creating your account.</Paragraph>
			</Paragraphs>
			<OfflineWarning visible={!isOnline} />
			<Form id={HTMLElementIdentifiers.AssociationForm} onSubmit={onSubmitted}>
				<DropDownInput
					key={Object.keys(dropDownEntries).join("-")} // Force a reload when the associations are populated, makes the initial value work
					id={HTMLElementIdentifiers.AssociationSelection}
					label="Association"
					initialValue={
						association?.id !== undefined
							? association.id.toString()
							: matchingAssociation?.id !== undefined
								? matchingAssociation.id.toString()
								: undefined
					}
					isFocused={true}
					isDisabled={!isOnline}
					choices={dropDownEntries}
					startIcon={isLoading => (
						<MapPinIcon
							className={`${longerColorTransitionStyles} ${dropDownIconStyles} ${isLoading ? inputDisabledColorStyles : "fill-primary"}`}
							width={inputIconSize}
							height={inputIconSize}
						/>
					)}
				/>

				<BackOrContinueButtons previousStage={OnboardingStage.PartialAddress} isDisabled={!isOnline} />
			</Form>
		</StageWrapper>
	)
}

export default AssociationStage
