import { useCallback } from "react"
import { useFormContext } from "~/contexts/form"

import type { Form } from "~/state/slices/userInterface"
import { slice } from "~/state/slices/userInterface"
import { useReduxDispatch, useReduxSelector } from "~/state/store"
import type { EmptyCallback } from "~/types/components/callbacks"

/**
 * Callback for showing a warning message on an individual input within a form.
 * @param {string} inputId The unique HTML identifier of the input.
 * @param {string} message The warning message to show.
 * @see useFormDispatch()
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export type ShowFormWarningCallback = (inputId: string, message: string) => void

/**
 * Callback for updating the loading state on a form.
 * @param {boolean} isLoading Whether the form should be in a loading state.
 * @see useFormDispatch()
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export type UpdateFormLoadingCallback = (isLoading: boolean) => void

/**
 * React hook for updating the loading state on a form & showing/clearing the warning messages for inputs within a form.
 * @param {string | undefined} id The ID of the form. If not provided, the form context will be used.
 * @returns {object} Functions for updating loading state & showing/clearing input warning messages.
 * @example const { clearWarnings, updateLoading } = useFormDispatch()
 * @see useFormSelector()
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const useFormDispatch = (
	id?: string
): {
	showWarning: ShowFormWarningCallback
	clearWarnings: EmptyCallback
	updateLoading: UpdateFormLoadingCallback
} => {
	const dispatch = useReduxDispatch()

	const { id: formContextId } = useFormContext()
	id ??= formContextId ?? undefined

	// Shows a warning message for a specific input within a form
	const showWarning = useCallback(
		(inputId: string, message: string) => {
			if (id === undefined) {
				console.warn("Cannot show a warning message without a form ID!")
				return
			}

			// NOTE: As a side effect, this clears loading state too. No need to call updateLoading() after showWarning()!
			dispatch(
				slice.actions.updateForm({
					[id]: {
						inputs: {
							[inputId]: {
								warningMessage: message
							}
						}
					}
				})
			)
		},
		[dispatch, id]
	)

	// Clears the warning message for a all inputs within the form
	const clearWarnings = useCallback(() => {
		if (id === undefined) {
			console.warn("Cannot clear warning messages without a form ID!")
			return
		}

		dispatch(
			slice.actions.updateForm({
				[id]: {
					inputs: null
				}
			})
		)
	}, [dispatch, id])

	// Updates the loading state of the form
	const updateLoading = useCallback(
		(isLoading: boolean) => {
			if (id === undefined) {
				console.warn("Cannot update loading without a form ID!")
				return
			}

			dispatch(
				slice.actions.updateForm({
					[id]: {
						isLoading
					}
				})
			)
		},
		[dispatch, id]
	)

	return { showWarning, clearWarnings, updateLoading }
}

/**
 * React hook for getting the loading state of a form.
 * @param {string | undefined} id The ID of the form. If not provided, the form context will be used.
 * @returns {Form} Whether the form is in a loading state.
 * @example const { isLoading } = useFormSelector()
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const useFormSelector = (id?: string): Omit<Form, "inputs"> & { isLoading: boolean } => {
	const { id: formContextId } = useFormContext()
	id ??= formContextId ?? undefined

	// We can't target just the form as then we'll receive updates for all its inputs too :(
	const isLoading = useReduxSelector(state => state.userInterface.forms[id ?? ""]?.isLoading)

	return {
		isLoading: isLoading ?? false
	}
}
