import type { ChangeEventHandler, FormEvent } from "react"
import { useCallback, useEffect, useRef } from "react"

import type { InputProps } from "~/components/controls/inputs/input"
import Paragraph from "~/components/standard/text/paragraph"
import FadeIn from "~/components/wrappers/fadeIn"
import { longerColorTransitionStyles } from "~/config/transitions"
import { useFormContext } from "~/contexts/form"
import { useFormSelector } from "~/hooks/useForm"
import { useInputDispatch, useInputSelector } from "~/hooks/useInput"
import type { ComponentProps } from "~/types/components/props"

/**
 * A pre-styled standard HTML textarea (multiple line input).
 * @example <MultiLineInput id="reasonInput" />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.1.0
 */
const MultiLineInput = ({
	id,

	label,
	tooltip = "Please enter text.",

	initialValue,
	placeholder,

	lines = 10,

	minimumLength = 1,
	maximumLength = 1000,

	isDisabled = false,
	isReadOnly = false,
	isRequired = true,
	isFocused = false,
	isLoading,

	missingValueWarningMessage,

	...props
}: ComponentProps<
	HTMLTextAreaElement,
	Pick<
		InputProps,
		| "id"
		| "label"
		| "tooltip"
		| "initialValue"
		| "placeholder"
		| "minimumLength"
		| "maximumLength"
		| "startIcon"
		| "isDisabled"
		| "isReadOnly"
		| "isRequired"
		| "isFocused"
		| "isLoading"
		| "missingValueWarningMessage"
	> & {
		lines?: number
	}
>): JSX.Element => {
	// Form
	const { id: formId } = useFormContext()
	const { isLoading: isFormLoading } = useFormSelector()
	isLoading ??= isFormLoading

	// Input
	const { showWarning, clearWarning } = useInputDispatch(id)
	const { warningMessage } = useInputSelector(id)

	const textAreaReference = useRef<HTMLTextAreaElement | null>(null)

	// Runs when the parent form is submitted and this input is invalid...
	const onInvalid = useCallback(
		(event: FormEvent<HTMLTextAreaElement>): void => {
			event.preventDefault() // Prevent showing the browser's default validation message

			// If there's already a warning message, don't show another one
			if (warningMessage !== null) return

			console.warn(`No value entered for input '${id}' in form '${formId?.toString() ?? "Unknown form"}'!`)
			showWarning(missingValueWarningMessage ?? "Enter a value!")
		},
		[showWarning, formId, warningMessage, id, missingValueWarningMessage]
	)

	// Clear warnings when the value changes...
	const onChange = useCallback<ChangeEventHandler<HTMLTextAreaElement>>(() => {
		if (warningMessage !== null) clearWarning()
	}, [clearWarning, warningMessage])

	// Focus after loading...
	useEffect(() => {
		if (isFocused && textAreaReference.current && textAreaReference.current.value === "")
			textAreaReference.current.focus()
	})

	return (
		<div className="flex flex-col gap-y-2">
			<div className="flex flex-row justify-between">
				{label !== undefined && (
					<label htmlFor={id} className="text-sm font-bold text-text">
						{label}
						{isRequired && <span className="ms-1 text-red-600">*</span>}
					</label>
				)}
			</div>
			<div
				key={initialValue}
				className={`${longerColorTransitionStyles} flex w-full flex-row gap-x-1 rounded-lg border px-4 py-3 text-sm shadow-sm ${warningMessage !== null ? "border-warning" : "border-controlBorder"} ${isDisabled || isLoading ? "bg-gray-300 hover:cursor-not-allowed" : "bg-white"}`}>
				<textarea
					ref={textAreaReference}
					{...props}
					id={id}
					name={id}
					aria-label={label}
					title={tooltip}
					placeholder={placeholder}
					aria-placeholder={placeholder}
					defaultValue={initialValue}
					disabled={isDisabled || isLoading}
					readOnly={isReadOnly}
					required={isRequired}
					autoFocus={isFocused}
					className={`${longerColorTransitionStyles} flex flex-grow resize-none bg-transparent`}
					minLength={minimumLength}
					maxLength={maximumLength}
					rows={lines}
					onInvalid={onInvalid}
					onChange={onChange}
				/>
			</div>
			{warningMessage !== null && (
				<FadeIn>
					<Paragraph className="text-center text-xs font-bold text-warning">{warningMessage}</Paragraph>
				</FadeIn>
			)}
		</div>
	)
}

export default MultiLineInput
