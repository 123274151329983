import type { ComponentProps } from "~/types/components/props"

/**
 * A pre-styled standard HTML divider.
 * @example <Divider />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const Divider = ({ ...props }: ComponentProps<HTMLHRElement>): JSX.Element => (
	<hr
		{...props}
		className={`border-1 h-0 w-full self-center border-dividerOnSecondary ${props.className ?? ""}`.trimEnd()}
	/>
)

export default Divider
