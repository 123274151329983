/**
 * Splits a date into an array of numbers that can be used to create an ICS calendar event.
 * @example const [year, month, day, hour, minute] = toCalendarDateTime(new Date())
 * @see https://www.npmjs.com/package/ics#example-usage
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.1.0
 */
export const toCalendarDateTime = (date: Date): [number, number, number, number, number] => [
	date.getFullYear(),
	date.getMonth() + 1,
	date.getDate(),
	date.getHours(),
	date.getMinutes()
]

/**
 * Converts a date to a YYYYMMDDTHHmmSS string for use in Google Calendar URLs.
 * @example const calendarDateTime = toGoogleCalendarDateTime(new Date())
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.1.0
 */
export const toGoogleCalendarDateTime = (date: Date): string => date.toISOString().replace(/-|:|\.\d+/g, "")
