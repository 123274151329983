/**
 * The stages in the onboarding process.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export enum OnboardingStage {
	// Login
	PhoneNumber,

	// Registration
	Name,
	DateOfBirth,
	EmailAddress,
	PartialAddress,
	SuggestedAddresses,
	ManualAddress,
	Association,
	Agreement,

	// Both
	VerifyCode
}
