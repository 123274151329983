/**
 * Ensures a date is valid.
 * @param {Date | null | undefined} date The date to check.
 * @returns {boolean} Whether the date is valid.
 * @example const isValid = isDateValid(new Date()) // true
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const isDate = (date?: Date | null): date is Date =>
	date !== undefined && date !== null && date.toString() !== "Invalid Date" && !isNaN(date.valueOf())

/**
 * Prettifies a date to DD / MM / YYYY format.
 * @param {Date | null} date The date to prettify.
 * @returns {string | null} The prettified date, or null if the date is invalid.
 * @example const pretty = prettyDate(new Date()) // "16 / 01 / 2024"
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const prettyDate = (date?: Date | null): string | null =>
	isDate(date)
		? `${date.getDate().toString()} / ${(date.getMonth() + 1).toString()} / ${date.getFullYear().toString()}`
		: null

/**
 * Prettifies a date & time.
 * @param {Date} date The date to prettify.
 * @returns {string} The prettified date & time.
 * @example const pretty = prettyDateTime(new Date())
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const prettyDateTime = (date: Date): string =>
	`${date.toLocaleDateString("en-gb", {
		weekday: "long",
		year: "numeric",
		month: "long",
		day: "numeric"
	})}, ${date.toLocaleTimeString("en-gb", {
		timeZoneName: "short"
	})}`
