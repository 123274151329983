import { useCallback } from "react"

import type { AutoCompletionSuggestion } from "~/api/get-address/types/models/autoComplete"
import type { NearestAddress } from "~/api/get-address/types/models/nearest"
import { isDate } from "~/helpers/date"
import { PersistentKeys } from "~/helpers/localStorage"
import type { Agreements, Association, ManualAddress, Name, PartialAddress } from "~/state/slices/onboarding"
import { slice } from "~/state/slices/onboarding"
import { useReduxDispatch, useReduxSelector } from "~/state/store"

/**
 * React hook to update the onboarding state.
 * @returns {object} Functions to update the onboarding state.
 * @example const { setPhoneNumber, setEmailAddress } = useOnboardingDispatch()
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const useOnboardingDispatch = (): {
	setPhoneNumber: (phoneNumber: string) => void
	clearPhoneNumber: () => void

	setName: (name: Name) => void
	clearName: () => void

	setDateOfBirth: (dateOfBirth: Date) => void
	clearDateOfBirth: () => void

	setEmailAddress: (emailAddress: string) => void
	clearEmailAddress: () => void

	setPartialAddress: (address: PartialAddress) => void
	clearPartialAddress: () => void

	setManualAddress: (address: ManualAddress) => void
	clearManualAddress: () => void

	setSuggestedAddresses: (addresses: AutoCompletionSuggestion[] | NearestAddress[]) => void
	clearSuggestedAddresses: () => void

	setAssociation: (association: Association) => void
	clearAssociation: () => void

	setAgreements: (agreements: Agreements) => void
	clearAgreements: () => void
} => {
	const dispatch = useReduxDispatch()

	const setPhoneNumber = useCallback(
		(phoneNumber: string) => {
			localStorage.setItem(PersistentKeys.OnboardingPhoneNumber, phoneNumber)

			dispatch(
				slice.actions.update({
					phoneNumber: phoneNumber
				})
			)
		},
		[dispatch]
	)

	const clearPhoneNumber = useCallback(() => {
		localStorage.removeItem(PersistentKeys.OnboardingPhoneNumber)

		dispatch(
			slice.actions.update({
				phoneNumber: undefined
			})
		)
	}, [dispatch])

	const setName = useCallback(
		(name: Name) => {
			localStorage.setItem(PersistentKeys.OnboardingName, JSON.stringify(name))

			dispatch(
				slice.actions.update({
					name: name
				})
			)
		},
		[dispatch]
	)

	const clearName = useCallback(() => {
		localStorage.removeItem(PersistentKeys.OnboardingName)

		dispatch(
			slice.actions.update({
				name: undefined
			})
		)
	}, [dispatch])

	const setDateOfBirth = useCallback(
		(dateOfBirth: Date) => {
			localStorage.setItem(PersistentKeys.OnboardingDateOfBirth, dateOfBirth.toISOString())

			dispatch(
				slice.actions.update({
					dateOfBirth: dateOfBirth.toISOString()
				})
			)
		},
		[dispatch]
	)

	const clearDateOfBirth = useCallback(() => {
		localStorage.removeItem(PersistentKeys.OnboardingDateOfBirth)

		dispatch(
			slice.actions.update({
				dateOfBirth: undefined
			})
		)
	}, [dispatch])

	const setEmailAddress = useCallback(
		(emailAddress: string) => {
			localStorage.setItem(PersistentKeys.OnboardingEmailAddress, emailAddress)

			dispatch(
				slice.actions.update({
					emailAddress: emailAddress
				})
			)
		},
		[dispatch]
	)

	const clearEmailAddress = useCallback(() => {
		localStorage.removeItem(PersistentKeys.OnboardingEmailAddress)

		dispatch(
			slice.actions.update({
				emailAddress: undefined
			})
		)
	}, [dispatch])

	const setPartialAddress = useCallback(
		(address: PartialAddress) => {
			localStorage.setItem(PersistentKeys.OnboardingPartialAddress, JSON.stringify(address))

			dispatch(
				slice.actions.update({
					address: {
						partial: address
					}
				})
			)
		},
		[dispatch]
	)

	const clearPartialAddress = useCallback(() => {
		localStorage.removeItem(PersistentKeys.OnboardingPartialAddress)

		dispatch(
			slice.actions.update({
				address: {
					partial: undefined
				}
			})
		)
	}, [dispatch])

	const setManualAddress = useCallback(
		(address: ManualAddress) => {
			localStorage.setItem(PersistentKeys.OnboardingManualAddress, JSON.stringify(address))

			dispatch(
				slice.actions.update({
					address: {
						manual: address
					}
				})
			)
		},
		[dispatch]
	)

	const clearManualAddress = useCallback(() => {
		localStorage.removeItem(PersistentKeys.OnboardingManualAddress)

		dispatch(
			slice.actions.update({
				address: {
					manual: undefined
				}
			})
		)
	}, [dispatch])

	const setSuggestedAddresses = useCallback(
		(addresses: AutoCompletionSuggestion[] | NearestAddress[]) => {
			dispatch(
				slice.actions.update({
					address: {
						suggestions: addresses
					}
				})
			)
		},
		[dispatch]
	)

	const clearSuggestedAddresses = useCallback(() => {
		dispatch(
			slice.actions.update({
				address: {
					suggestions: []
				}
			})
		)
	}, [dispatch])

	const setAssociation = useCallback(
		(association: Association) => {
			localStorage.setItem(PersistentKeys.OnboardingAssociation, JSON.stringify(association))

			dispatch(
				slice.actions.update({
					association: association
				})
			)
		},
		[dispatch]
	)

	const clearAssociation = useCallback(() => {
		localStorage.removeItem(PersistentKeys.OnboardingAssociation)

		dispatch(
			slice.actions.update({
				association: undefined
			})
		)
	}, [dispatch])

	const setAgreements = useCallback(
		(agreements: Agreements) => {
			localStorage.setItem(PersistentKeys.OnboardingAgreements, JSON.stringify(agreements))

			dispatch(
				slice.actions.update({
					agreements: agreements
				})
			)
		},
		[dispatch]
	)

	const clearAgreements = useCallback(() => {
		localStorage.removeItem(PersistentKeys.OnboardingAgreements)

		dispatch(
			slice.actions.update({
				agreements: undefined
			})
		)
	}, [dispatch])

	return {
		setPhoneNumber,
		clearPhoneNumber,
		setName,
		clearName,
		setDateOfBirth,
		clearDateOfBirth,
		setEmailAddress,
		clearEmailAddress,
		setPartialAddress,
		clearPartialAddress,
		setManualAddress,
		clearManualAddress,
		setSuggestedAddresses,
		clearSuggestedAddresses,
		setAssociation,
		clearAssociation,
		setAgreements,
		clearAgreements
	}
}

/**
 * React hook to get the onboarding state.
 * @returns {object} Values from the onboarding state.
 * @example const { phoneNumber, emailAddress } = useOnboarding()
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const useOnboardingSelector = (): {
	phoneNumber: string | null
	name: Name | null
	dateOfBirth: Date | null
	emailAddress: string | null
	partialAddress: PartialAddress | null
	manualAddress: ManualAddress | null
	suggestedAddresses: AutoCompletionSuggestion[] | NearestAddress[]
	association: Association | null
	agreements: Agreements | null
} => {
	const reduxData = useReduxSelector(store => store.onboarding)

	const sessionDateOfBirth = reduxData.dateOfBirth !== undefined ? new Date(reduxData.dateOfBirth) : null

	return {
		phoneNumber: reduxData.phoneNumber ?? null,
		name: reduxData.name ?? null,
		dateOfBirth: isDate(sessionDateOfBirth) ? sessionDateOfBirth : null,
		emailAddress: reduxData.emailAddress ?? null,
		partialAddress: reduxData.address?.partial ?? null,
		manualAddress: reduxData.address?.manual ?? null,
		suggestedAddresses: reduxData.address?.suggestions ?? [],
		association: reduxData.association ?? null,
		agreements: reduxData.agreements ?? null
	}
}
