import { isDate } from "~/helpers/date"

/**
 * Parses the value from a date picker input.
 * @param {string} value The value to parse.
 * @returns {Date | null} The parsed date, or null if the value is not a valid date.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const parseDatePickerValue = (value: string): Date | null => {
	// Split the date into its individual components
	const [dayText, monthText, yearText] = value.replace(/-/g, "/").replace(/\s/g, "").split("/", 3)
	if (dayText === undefined || monthText === undefined || yearText === undefined) return null

	// Parse those components as numbers
	const day = parseInt(dayText, 10)
	const month = parseInt(monthText, 10)
	const year = parseInt(yearText, 10)
	if (isNaN(day) || isNaN(month) || isNaN(year)) return null

	// Convert to date
	const date = new Date(year, month - 1, day)
	if (!isDate(date)) return null

	return date
}
