import { MapPinIcon } from "@heroicons/react/24/solid"
import { useCallback } from "react"

import type { InflatedAppointment } from "~/classes/appointment"
import Button from "~/components/controls/button"
import Image from "~/components/controls/image"
import Paragraph from "~/components/standard/text/paragraph"
import { generateInitialsProfilePicture } from "~/helpers/initialsProfilePicture"
import type { OnClickCallback } from "~/types/components/controls/button"
import type { ComponentWithChildrenProps } from "~/types/components/props"

/**
 * The size of the appointment card profile pictures.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const appointmentCardProfilePictureSize = 72 // 50

/**
 * The size of the appointment card button icons.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const appointmentCardButtonIconSize = 20

/**
 * The size of the appointment card map icon.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const appointmentCardMapIconSize = 24

/**
 * An appointment card.
 * This should not be used directly! Instead use <AvailableAppointmentCard /> or <BookedAppointmentCard />.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const AppointmentCard = ({
	appointment,

	onContentClick,

	children,
	...props
}: ComponentWithChildrenProps<
	HTMLDivElement,
	{
		appointment: InflatedAppointment

		onContentClick?: OnClickCallback
	}
>): JSX.Element => {
	const [latitude, longitude] = appointment.location.latlong

	// Runs when the map icon is clicked...
	const onMapClick = useCallback<OnClickCallback>(() => {
		if (latitude === undefined || longitude === undefined) return // Do not continue if we don't have coordinates

		window.open(`https://maps.google.com?q=${latitude.toString()},${longitude.toString()}`, "_blank")
	}, [latitude, longitude])

	// Generate a fallback URL for the profile picture
	const initialsImageUrl = generateInitialsProfilePicture(appointment.practitioner.name)

	return (
		<div
			{...props}
			className={`flex w-full flex-col gap-y-3 rounded-lg bg-white px-4 py-3 shadow ${props.className ?? ""}`.trimEnd()}>
			<div className="flex flex-row justify-between">
				<div
					className={`flex flex-grow flex-row gap-x-4 ${onContentClick ? "hover:cursor-pointer" : ""}`.trimEnd()}
					onClick={onContentClick}>
					{appointment.practitioner.image !== null ? (
						<div className="flex aspect-square flex-col rounded-full bg-secondary">
							<Image
								className="rounded-full object-cover"
								sourceUrl={appointment.practitioner.image}
								fallbackUrl={initialsImageUrl ?? undefined}
								screenReaderDescription={`Picture of ${appointment.practitioner.name}'s face.`}
								width={appointmentCardProfilePictureSize}
								height={appointmentCardProfilePictureSize}
							/>
						</div>
					) : initialsImageUrl !== null ? (
						<div className="flex flex-col">
							<Image
								className="rounded-full"
								sourceUrl={initialsImageUrl}
								screenReaderDescription={`${appointment.practitioner.name}'s initials.`}
								width={appointmentCardProfilePictureSize}
								height={appointmentCardProfilePictureSize}
							/>
						</div>
					) : (
						<></>
					)}
					<div className="flex flex-col text-start">
						<Paragraph className="font-bold text-primary">{appointment.practitioner.name}</Paragraph>
						<Paragraph className="text-sm">
							{appointment.getHumanStartDate()}
							{appointment.isBooked() ? ` at ${appointment.getHumanStartTime()}` : ""}
						</Paragraph>
						<Paragraph className="text-sm">{appointment.location.name}</Paragraph>
					</div>
				</div>

				{latitude !== undefined && longitude !== undefined && (
					<Button invisible={true} className="flex h-fit items-start" onClick={onMapClick}>
						<MapPinIcon
							className="text-primary"
							width={appointmentCardMapIconSize}
							height={appointmentCardMapIconSize}
						/>
					</Button>
				)}
			</div>
			{children}
		</div>
	)
}

export default AppointmentCard
