import type { InputProps } from "~/components/controls/inputs/input"
import { buttonTransitionStyles } from "~/config/transitions"
import { useFormSelector } from "~/hooks/useForm"
import type { DoRenderCallback } from "~/types/components/callbacks"
import { ButtonThemes } from "~/types/components/controls/button"
import type { ComponentWithChildrenProps } from "~/types/components/props"

/**
 * Props on the button component.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export type ButtonProps = {
	endIcon?: JSX.Element | DoRenderCallback

	invisible?: boolean
	wide?: boolean
	theme?: ButtonThemes
	circle?: boolean
	alignment?: "left" | "center" | "right"

	standardHeight?: boolean
} & Pick<InputProps, "label" | "tooltip" | "startIcon" | "isDisabled" | "isFocused" | "isLoading" | "transition">

/**
 * Default size for loading button icons.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const loadingButtonIconSize = 24

/**
 * A pre-styled standard HTML button.
 * @example <Button />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const Button = ({
	type = "button",

	label,
	tooltip,

	startIcon,
	endIcon,

	invisible = false, // Transparent background, no label, etc.
	wide = false, // Full width
	theme = ButtonThemes.WhiteOnPrimary,
	circle = false, // Circular
	alignment = "center", // "left" | "center" | "right"

	isDisabled = false,
	isFocused = false,
	isLoading,

	standardHeight = true,

	children,
	...props
}: ComponentWithChildrenProps<
	HTMLButtonElement,
	{
		type?: "button" | "submit" | "reset"
	} & ButtonProps
>): JSX.Element => {
	const { isLoading: isFormLoading } = useFormSelector()
	isLoading ??= isFormLoading

	let activeButtonThemeStyles = ""
	let activeLabelThemeStyles = ""

	let disabledButtonThemeStyles = "bg-gray-400 hover:bg-gray-400 active:bg-gray-400 cursor-not-allowed"
	let disabledLabelThemeStyles = "text-text"

	switch (theme) {
		case ButtonThemes.PrimaryOnWhite:
			activeButtonThemeStyles =
				"border border-primary text-primary bg-white hover:bg-secondary active:bg-secondaryButtonActive"
			activeLabelThemeStyles = "text-primary"

			disabledLabelThemeStyles = "text-primary"

			break
		case ButtonThemes.SecondaryOnWhite:
			activeButtonThemeStyles =
				"border border-secondary text-secondary bg-white hover:bg-secondary active:bg-secondaryButtonActive"
			activeLabelThemeStyles = "text-secondary"

			disabledLabelThemeStyles = "text-secondary"

			break
		case ButtonThemes.WhiteOnPrimary:
			activeButtonThemeStyles = "text-white bg-primary hover:bg-primaryButtonHover active:bg-primaryButtonActive"
			activeLabelThemeStyles = "text-white"

			disabledLabelThemeStyles = "text-white"

			break
		case ButtonThemes.WhiteOnSecondary:
			activeButtonThemeStyles =
				"text-white bg-secondary hover:bg-secondaryButtonHover active:bg-secondaryButtonActive"
			activeLabelThemeStyles = "text-white"

			disabledLabelThemeStyles = "text-white"

			break
		case ButtonThemes.TextOnWhite:
			activeButtonThemeStyles =
				"border border-text text-text bg-white hover:bg-whiteButtonHover active:bg-whiteButtonActive"
			activeLabelThemeStyles = "text-text"

			disabledLabelThemeStyles = "text-text"

			break
		case ButtonThemes.PrimaryOnSecondary:
			activeButtonThemeStyles =
				"text-text bg-secondary hover:bg-secondaryButtonHover active:bg-secondaryButtonActive"
			activeLabelThemeStyles = "text-text"

			disabledButtonThemeStyles = "bg-gray-400 hover:bg-gray-400 active:bg-gray-400 cursor-not-allowed"
			disabledLabelThemeStyles = "text-white"

			break
	}

	return (
		<button
			{...props}
			type={type}
			aria-label={label}
			title={tooltip ?? label ?? "Please click the button"}
			disabled={isDisabled}
			autoFocus={isFocused}
			className={`${buttonTransitionStyles} flex flex-row items-center gap-x-1 ${alignment === "right" ? "justify-end" : alignment === "left" ? "justify-start" : "justify-center"} ${wide ? "w-full" : "w-fit"} ${!invisible ? `${circle ? "aspect-square rounded-full" : "rounded-lg px-3 py-2"} text-sm ${isDisabled || isLoading ? disabledButtonThemeStyles : `${activeButtonThemeStyles} hover:cursor-pointer`}` : ""} ${standardHeight && !invisible ? "h-12" : "h-fit"} ${props.className ?? ""}`.trimEnd()}>
			{children ?? (
				<>
					{typeof startIcon === "function" ? startIcon(isLoading) : startIcon}
					{label !== undefined && (
						<span
							className={`items-center justify-center text-center align-middle ${isDisabled || isLoading ? disabledLabelThemeStyles : activeLabelThemeStyles} ${alignment === "center" ? "m-auto" : ""}`.trimEnd()}>
							{label}
						</span>
					)}
					{typeof endIcon === "function" ? endIcon(isLoading) : endIcon}
				</>
			)}
		</button>
	)
}

export default Button
