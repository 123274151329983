/**
 * Clamps a number between a minimum and maximum value.
 * @param {number} value The value to clamp.
 * @param {number} minimum The minimum value.
 * @param {number} maximum The maximum value.
 * @returns {number} The clamped value.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.6.1
 */
export const clamp = (value: number, minimum: number, maximum: number): number =>
	Math.min(Math.max(value, minimum), maximum)
