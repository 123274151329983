import { useCallback, useEffect, useState } from "react"

import type { EmptyCallback } from "~/types/components/callbacks"

/**
 * React hook to periodically check if we've got Internet access.
 * @param {number} interval The interval in milliseconds to check the connection status.
 * @returns {[boolean, EmptyCallback]} An array containing the current online status state & a method to check it immediately.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.7.0
 */
export const useIsOnline = (interval = 1000): [boolean, EmptyCallback] => {
	const [isOnline, setIsOnline] = useState<boolean>(true) // Assume online by default, we don't want to completely brick the app

	useEffect(() => {
		const loop = setInterval(() => {
			const isOnline = navigator.onLine

			setIsOnline(wasOnline => {
				if (wasOnline !== isOnline) console.info(`We're ${isOnline ? "online" : "offline"}!`)
				return isOnline
			})
		}, interval)

		return (): void => {
			clearInterval(loop)
		}
	})

	const checkNow = useCallback<EmptyCallback>(() => {
		const isOnline = navigator.onLine
		setIsOnline(isOnline)

		return isOnline
	}, [])

	return [isOnline, checkNow]
}
