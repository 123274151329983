import { useCallback, useEffect } from "react"
import { Link } from "react-router-dom"

import { useLazySendVerificationCodeQuery } from "~/api/osteo-physio/client"
import { ErrorMessages, isAPIError, isHTTPError, isStandardError } from "~/api/osteo-physio/types/error"
import Form from "~/components/controls/forms/form"
import SubmitButton from "~/components/controls/forms/submitButton"
import PhoneNumberInput from "~/components/controls/inputs/phoneNumber"
import OfflineWarning from "~/components/onboarding/offlineWarning"
import StageWrapper from "~/components/onboarding/wrapper"
import Paragraph from "~/components/standard/text/paragraph"
import { useFlowContext } from "~/contexts/flow"
import { prettyPhoneNumber } from "~/helpers/phoneNumber"
import { useFormDispatch } from "~/hooks/useForm"
import { useIsOnline } from "~/hooks/useIsOnline"
import { useOnboardingDispatch, useOnboardingSelector } from "~/hooks/useOnboarding"
import { Routes } from "~/router"
import type { OnFormSubmitCallback } from "~/types/components/controls/form"
import { OnboardingStage } from "~/types/components/pages/onboarding"
import type { ComponentProps } from "~/types/components/props"

enum HTMLElementIdentifiers {
	VerifyPhoneNumber = "verifyPhoneNumber",
	PhoneNumber = "phoneNumber"
}

/**
 * The first stage of the onboarding process.
 * This stage collects the user's phone number to check if they already have an account.
 * This should be wrapped in a <StageFlow /> component!
 * @example <PhoneNumberStage />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const PhoneNumberStage = ({ ...props }: ComponentProps<HTMLDivElement>): JSX.Element => {
	const { transfer } = useFlowContext()

	const [sendVerificationCode, verificationCodeResponse] = useLazySendVerificationCodeQuery()

	const { showWarning, updateLoading } = useFormDispatch(HTMLElementIdentifiers.VerifyPhoneNumber)

	const { setPhoneNumber, clearPhoneNumber } = useOnboardingDispatch()
	const { phoneNumber } = useOnboardingSelector()

	// Internet connectivity check
	const [isOnline] = useIsOnline()

	// Runs after the verification code response is received...
	useEffect(() => {
		if (phoneNumber === null) return
		if (
			verificationCodeResponse.isUninitialized ||
			verificationCodeResponse.isFetching ||
			verificationCodeResponse.isLoading
		)
			return

		updateLoading(false)

		if (verificationCodeResponse.isError) {
			// API error message
			if (isAPIError(verificationCodeResponse.error)) {
				if (verificationCodeResponse.error.data.SystemErrorMessage === ErrorMessages.Success.valueOf()) return // API can return success within an error message?!

				if (
					verificationCodeResponse.error.data.SystemErrorMessage ===
					ErrorMessages.MobileNumberNotFound.valueOf()
				) {
					console.warn("Mobile phone number not found!")
					transfer(OnboardingStage.Name)
					return
				}

				console.warn(
					`Failed to send verification code! (API said '${verificationCodeResponse.error.data.SystemErrorMessage?.toString() ?? "Unknown error"}')`
				)
				showWarning(HTMLElementIdentifiers.PhoneNumber, "API error! See console for more information.")
				clearPhoneNumber()
				return
			}

			// HTTP error code
			if (isHTTPError(verificationCodeResponse.error)) {
				console.warn(
					`Failed to send verification code! (HTTP ${verificationCodeResponse.error.status.toString()})`
				)
				showWarning(
					HTMLElementIdentifiers.PhoneNumber,
					`HTTP ${verificationCodeResponse.error.status.toString()}! See console for more information.`
				)
				clearPhoneNumber()
				return
			}

			// Fetch aborted (e.g., timeout?)
			if (
				isStandardError(verificationCodeResponse.error) &&
				verificationCodeResponse.error.name === "AbortError"
			) {
				console.warn("Failed to send verification code! (Request was aborted)")
				showWarning(HTMLElementIdentifiers.PhoneNumber, "The request was aborted! Please try again.")
				clearPhoneNumber()
				return
			}

			// Unknown
			console.error(`Failed to send verification code! (${JSON.stringify(verificationCodeResponse.error)})`)
			showWarning(HTMLElementIdentifiers.PhoneNumber, "An unknown error occurred! Please try again later.")
			clearPhoneNumber()
			return
		}

		// This is only necessary for verification code responses!
		if (verificationCodeResponse.data.SystemErrorMessage !== ErrorMessages.Success.valueOf()) {
			console.warn(
				`Failed to send verification code! (API said '${verificationCodeResponse.data.SystemErrorMessage?.toString() ?? "Unknown error"}')`
			)
			showWarning(HTMLElementIdentifiers.PhoneNumber, "API error! See console for more information.")
			clearPhoneNumber()
			return
		}

		// Great success
		console.info(`Verification code sent to '${phoneNumber}'!`)
		transfer(OnboardingStage.VerifyCode)
	}, [showWarning, updateLoading, clearPhoneNumber, transfer, verificationCodeResponse, phoneNumber])

	// Runs when the verify button is clicked submitted...
	const onSubmitted = useCallback<OnFormSubmitCallback>(
		(values): void => {
			const mobileNumber = values.get(HTMLElementIdentifiers.PhoneNumber) as string | null

			// Shouldn't happen, but check just in case to please TypeScript
			if (mobileNumber === null) {
				console.warn("No mobile phone number was entered, even though the input is required?!")
				showWarning(HTMLElementIdentifiers.PhoneNumber, "Enter your mobile phone number!")
				return
			}

			// Forbid international numbers (i.e., those starting with + followed by a country code)
			if (mobileNumber.startsWith("+")) {
				console.warn("The mobile phone number is in international format!")
				showWarning(HTMLElementIdentifiers.PhoneNumber, "The phone number cannot be in international format!")
				return
			}

			const mobileNumberWithoutWhitespace = mobileNumber.replace(/\s/g, "")
			setPhoneNumber(mobileNumberWithoutWhitespace)
			console.info(`Checking mobile phone number '${mobileNumberWithoutWhitespace}'...`)

			/* eslint-disable promise/prefer-await-to-then */
			updateLoading(true)
			sendVerificationCode({
				mobileNumber: mobileNumberWithoutWhitespace,
				userAgent: window.navigator.userAgent
			}).catch((error: unknown) => {
				console.error(`Failed to send verification code! (${error?.toString() ?? "Unknown error"})`)
				showWarning(
					HTMLElementIdentifiers.PhoneNumber,
					"Sorry, an unknown problem occurred! Please try again later."
				)
				clearPhoneNumber()
			})
		},
		[showWarning, updateLoading, clearPhoneNumber, setPhoneNumber, sendVerificationCode]
	)

	return (
		<StageWrapper {...props}>
			<Paragraph>
				Please enter your mobile phone number below to sign into your account. If you don&apos;t have an account
				yet, we&apos;ll create one for you!
			</Paragraph>
			<OfflineWarning visible={!isOnline} />
			<div className="flex flex-col gap-y-6">
				<Form id={HTMLElementIdentifiers.VerifyPhoneNumber} onSubmit={onSubmitted}>
					<PhoneNumberInput
						id={HTMLElementIdentifiers.PhoneNumber}
						label="Phone Number"
						initialValue={phoneNumber !== null ? prettyPhoneNumber(phoneNumber) : undefined}
						isFocused={true}
						isDisabled={!isOnline}
					/>
					<SubmitButton isDisabled={!isOnline} loadingLabel="Checking for an account..." />
				</Form>
				<Paragraph className="text-sm">
					Ensure you agree to our <Link to={Routes.TermsConditions}>Terms & Conditions</Link>.
				</Paragraph>
			</div>
		</StageWrapper>
	)
}

export default PhoneNumberStage
