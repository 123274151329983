import { useCallback } from "react"

import type { CustomDate } from "~/contexts/datePicker"
import { AnyIdentifier } from "~/helpers/object"
import type { SliceStructure } from "~/state/slices/appointmentFilters"
import { slice } from "~/state/slices/appointmentFilters"
import { useReduxDispatch, useReduxSelector } from "~/state/store"
import type { EmptyCallback } from "~/types/components/callbacks"

/**
 * Callback for setting a date.
 * @param {CustomDate} date The date to set.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.6.0
 */
export type CustomDateCallback = (date: CustomDate) => void

/**
 * Callback for setting an identifier.
 * @param {number} identifier The identifier to set.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.5.0
 */
export type IdentifierCallback = (identifier: number) => void

/**
 * React hook for retrieving the filters for fetching available appointments.
 * @returns {object} The current filter values.
 * @example const { chosenLocationIdentifier, chosenDate, ... } = useAppointmentFilterSelector()
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.5.0
 */
/*
export const useAppointmentFilterSelector = (): {
	chosenTherapyTypeIdentifier: SliceStructure["therapyTypeIdentifier"]
	chosenAppointmentTypeIdentifier: SliceStructure["appointmentTypeIdentifier"]

	chosenLocationIdentifier: SliceStructure["locationIdentifier"]
	chosenPractitionerIdentifier: SliceStructure["practitionerIdentifier"]
	chosenGenderIdentifier: SliceStructure["genderIdentifier"]

	chosenDate: SliceStructure["date"]
} => {
	const slice = useReduxSelector(store => store.appointmentFilters)

	return {
		chosenTherapyTypeIdentifier: slice.therapyTypeIdentifier,
		chosenAppointmentTypeIdentifier: slice.appointmentTypeIdentifier,

		chosenLocationIdentifier: slice.locationIdentifier,
		chosenPractitionerIdentifier: slice.practitionerIdentifier,
		chosenGenderIdentifier: slice.genderIdentifier,

		chosenDate: slice.date
	}
}
*/

/**
 * React hook for updating the filters for fetching available appointments.
 * This does not include therapy types & appointment types, use the respective hooks for those.
 * @returns {object} Methods to update the filter values.
 * @example const { setChosenLocationIdentifier, clearChosenPractitionerIdentifier, clear, ... } = useAppointmentFilterDispatch()
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.5.0
 */
export const useAppointmentFilterDispatch = (): {
	setChosenLocationIdentifier: IdentifierCallback
	clearChosenLocationIdentifier: EmptyCallback

	setChosenPractitionerIdentifier: IdentifierCallback
	clearChosenPractitionerIdentifier: EmptyCallback

	setChosenGenderIdentifier: IdentifierCallback
	clearChosenGenderIdentifier: EmptyCallback

	setChosenDate: CustomDateCallback
	clearChosenDate: EmptyCallback

	clear: EmptyCallback
} => {
	const dispatch = useReduxDispatch()

	return {
		setChosenLocationIdentifier: useCallback<IdentifierCallback>(
			identifier => {
				if (isNaN(identifier)) throw new Error("Location identifier must be a number")

				dispatch(
					slice.actions.updateLocationIdentifier({
						locationIdentifier: identifier !== AnyIdentifier ? identifier : null
					})
				)
			},
			[dispatch]
		),
		clearChosenLocationIdentifier: useCallback<EmptyCallback>(() => {
			dispatch(
				slice.actions.updateLocationIdentifier({
					locationIdentifier: null
				})
			)
		}, [dispatch]),

		setChosenPractitionerIdentifier: useCallback<IdentifierCallback>(
			identifier => {
				if (isNaN(identifier)) throw new Error("Practitioner identifier must be a number")

				dispatch(
					slice.actions.updatePractitionerIdentifier({
						practitionerIdentifier: identifier !== AnyIdentifier ? identifier : null
					})
				)
			},
			[dispatch]
		),
		clearChosenPractitionerIdentifier: useCallback<EmptyCallback>(() => {
			dispatch(
				slice.actions.updatePractitionerIdentifier({
					practitionerIdentifier: null
				})
			)
		}, [dispatch]),

		setChosenGenderIdentifier: useCallback<IdentifierCallback>(
			identifier => {
				if (isNaN(identifier)) throw new Error("Gender identifier must be a number")

				dispatch(
					slice.actions.updateGenderIdentifier({
						genderIdentifier: identifier !== AnyIdentifier ? identifier : null
					})
				)
			},
			[dispatch]
		),
		clearChosenGenderIdentifier: useCallback<EmptyCallback>(() => {
			dispatch(
				slice.actions.updateGenderIdentifier({
					genderIdentifier: null
				})
			)
		}, [dispatch]),

		setChosenDate: useCallback<CustomDateCallback>(
			date => {
				dispatch(
					slice.actions.updateDate({
						date: date
					})
				)
			},
			[dispatch]
		),
		clearChosenDate: useCallback<EmptyCallback>(() => {
			dispatch(
				slice.actions.updateDate({
					date: null
				})
			)
		}, [dispatch]),

		clear: useCallback<EmptyCallback>(() => {
			dispatch(
				slice.actions.update({
					therapyTypeIdentifier: null,
					appointmentTypeIdentifier: null,

					locationIdentifier: null,
					practitionerIdentifier: null,
					genderIdentifier: null,

					date: null
				})
			)
		}, [dispatch])
	}
}

/**
 * React hook for retrieving the therapy type filter.
 * @returns {number | null} The chosen therapy type identifier.
 * @example const chosenTherapyTypeIdentifier = useChosenTherapyTypeIdentifier()
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.5.0
 */
export const useChosenTherapyTypeIdentifier = (): SliceStructure["therapyTypeIdentifier"] =>
	useReduxSelector(store => store.appointmentFilters.therapyTypeIdentifier)

/**
 * React hook for retrieving the appointment type filter.
 * @returns {number | null} The chosen appointment type identifier.
 * @example const chosenAppointmentTypeIdentifier = useChosenAppointmentTypeIdentifier()
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.5.0
 */
export const useChosenAppointmentTypeIdentifier = (): SliceStructure["appointmentTypeIdentifier"] =>
	useReduxSelector(store => store.appointmentFilters.appointmentTypeIdentifier)

/**
 * React hook for retrieving the location filter.
 * @returns {number | null} The chosen location identifier.
 * @example const chosenLocationIdentifier = useChosenLocationIdentifier()
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.5.0
 */
export const useChosenLocationIdentifier = (): SliceStructure["locationIdentifier"] =>
	useReduxSelector(store => store.appointmentFilters.locationIdentifier)

/**
 * React hook for retrieving the practitioner filter.
 * @returns {number | null} The chosen practitioner identifier.
 * @example const chosenPractitionerIdentifier = useChosenPractitionerIdentifier()
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.5.0
 */
export const useChosenPractitionerIdentifier = (): SliceStructure["practitionerIdentifier"] =>
	useReduxSelector(store => store.appointmentFilters.practitionerIdentifier)

/**
 * React hook for retrieving the gender filter.
 * @returns {number | null} The chosen gender identifier.
 * @example const chosenGenderIdentifier = useChosenGenderIdentifier()
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.5.0
 */
export const useChosenGenderIdentifier = (): SliceStructure["genderIdentifier"] =>
	useReduxSelector(store => store.appointmentFilters.genderIdentifier)

/**
 * React hook for retrieving the date filter.
 * @returns {CustomDate | null} The chosen date.
 * @example const chosenDate = useChosenDate()
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.5.0
 */
export const useChosenDate = (): SliceStructure["date"] => useReduxSelector(store => store.appointmentFilters.date)
