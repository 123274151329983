import { Switch } from "@headlessui/react"
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid"
import { useCallback, useState } from "react"

import type { InputProps } from "~/components/controls/inputs/input"
import Paragraph from "~/components/standard/text/paragraph"
import FadeIn from "~/components/wrappers/fadeIn"
import { useFormSelector } from "~/hooks/useForm"
import { useInputDispatch, useInputSelector } from "~/hooks/useInput"

/**
 * A pre-styled standard HTML check-box.
 * @example <SwitchInput id="switchInput" />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const SwitchInput = ({
	id,

	label,
	url,

	tooltip,

	initialValue = false,

	isDisabled,
	isReadOnly,
	isRequired = true,
	isFocused,

	className
}: Pick<InputProps, "id" | "tooltip" | "isDisabled" | "isReadOnly" | "isRequired" | "isFocused"> & {
	label: string
	url?: string

	initialValue?: boolean

	className?: string
}): JSX.Element => {
	const [isEnabled, setIsEnabled] = useState<boolean>(initialValue)

	const { isLoading } = useFormSelector()

	const { clearWarning } = useInputDispatch(id)
	const { warningMessage } = useInputSelector(id)

	// Runs when the toggle changes...
	const onChange = useCallback(() => {
		clearWarning()
		setIsEnabled(isCurrentlyEnabled => !isCurrentlyEnabled)
	}, [clearWarning, setIsEnabled])

	return (
		<Switch.Group>
			<div className={`flex flex-col gap-y-4 ${className ?? ""}`.trimEnd()}>
				<div className="flex items-center gap-x-4">
					<Switch
						id={id}
						name={id}
						checked={isEnabled}
						onChange={onChange}
						title={tooltip}
						aria-label={label}
						disabled={isDisabled ?? isLoading}
						aria-disabled={isDisabled ?? isLoading}
						aria-readonly={isReadOnly}
						aria-required={isRequired}
						autoFocus={isFocused}
						className={`relative inline-flex h-6 w-11 items-center rounded-full ${!(isDisabled ?? isLoading) && isEnabled ? "bg-switchPositive" : "bg-gray-400"}`}>
						<span className="sr-only">Example</span>
						<span
							className={`${
								isEnabled ? "translate-x-6" : "translate-x-1"
							} inline-block h-4 w-4 transform rounded-full bg-white transition`}
						/>
					</Switch>
					{label && (
						<div className="flex flex-col text-left">
							<Switch.Label
								passive={url !== undefined ? true : false}
								className={`mr-4 hover:cursor-pointer ${url !== undefined ? "text-primary hover:underline" : ""}`}>
								{url !== undefined ? (
									<div className="flex flex-row items-start gap-x-1">
										<a href={url} target="_blank" rel="noopener noreferrer">
											{label}
										</a>
										<ArrowTopRightOnSquareIcon width={14} height={14} />
									</div>
								) : (
									label
								)}
							</Switch.Label>
							{tooltip !== undefined && <Paragraph className="text-xs text-primary">{tooltip}</Paragraph>}
						</div>
					)}
				</div>
				{warningMessage !== null && (
					<FadeIn>
						<Paragraph className="text-xs font-bold text-warning">{warningMessage}</Paragraph>
					</FadeIn>
				)}
			</div>
		</Switch.Group>
	)
}

export default SwitchInput
