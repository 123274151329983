import { isDate } from "~/helpers/date"

/**
 * The keys for all the values stored in local storage.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export enum PersistentKeys {
	// Onboarding
	OnboardingPhoneNumber = "onboardingPhoneNumber",
	OnboardingName = "onboardingName",
	OnboardingDateOfBirth = "onboardingDateOfBirth",
	OnboardingEmailAddress = "onboardingEmailAddress",
	OnboardingPartialAddress = "onboardingPartialAddress",
	OnboardingManualAddress = "onboardingManualAddress",
	OnboardingAssociation = "onboardingAssociation",
	OnboardingAgreements = "onboardingAgreements",

	// Session/Authentication
	SessionID = "sessionId",
	SessionUser = "sessionUser",
	SessionToken = "sessionToken"
}

/**
 * Reads a value from local storage.
 * @returns {string | null} The value from local storage, or null if not found.
 * @example const myValue = getPersistentValue("myValue")
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const getPersistentValue = (key: PersistentKeys): string | null => localStorage.getItem(key)

/**
 * Reads a JSON object from local storage.
 * @type {Type} The type to parse the JSON object as.
 * @returns {Type | null} The parsed JSON object from local storage, or null if not found or otherwise invalid.
 * @example const myObject = getPersistentJSON<{ hello: string, world: number }>("myDate")
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const getPersistentJSON = <Type>(key: PersistentKeys): Type | null => {
	const value = getPersistentValue(key)
	return value !== null ? ((JSON.parse(value) as Type) ?? null) : null
}

/**
 * Reads a date from local storage.
 * @returns {Date | null} The date from local storage, or null if not found or otherwise invalid.
 * @example const myDate = getPersistentDate("myDate")
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const getPersistentDate = (key: PersistentKeys): Date | null => {
	const value = getPersistentValue(key) // ISO 8601
	if (value === null) return null

	const date = new Date(value)
	if (!isDate(date)) return null

	return date
}

/**
 * Reads a number from local storage.
 * @returns {number | null} The number from local storage, or null if not found or otherwise invalid.
 * @example const myNumber = getPersistentNumber("myNumber")
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const getPersistentNumber = (key: PersistentKeys): number | null => {
	const value = getPersistentValue(key)
	if (value === null) return null

	const number = parseFloat(value)
	if (isNaN(number)) return null

	return number
}
