import { useCallback } from "react"

import Button from "~/components/controls/button"
import SubmitButton from "~/components/controls/forms/submitButton"
import type { InputProps } from "~/components/controls/inputs/input"
import { useFlowContext } from "~/contexts/flow"
import { useFormDispatch } from "~/hooks/useForm"
import type { OnClickCallback } from "~/types/components/controls/button"
import { ButtonThemes } from "~/types/components/controls/button"

/**
 * Back & continue buttons for the bottom of a form.
 * This feels sketchy, I might remove it!
 * @example <BackOrContinueButtons />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const BackOrContinueButtons = ({
	previousStage,

	submitLabel = "Continue",
	submitLoadingLabel,

	isDisabled,

	onBackClick
}: Pick<InputProps, "isDisabled"> & {
	previousStage?: number

	submitLabel?: string
	submitLoadingLabel?: string

	onBackClick?: OnClickCallback
}): JSX.Element => {
	// Onboarding
	const { transfer, currentStage, previousStage: previousStageInContext } = useFlowContext()

	// Form
	const { updateLoading, clearWarnings } = useFormDispatch()

	const onClick = useCallback<OnClickCallback>(
		event => {
			// Clear pending loading states & warning messages as going back acts like cancelling too
			updateLoading(false)
			clearWarnings()

			// Call the custom back click event instead of below if it exists
			if (onBackClick) {
				onBackClick(event)
				return
			}

			// Try get the last stage
			const newStage = previousStage ?? previousStageInContext
			if (newStage === null) {
				console.warn("No previous stage was provided or found in the context!")
				return
			}

			// Warn about switching to the same stage
			if (currentStage === newStage) {
				console.warn("The previous stage is the same as the current stage?!")
				return
			}

			// Switch the last stage
			transfer(newStage)
		},
		[transfer, updateLoading, clearWarnings, onBackClick, previousStage, currentStage, previousStageInContext]
	)

	return (
		<div className="flex flex-row gap-x-4">
			<Button label="Back" wide={true} theme={ButtonThemes.WhiteOnPrimary} className="mt-2" onClick={onClick} />
			<SubmitButton label={submitLabel} isDisabled={isDisabled} loadingLabel={submitLoadingLabel} />
		</div>
	)
}

export default BackOrContinueButtons
