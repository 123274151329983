import { Bars3Icon } from "@heroicons/react/24/solid"
import { useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"

import AccountMenu from "~/components/accountMenu"
import Button from "~/components/controls/button"
import Image from "~/components/controls/image"
import Paragraph from "~/components/standard/text/paragraph"
import { generateGreeting } from "~/helpers/greeting"
import { useAuthSessionSelector } from "~/hooks/useAuthSession"
import { Routes } from "~/router"
import { isPartialUser } from "~/state/slices/authSession"
import type { OnClickCallback } from "~/types/components/controls/button"
import type { ComponentProps } from "~/types/components/props"

/**
 * A pre-styled standard HTML header used across all non-fullscreen (e.g., onboarding) pages.
 * @example <Header />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const Header = ({ ...props }: ComponentProps): JSX.Element => {
	const [isMenuVisible, setMenuVisibility] = useState(false)

	const navigate = useNavigate()

	// Runs when the logo is clicked...
	const onLogoClick = useCallback<OnClickCallback>(() => {
		navigate(Routes.Home)
	}, [navigate])

	// Runs when the hamburger menu icon is clicked...
	const onHamburgerClick = useCallback<OnClickCallback>(() => {
		setMenuVisibility(isMenuVis => !isMenuVis)
	}, [setMenuVisibility])

	// z index is required to ensure the account menu slides in underneath it!
	return (
		<>
			<header
				{...props}
				className={`z-30 flex max-h-16 flex-row justify-between bg-primary px-4 py-2 shadow-md ${props.className ?? ""} items-center`.trimEnd()}>
				<Button invisible={true} onClick={onLogoClick}>
					<Image
						sourceUrl="/images/logo/text.svg"
						screenReaderDescription="The small horizontal Osteo & Physio logo."
						width={78}
					/>
				</Button>
				<div className="flex flex-row gap-x-3 py-3">
					<div className="flex flex-row items-center gap-x-3">
						<div className="flex flex-col bg-primary text-right text-sm">
							<Greeting />
						</div>
					</div>
					<Button invisible={true} onClick={onHamburgerClick}>
						<Bars3Icon className="rounded-full p-1 text-white" width={42} height={42} />
					</Button>
				</div>
			</header>
			<AccountMenu isVisible={isMenuVisible} setMenuVisibility={setMenuVisibility} />
		</>
	)
}

/**
 * Renders a greeting with the user's name.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const Greeting = (): JSX.Element => {
	// Could use core data here, but app.tsx will update session user with the full user once we have core data
	const { user } = useAuthSessionSelector()
	const userName = user && !isPartialUser(user) ? `${user.first_name} ${user.last_name}` : null

	return (
		<Paragraph className="text-secondary">
			{userName !== null && (
				<>
					{generateGreeting()}
					<span className="text-secondary">
						, <span className="font-bold text-white">{userName}</span>
					</span>
				</>
			)}
		</Paragraph>
	)
}

export default Header
