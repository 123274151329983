import { ErrorBoundary, init, reactRouterV6BrowserTracingIntegration, replayIntegration } from "@sentry/react"
import React, { useEffect } from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { RouterProvider, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom"
import { registerSW } from "virtual:pwa-register"
import { terminal } from "virtual:terminal"

import { router } from "~/router"
import { store } from "~/state/store"

import "~/index.css"

// https://vitejs.dev/guide/env-and-mode#env-variables-and-modes
const sentryDSN = import.meta.env.VITE_SENTRY_DSN
if (!sentryDSN) throw new Error("The Sentry DSN is missing!")

registerSW({
	// https://vite-pwa-org.netlify.app/guide/auto-update.html#automatic-reload
	immediate: true,

	// https://vite-pwa-org.netlify.app/guide/auto-update.html#ready-to-work-offline
	onOfflineReady: () => {
		console.info("This PWA is ready to work offline :D")
	}
})

// https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps/Guides/Making_PWAs_installable#triggering_the_install_prompt
window.addEventListener("beforeinstallprompt", event => {
	console.info("Stored PWA install prompt event.")
	globalThis.progressiveWebAppInstallEvent = (event as BeforeInstallPromptEvent | undefined) ?? undefined

	if (localStorage.getItem("dismissInstallPrompt") === "true") {
		console.warn("Skipping PWA install prompt as it has been previously dismissed.")
		return
	}

	const onboardingInstallPrompt = document.getElementById("onboardingInstallPrompt")
	if (!onboardingInstallPrompt) {
		console.warn("Onboarding PWA install prompt element not found!")
		return
	}

	onboardingInstallPrompt.classList.remove("opacity-0")
	onboardingInstallPrompt.classList.add("opacity-100", "delay-1000")
})

init({
	dsn: sentryDSN,
	release: VITE_BITBUCKET_TAG,
	environment: import.meta.env.MODE,
	enabled: !import.meta.env.DEV, // No captures during development
	integrations: [
		// See docs for support of different versions of variation of react router
		// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
		reactRouterV6BrowserTracingIntegration({
			useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes
		}),
		replayIntegration()
	],
	tracesSampleRate: 1.0,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
	autoSessionTracking: true,

	// baggage header
	tracePropagationTargets: [import.meta.env.VITE_BASE_URL]
})

// Dump visitor info to Vite terminal for debugging
if (import.meta.env.DEV) {
	terminal.info(window.navigator.userAgent)
	if ("userAgentData" in navigator) terminal.dir(navigator.userAgentData)
}

// Render the app
const rootElement = document.getElementById("root")
if (!rootElement) throw new Error("Root element not found!")

ReactDOM.createRoot(rootElement).render(
	<React.StrictMode>
		<ErrorBoundary>
			<Provider store={store}>
				<RouterProvider router={router} />
			</Provider>
		</ErrorBoundary>
	</React.StrictMode>
)
