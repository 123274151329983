import { createContext, useContext } from "react"

/**
 * The structure of a form context.
 * @property {string} id The ID of the form.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export interface FormContext {
	id: string | null
}

/**
 * The default values of the form context.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const FormContext = createContext<FormContext>({
	id: null
})

/**
 * A hook to access the current form's context.
 * @example const form = useFormContext()
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const useFormContext = (): FormContext => useContext(FormContext)

/**
 * The provider for the form context.
 * @example <FormContextProvider value={{ id: "myForm" }}>...</FormContextProvider>
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const FormContextProvider = FormContext.Provider
