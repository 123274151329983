import { DocumentTextIcon } from "@heroicons/react/24/outline"

import { useFetchTermsConditionsQuery } from "~/api/osteo-physio/client"
import PopupModal from "~/components/popupModal"
import Header from "~/components/standard/layout/header"
import Page from "~/components/standard/layout/page"
import Section, { sectionHeadingIconSize } from "~/components/standard/layout/section"
import Paragraph from "~/components/standard/text/paragraph"
import FadeIn from "~/components/wrappers/fadeIn"
import FullScreen from "~/components/wrappers/fullScreen"
import { useIsOnline } from "~/hooks/useIsOnline"
import type { ComponentProps } from "~/types/components/props"

/**
 * The Terms & Conditions page.
 * @example <TermsPage />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const TermsPage = ({ ...props }: ComponentProps<HTMLDivElement>): JSX.Element => {
	const [isOnline] = useIsOnline()

	const { data } = useFetchTermsConditionsQuery(
		{},
		{
			skip: !isOnline
		}
	)

	const lastUpdatedAt = data?.updated_at !== undefined ? new Date(data.updated_at) : new Date("2024-01-16")
	const prettyDate = lastUpdatedAt.toLocaleDateString("en-GB", {
		weekday: "long",
		year: "numeric",
		month: "long",
		day: "numeric"
	})

	/* eslint-disable quotes */
	/* eslint-disable indent */
	// Replace new lines with breaks & make links clickable
	const htmlContent =
		data?.content !== undefined
			? data.content
					.replace(/\r\n/g, "<br>")
					.replace(/(https?:\/\/[\w\d.]+)/, '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>')
			: null

	// This has the same root components as <App />
	return (
		<FullScreen useMain={false}>
			<FadeIn className="flex flex-grow flex-col">
				<Header />
				<Page className="gap-y-2" {...props}>
					<Section
						title="Terms & Conditions"
						innerClassName="py-3"
						icon={
							<DocumentTextIcon
								className="text-text"
								width={sectionHeadingIconSize}
								height={sectionHeadingIconSize}
							/>
						}>
						{!isOnline && (
							<FadeIn>
								<Paragraph className="text-center text-sm font-bold text-red-600">
									You are offline. Please check your Wi-Fi or data connection to continue.
								</Paragraph>
							</FadeIn>
						)}
						<Paragraph className={`text-center font-bold ${!isOnline ? "text-gray-600" : ""}`.trimEnd()}>
							Last updated on {prettyDate}.
						</Paragraph>
						<div
							id="termsConditions" // So global styles can target this element
							className={`flex flex-col gap-y-4 ${!isOnline ? "text-gray-400" : ""}`.trimEnd()}
							dangerouslySetInnerHTML={{
								__html: htmlContent ?? ""
							}}
						/>
					</Section>
				</Page>
			</FadeIn>

			<PopupModal />
		</FullScreen>
	)
}

export default TermsPage
