import { useCallback } from "react"

import { useFormContext } from "~/contexts/form"
import type { Input } from "~/state/slices/userInterface"
import { slice } from "~/state/slices/userInterface"
import { useReduxDispatch, useReduxSelector } from "~/state/store"
import type { EmptyCallback } from "~/types/components/callbacks"

/**
 * Callback for showing a warning message on an individual input within a form.
 * @param {string} message The warning message to show.
 * @see useInputDispatch()
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export type ShowInputWarningCallback = (message: string) => void

/**
 * React hook for showing & clearing the warning message for an individual input within a form.
 * @param {string} inputId The unique HTML identifier of the input.
 * @param {string | undefined} formId The unique HTML identifier of the form. If not provided, the form context will be used.
 * @returns {object} Functions for showing & clearing the warning message.
 * @example const { showWarning, clearWarning } = useInputDispatch("myInput")
 * @see useInputSelector()
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const useInputDispatch = (
	inputId: string,
	formId?: string
): {
	showWarning: ShowInputWarningCallback
	clearWarning: EmptyCallback
} => {
	const dispatch = useReduxDispatch()

	const { id: formContextId } = useFormContext()
	formId ??= formContextId ?? undefined

	// Shows a warning message for a specific input within a form
	const showWarning = useCallback(
		(message: string) => {
			if (formId === undefined) {
				console.warn("Cannot show a warning message without a form ID!")
				return
			}

			dispatch(
				slice.actions.updateForm({
					[formId]: {
						inputs: {
							[inputId]: {
								warningMessage: message
							}
						}
					}
				})
			)
		},
		[dispatch, formId, inputId]
	)

	// Clears the warning message for a specific input within a form
	const clearWarning = useCallback(() => {
		if (formId === undefined) {
			console.warn("Cannot clear a warning message without a form ID!")
			return
		}

		dispatch(
			slice.actions.updateForm({
				[formId]: {
					inputs: {
						[inputId]: {
							warningMessage: null
						}
					}
				}
			})
		)
	}, [dispatch, formId, inputId])

	return { showWarning, clearWarning }
}

/**
 * React hook for getting the warning message for an individual input within a form.
 * @param {string} inputId The unique HTML identifier of the input.
 * @param {string | undefined} formId The unique HTML identifier of the form. If not provided, the form context will be used.
 * @returns {Input} The warning message for the input.
 * @example const { warningMessage } = useInputSelector("myInput")
 * @see useInputDispatch()
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const useInputSelector = (inputId: string, formId?: string): Input => {
	const { id: formContextId } = useFormContext()
	formId ??= formContextId ?? undefined

	const input = useReduxSelector(state => state.userInterface.forms[formId ?? ""]?.inputs?.[inputId])

	return {
		warningMessage: input?.warningMessage ?? null
	}
}
