/**
 * Prettifies a phone number into a human-readable format.
 * @example const prettyLocalNumber = prettyPhoneNumber("01234567890") // 01234 567890
 * @example const prettyInternationalNumber = prettyPhoneNumber("+441234567890") // 01234 567890
 * @param raw The original/machine-readable phone number.
 * @returns The prettified/human-readable phone number.
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
export const prettyPhoneNumber = (raw: string): string => {
	// 01234567890 -> 01234 567890 (Local)
	if (raw.length === 11) return `${raw.substring(0, 5)} ${raw.substring(5, 11)}`

	// +441234567890 -> 01234 567890 (International to Local)
	if (raw.length === 13) return `0${raw.substring(3, 7)} ${raw.substring(7, 13)}`

	return raw
}
