import { useCallback, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import {
	useFetchCoreQuery,
	useLazyBookAppointmentQuery,
	useLazyRescheduleAppointmentQuery
} from "~/api/osteo-physio/client"
import { ErrorMessages, isAPIError, isHTTPError, isStandardError } from "~/api/osteo-physio/types/error"
import { InflatedAppointment, InflatedAppointmentTime } from "~/classes/appointment"
import AppointmentDetails from "~/components/appointments/details"
import Form from "~/components/controls/forms/form"
import SubmitButton from "~/components/controls/forms/submitButton"
import SwitchInput from "~/components/controls/inputs/switch"
import ErrorMessage from "~/components/errorMessage"
import LoadingSpinner, { loadingSpinnerIconSize } from "~/components/loadingSpinner"
import Page from "~/components/standard/layout/page"
import Section from "~/components/standard/layout/section"
import { tidyUpBookedAppointment } from "~/helpers/tidy-ups/appointment/booked"
import { useAuthSessionSelector } from "~/hooks/useAuthSession"
import { useFormDispatch } from "~/hooks/useForm"
import { useMediaQueries } from "~/hooks/useMediaQueries"
import { Routes } from "~/router"
import { isPartialUser } from "~/state/slices/authSession"
import type { OnFormSubmitCallback } from "~/types/components/controls/form"
import type { ComponentProps } from "~/types/components/props"
import type { NavigationState } from "~/types/router/navigation"

enum HTMLElementIdentifiers {
	ConfirmAppointment = "confirmAppointment",
	EarlierCancellations = "earlierCancellations"
}

export interface ConfirmAppointmentNavigationState extends NavigationState {
	rescheduleAppointmentId?: number
}

/**
 * The appointment confirmation page.
 * This page handles confirmation of a chosen available appointment.
 * @example <ConfirmAppointmentPage />
 * @author Jay Hunter <jh@yello.studio>
 * @since 2.0.0
 */
const ConfirmAppointmentPage = ({ ...props }: ComponentProps<HTMLDivElement>): JSX.Element => {
	// Responsiveness
	const { isMediumHeight } = useMediaQueries()

	// Form
	const { showWarning, updateLoading, clearWarnings } = useFormDispatch(HTMLElementIdentifiers.ConfirmAppointment)

	// Router
	const navigate = useNavigate()

	// State
	const { user } = useAuthSessionSelector()

	// Get state from router
	const location = useLocation()
	const state = location.state as ConfirmAppointmentNavigationState | null
	const appointment = state !== null ? InflatedAppointment.fromJSON(state.inflatedAppointmentJSON) : null
	const time =
		state?.inflatedAppointmentTimeJSON !== undefined && appointment !== null
			? InflatedAppointmentTime.fromJSON(appointment, state.inflatedAppointmentTimeJSON)
			: null
	const rescheduleAppointmentId = state?.rescheduleAppointmentId ?? null

	// API queries
	const { data: core, error } = useFetchCoreQuery({})
	const [bookAppointment, bookAppointmentResponse] = useLazyBookAppointmentQuery()
	const [rescheduleAppointment, rescheduleAppointmentResponse] = useLazyRescheduleAppointmentQuery()

	// Runs after the appointment has been booked...
	useEffect(() => {
		if (
			bookAppointmentResponse.isUninitialized ||
			bookAppointmentResponse.isFetching ||
			bookAppointmentResponse.isLoading
		)
			return

		updateLoading(false)

		if (bookAppointmentResponse.isError) {
			// API error message
			if (isAPIError(bookAppointmentResponse.error)) {
				if (bookAppointmentResponse.error.data.SystemErrorMessage === ErrorMessages.Success.valueOf()) return // APIs can return success within an error message?!

				if (
					bookAppointmentResponse.error.data.SystemErrorMessage === ErrorMessages.AppointmentTaken.valueOf()
				) {
					console.warn("This appointment has been taken by another patient!")
					showWarning(
						HTMLElementIdentifiers.EarlierCancellations,
						"Appointment already booked by another patient."
					)
					return
				}

				console.warn(
					`Failed to book appointment! (API said '${bookAppointmentResponse.error.data.SystemErrorMessage?.toString() ?? "Unknown error"}')`
				)
				//showWarning(HTMLElementIdentifiers.EarlierCancellations, "API error! See console for more information.")
				showWarning(
					HTMLElementIdentifiers.EarlierCancellations,
					JSON.stringify(bookAppointmentResponse.error.data)
				)
				return
			}

			// HTTP error code
			if (isHTTPError(bookAppointmentResponse.error)) {
				console.warn(`Failed to book appointment! (HTTP ${bookAppointmentResponse.error.status.toString()})`)
				showWarning(
					HTMLElementIdentifiers.EarlierCancellations,
					`HTTP ${bookAppointmentResponse.error.status.toString()}! See console for more information.`
				)
				return
			}

			// Fetch aborted (e.g., timeout?)
			if (isStandardError(bookAppointmentResponse.error) && bookAppointmentResponse.error.name === "AbortError") {
				console.warn("Failed to book appointment! (Request was aborted)")
				showWarning(HTMLElementIdentifiers.EarlierCancellations, "The request was aborted! Please try again.")
				return
			}

			// Unknown
			console.error(`Failed to book appointment! (${JSON.stringify(bookAppointmentResponse.error)})`)
			showWarning(
				HTMLElementIdentifiers.EarlierCancellations,
				"An unknown error occurred! Please try again later."
			)
			return
		}

		// Great success
		const bookedAppointment = tidyUpBookedAppointment(bookAppointmentResponse.data)
		console.info(
			`Booked appointment '${bookedAppointment.id.toString()}' at '${new Date(bookedAppointment.start_at).toISOString()}'!`
		)
		navigate(Routes.Home)
	}, [showWarning, updateLoading, navigate, bookAppointmentResponse, location.state])

	// Runs after the appointment has been rescheduled...
	useEffect(() => {
		if (
			rescheduleAppointmentResponse.isUninitialized ||
			rescheduleAppointmentResponse.isFetching ||
			rescheduleAppointmentResponse.isLoading
		)
			return

		updateLoading(false)

		if (rescheduleAppointmentResponse.isError) {
			// API error message
			if (isAPIError(rescheduleAppointmentResponse.error)) {
				if (rescheduleAppointmentResponse.error.data.SystemErrorMessage === ErrorMessages.Success.valueOf())
					return // APIs can return success within an error message?!

				console.warn(
					`Failed to reschedule appointment! (API said '${rescheduleAppointmentResponse.error.data.SystemErrorMessage?.toString() ?? "Unknown error"}')`
				)
				showWarning(HTMLElementIdentifiers.EarlierCancellations, "API error! See console for more information.")
				return
			}

			// HTTP error code
			if (isHTTPError(rescheduleAppointmentResponse.error)) {
				console.warn(
					`Failed to reschedule appointment! (HTTP ${rescheduleAppointmentResponse.error.status.toString()})`
				)
				showWarning(
					HTMLElementIdentifiers.EarlierCancellations,
					`HTTP ${rescheduleAppointmentResponse.error.status.toString()}! See console for more information.`
				)
				return
			}

			// Fetch aborted (e.g., timeout?)
			if (
				isStandardError(rescheduleAppointmentResponse.error) &&
				rescheduleAppointmentResponse.error.name === "AbortError"
			) {
				console.warn("Failed to reschedule appointment! (Request was aborted)")
				showWarning(HTMLElementIdentifiers.EarlierCancellations, "The request was aborted! Please try again.")
				return
			}

			// Unknown
			console.error(`Failed to reschedule appointment! (${JSON.stringify(rescheduleAppointmentResponse.error)})`)
			showWarning(
				HTMLElementIdentifiers.EarlierCancellations,
				"An unknown error occurred! Please try again later."
			)
			return
		}

		// Great success
		const bookedAppointment = tidyUpBookedAppointment(rescheduleAppointmentResponse.data)
		console.info(
			`Rescheduled appointment '${bookedAppointment.id.toString()}' at '${new Date(bookedAppointment.start_at).toISOString()}'!`
		)
		navigate(Routes.Home)
	}, [showWarning, updateLoading, navigate, rescheduleAppointmentResponse, location.state])

	// Runs when the confirmation button is clicked...
	const onBookingConfirm = useCallback<OnFormSubmitCallback>(
		values => {
			if (!appointment || !time) return // Do not continue if we have no state

			const onStandbyForEarlierCancellations =
				(values.get(HTMLElementIdentifiers.EarlierCancellations) as boolean | undefined) ?? false

			updateLoading(true)

			/* eslint-disable promise/prefer-await-to-then */
			if (rescheduleAppointmentId !== null)
				rescheduleAppointment({
					...appointment.toAPIRequestPayload(),

					start_at: time.startAt.toISOString(),
					end_at: time.finishAt.toISOString(),

					earlier_cancellations_flag: onStandbyForEarlierCancellations,

					id: rescheduleAppointmentId
				}).catch((error: unknown) => {
					console.warn(
						`Failed to reschedule appointment '${appointment.getUniqueKey()}'! (${error?.toString() ?? "Unknown error"})`
					)
					showWarning(
						HTMLElementIdentifiers.EarlierCancellations,
						"Sorry, an unknown problem occurred. Please try again later."
					)
				})
			else
				bookAppointment({
					...appointment.toAPIRequestPayload(),

					start_at: time.startAt.toISOString(),
					end_at: time.finishAt.toISOString(),

					earlier_cancellations_flag: onStandbyForEarlierCancellations
				}).catch((error: unknown) => {
					console.warn(
						`Failed to book appointment '${appointment.getUniqueKey()}'! (${error?.toString() ?? "Unknown error"})`
					)
					showWarning(
						HTMLElementIdentifiers.EarlierCancellations,
						"Sorry, an unknown problem occurred. Please try again later."
					)
				})
		},
		[showWarning, updateLoading, bookAppointment, rescheduleAppointment, appointment, time, rescheduleAppointmentId]
	)

	useEffect(() => {
		clearWarnings()
		console.debug("Confirmation page mounted!")
	}, [clearWarnings])

	// Handle fetch errors
	if (error) {
		if (isAPIError(error))
			return <ErrorMessage title="API Error" content={error.data.SystemErrorMessage?.toString()} />

		if (isHTTPError(error)) return <ErrorMessage title="HTTP Error" content={error.status.toString()} />

		return <ErrorMessage title="Unknown Error" content="Failed to fetch core data!" />
	}

	// Wait for core app data
	if (!core) return <LoadingSpinner size={loadingSpinnerIconSize} />

	// Do not continue if we have no state
	if (!appointment || !time) return <ErrorMessage title="Router" content="No state passed to confirmation page!" />

	// Do not continue if the appointment is invalid
	if (!appointment.isValid())
		return <ErrorMessage title="Appointment" content="Appointment has bad properties/malformed data!" />

	// Do not continue if the user is not fully signed in
	if (!user || isPartialUser(user)) return <ErrorMessage title="User" content="User is not signed in!" />

	// Get their contact methods
	/*
	const contactMethods = [
		user.email,
		user.mobile_phone && prettyPhoneNumber(user.mobile_phone),
		user.home_phone && prettyPhoneNumber(user.home_phone)
	]
		.filter(value => value)
		.join(", ")
	*/

	return (
		<Page {...props} className={`justify-between ${props.className ?? ""}`.trimEnd()}>
			<Section title={rescheduleAppointmentId !== null ? "Confirm Reschedule" : "Confirm Appointment"}>
				<AppointmentDetails appointment={appointment} time={time} />
			</Section>
			{/* Cannot use <StickyButton /> due to switch! */}
			<Form
				id={HTMLElementIdentifiers.ConfirmAppointment}
				className={`w-full p-4 ${!isMediumHeight ? "fixed bottom-0 left-0" : ""}`.trimEnd()}
				onSubmit={onBookingConfirm}>
				{/* Useful information isn't part of the spec I guess */}
				{/* {contactMethods && (
					<div className="flex flex-col gap-y-4 px-4">
						<Paragraph className="text-center text-sm">
							You may be contacted regarding any updates to your appointment. If your preferred contact
							methods ({contactMethods}) are wrong, please amend them in{" "}
							<Link to={Routes.Account}>
								your account settings.
							</a>
						</Paragraph>
					</div>
				)} */}

				<SwitchInput
					id={HTMLElementIdentifiers.EarlierCancellations}
					className="items-center text-center"
					label="Early Cancellations"
					tooltip="Notify me of earlier appointments if cancellations occur"
					initialValue={true}
				/>

				<SubmitButton
					label={rescheduleAppointmentId !== null ? "Reschedule this appointment" : "Book this appointment"}
					loadingLabel={
						rescheduleAppointmentId !== null
							? "Rescheduling this appointment..."
							: "Booking this appointment..."
					}
					className="!text-md shadow"
					wide={true}
				/>
			</Form>
		</Page>
	)
}

export default ConfirmAppointmentPage
